import { Directive, HostListener, ElementRef, OnInit } from '@angular/core';

@Directive({ selector: '[customNumerics]' })
export class CustomNumericsDirective implements OnInit {
  private el: HTMLInputElement;

  constructor(private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {}

  @HostListener('keypress', ['$event'])
  keypress(event: KeyboardEvent) {
    if (
      ((event.which >= 48 && event.which <= 57) ||
        event.which === 46 ||
        event.which === 44) &&
      !event.shiftKey
    ) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
}
