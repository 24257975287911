import { Directive, ElementRef, Input, NgZone } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ToastrService } from 'ngx-toastr';
import { fromEvent } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@UntilDestroy()
@Directive({
  selector: '[copy]'
})
export class CopyDirective {
  @Input() copy: string;

  constructor(
    private host: ElementRef<HTMLElement>,
    private zone: NgZone,
    private toastr: ToastrService
  ) {
  }

  ngOnInit() {
    this.zone.runOutsideAngular(() => {
      fromEvent(this.host.nativeElement, 'click').pipe(
        switchMap(() => navigator.clipboard.writeText(this.copy)),
        untilDestroyed(this)
      ).subscribe(() => this.toastr.success('Copied!'))
    })
  }
}