import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { RouteStateService } from '../../../shared/service/route-state.service';

export interface ICanComponentDeactivate {
  canDeactivate: (
    component: ICanComponentDeactivate,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ) => boolean | Observable<boolean> | Promise<boolean>;
}

// If a component should be checked before navigating away, then this CanDeactivateGuardService
// can be used in the route definition for that component
@Injectable()
export class CanDeactivateGuardService
  implements CanDeactivate<ICanComponentDeactivate>
{
  constructor(private routeState: RouteStateService) {}
  canDeactivate(
    component: ICanComponentDeactivate,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.routeState.determineIfInAppNavigationPopState()) {
      // Push history to prevent double clicking back button
      history.pushState(null, null, location.href);
      // Prevent navigation
      return false;
    } else if (!component || !component.canDeactivate) {
      return true;
    }
    return component.canDeactivate(component, route, state, nextState);
  }
}
