import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'removeHtml', pure: false })
export class RemoveHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(text) {
    return text ? String(text).replace(/<[^>]+>/gm, '') : '';
  }
}
