import {
  Component,
  ElementRef,
  HostBinding,
  NgZone,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core';
import {
  Event as RouterEvent,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { AppConfig } from '../app.config';
import { AuthService } from '../modules/login/services/auth.service';
import { UserIdleService } from 'angular-user-idle';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SessionExpiredPopupComponent } from './session-expired/session-expired.component';
import { isNil } from 'ramda';
import { Subscription } from 'rxjs';

declare let jQuery: any;
declare let Hammer: any;

const SESSION_TIMEOUT_WARNING_DURATION = 60; // 2 minutes - Default 120

/**
 * LayoutComponent
 */
@Component({
  selector: 'app-layout',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './layout.template.html',
  //providers: [BsModalService]
})
export class LayoutComponent implements OnInit, OnDestroy {
  @ViewChild('spinnerElement') spinnerElement: ElementRef;
  @ViewChild('routerComponent', { static: true }) routerComponent: ElementRef;

  @HostBinding('class.nav-static') navStatic: boolean;
  @HostBinding('class.chat-sidebar-opened') chatOpened: boolean = false;
  @HostBinding('class.app') appClass: boolean = true;
  config: any;
  configFn: any;
  $sidebar: any;
  el: ElementRef;
  router: Router;
  showExpiryModal: boolean;

  bsSessionExpiredModalRef: BsModalRef;
  timerRef: Subscription;

  /**
   * Creates an instance of layout component.
   * @param config
   * @param el
   * @param router
   * @param renderer
   * @param auth
   * @param ngZone
   */
  constructor(
    config: AppConfig,
    el: ElementRef,
    router: Router,
    private renderer: Renderer2,
    private auth: AuthService,
    private userIdle: UserIdleService,
    private modalService: BsModalService,
    private ngZone: NgZone
  ) {
    this.el = el;
    this.config = config.getConfig();
    this.configFn = config;
    this.router = router;
    this.showExpiryModal = false;

    let that = this;
    // Start watching when user idle is starting.
    this.timerRef = this.userIdle.onTimerStart().subscribe(count => {
      if (!isNil(count)) {
        if (that.showExpiryModal === false) {
          if (that.iskeepSessionAliveClicked() === false) {
            that.showSessionExpiredModal();
            that.showExpiryModal = true;
          }
        } else {
          // Please check the settings in app.module.ts for warning time period
          if(!isNil(that.bsSessionExpiredModalRef) && !isNil(that.bsSessionExpiredModalRef.content)){          
            that.bsSessionExpiredModalRef.content.timeToExpire =
              SESSION_TIMEOUT_WARNING_DURATION - count;
          }
        }
      }
    });

    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => {
      that.userIdle.resetTimer();
      if(!isNil(that.bsSessionExpiredModalRef) && !isNil(that.bsSessionExpiredModalRef.content)){
        that.bsSessionExpiredModalRef.content.logout();
      }

    });

    // this.userIdle.ping$.subscribe(() => {
    //     // Use this ping to refresh Okta oken every PING interval
    //   }
    // );
  }

  // Check if the modal is up
  iskeepSessionAliveClicked() {
    if (
      !isNil(this.bsSessionExpiredModalRef) &&
      !isNil(this.bsSessionExpiredModalRef.content) &&
      this.bsSessionExpiredModalRef.content.keepSessionAliveClicked
    ) {
      return true;
    }
    return false;
  }

  showSessionExpiredModal() {
    let that = this;

    const initialState = {
      timeToExpire: SESSION_TIMEOUT_WARNING_DURATION,
    };
    this.showExpiryModal = true;
    this.bsSessionExpiredModalRef = this.modalService.show(
      SessionExpiredPopupComponent,
      {
        initialState,
        class: 'modal-md modal-center',
        animated: true,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true,
      }
    );

    let modalHideSubscriber = this.modalService.onHide.subscribe(() => {
      that.showExpiryModal = false;

      // Did the user decide to stay logged in?
      if(!isNil(that.bsSessionExpiredModalRef) && !isNil(that.bsSessionExpiredModalRef.content)){      
        if (
          that.bsSessionExpiredModalRef.content.keepSessionAliveClicked === true
        ) {
          that.userIdle.stopTimer();
          that.bsSessionExpiredModalRef.content.keepSessionAliveClicked = false;
          modalHideSubscriber.unsubscribe();
        } else {
          that.userIdle.stopTimer();
          that.timerRef.unsubscribe();

          // Check for any open popups and close them before logout.
          if (jQuery('.modal-dialog') && jQuery('.modal-dialog').find('.close')) {
            jQuery('.modal-dialog').find('.close').click();
          }
          modalHideSubscriber.unsubscribe();
          sessionStorage.clear();
          that.auth.signOut();
        }
      }
    });

    let modalShowSubscriber = this.modalService.onShow.subscribe(() => {
      that.showExpiryModal = true;
      modalShowSubscriber.unsubscribe();
    });
  }

  /**
   * Toggles sidebar listener
   * @param state
   */
  toggleSidebarListener(state): void {
    const toggleNavigation =
      state === 'static'
        ? this.toggleNavigationState
        : this.toggleNavigationCollapseState;
    toggleNavigation.apply(this);
    localStorage.setItem('nav-static', JSON.stringify(this.navStatic));
  }

  /**
   * Toggles chat listener
   */
  toggleChatListener(): void {
    jQuery(this.el.nativeElement).find('.chat-notification-sing').remove();
    this.chatOpened = !this.chatOpened;

    setTimeout(() => {
      // demo: add class & badge to indicate incoming messages from contact
      // .js-notification-added ensures notification added only once
      jQuery(
        '.chat-sidebar-user-group:first-of-type ' +
          '.list-group-item:first-child:not(.js-notification-added)'
      )
        .addClass('active js-notification-added')
        .find('.fa-circle')
        .before(
          '<span class="badge badge-danger badge-pill ' +
            'float-right animated bounceInDown">3</span>'
        );
    }, 1000);
  }

  /**
   * Toggles navigation state
   */
  toggleNavigationState(): void {
    this.navStatic = !this.navStatic;
    if (!this.navStatic) {
      this.collapseNavigation();
    }
  }

  /**
   * Expands navigation
   * @returns navigation
   */
  expandNavigation(): void {
    // this method only makes sense for non-static navigation state
    if (
      this.isNavigationStatic() &&
      (this.configFn.isScreen('lg') || this.configFn.isScreen('xl'))
    ) {
      return;
    }
    jQuery('app-layout').removeClass('nav-collapsed');
    this.$sidebar
      .find('.active .active')
      .closest('.collapse')
      .collapse('show')
      .siblings('[data-toggle=collapse]')
      .removeClass('collapsed');
  }

  /**
   * Collapses navigation
   * @returns navigation
   */
  collapseNavigation(): void {
    // this method only makes sense for non-static navigation state
    if (
      this.isNavigationStatic() &&
      (this.configFn.isScreen('lg') || this.configFn.isScreen('xl'))
    ) {
      return;
    }

    jQuery('app-layout').addClass('nav-collapsed');
    this.$sidebar
      .find('.collapse.in')
      .collapse('hide')
      .siblings('[data-toggle=collapse]')
      .addClass('collapsed');
  }

  /**
   * Check and set navigation collapse according to screen size and navigation state
   */
  checkNavigationState(): void {
    if (this.isNavigationStatic()) {
      if (
        this.configFn.isScreen('sm') ||
        this.configFn.isScreen('xs') ||
        this.configFn.isScreen('md')
      ) {
        this.collapseNavigation();
      }
    } else {
      if (this.configFn.isScreen('lg') || this.configFn.isScreen('xl')) {
        setTimeout(() => {
          this.collapseNavigation();
        }, this.config.settings.navCollapseTimeout);
      } else {
        this.collapseNavigation();
      }
    }
  }

  /**
   * Determines whether navigation static is
   * @returns true if navigation static
   */
  isNavigationStatic(): boolean {
    return this.navStatic === true;
  }

  /**
   * Toggles navigation collapse state
   */
  toggleNavigationCollapseState(): void {
    if (jQuery('app-layout').is('.nav-collapsed')) {
      this.expandNavigation();
    } else {
      this.collapseNavigation();
    }
  }

  /**
   * Sidebars mouse enter
   */
  _sidebarMouseEnter(): void {
    if (this.configFn.isScreen('lg') || this.configFn.isScreen('xl')) {
      this.expandNavigation();
    }
  }

  /**
   * Sidebars mouse leave
   */
  _sidebarMouseLeave(): void {
    if (this.configFn.isScreen('lg') || this.configFn.isScreen('xl')) {
      this.collapseNavigation();
    }
  }

  /**
   * Enables swipe collapsing
   */
  enableSwipeCollapsing(): void {
    const swipe = new Hammer(document.getElementById('content-wrap'));
    const d = this;

    swipe.on('swipeleft', () => {
      setTimeout(() => {
        if (d.configFn.isScreen('md')) {
          return;
        }

        if (!jQuery('app-layout').is('.nav-collapsed')) {
          d.collapseNavigation();
        }
      });
    });

    swipe.on('swiperight', () => {
      if (d.configFn.isScreen('md')) {
        return;
      }

      if (jQuery('app-layout').is('.chat-sidebar-opened')) {
        return;
      }

      if (jQuery('app-layout').is('.nav-collapsed')) {
        d.expandNavigation();
      }
    });
  }

  /**
   * Collapses nav if small screen
   */
  collapseNavIfSmallScreen(): void {
    if (
      this.configFn.isScreen('xs') ||
      this.configFn.isScreen('sm') ||
      this.configFn.isScreen('md')
    ) {
      this.collapseNavigation();
    }
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.userIdle.stopTimer();
  }

  /**
   * Determines whether logged in is
   * @returns
   */
  isLoggedIn() {
    if (this.auth.loggedIn.value) {
      return true;
    }
    return false;
  }

  /**
   * Navigations interceptor
   * @param event
   */
  private _navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      // We wanna run this function outside of Angular's zone to
      // bypass change detection
      this.ngZone.runOutsideAngular(() => {
        // For simplicity we are going to turn opacity on / off
        // you could add/remove a class for more advanced styling
        // and enter/leave animation of the spinner
        this.renderer.setStyle(
          this.spinnerElement.nativeElement,
          'opacity',
          '1'
        );
        this.renderer.setStyle(
          this.routerComponent.nativeElement,
          'opacity',
          '0'
        );
      });
    }
    if (event instanceof NavigationEnd) {
      this._hideSpinner();
    }

    // Set loading state to false in both of the below events to
    // hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this._hideSpinner();
    }
    if (event instanceof NavigationError) {
      this._hideSpinner();
    }
  }

  /**
   * Hides spinner
   */
  private _hideSpinner(): void {
    // We wanna run this function outside of Angular's zone to
    // bypass change detection,
    this.ngZone.runOutsideAngular(() => {
      // For simplicity we are going to turn opacity on / off
      // you could add/remove a class for more advanced styling
      // and enter/leave animation of the spinner
      this.renderer.setStyle(this.spinnerElement.nativeElement, 'opacity', '0');
      this.renderer.setStyle(
        this.routerComponent.nativeElement,
        'opacity',
        '1'
      );
    });
  }
}
