<app-top-navbar *ngIf="isTopNavbarShown()"></app-top-navbar>
<router-outlet></router-outlet>
<app-footer></app-footer>
<!-- Announce Session Expiry Message -->
<!-- TODO - Should move to a separate component -->
<div class="modal fade" id="app-popup">
  <div class="modal-dialog" role="app-popup">
    <div class="modal-content">
      <div class="modal-header modal-title-bar">
        <div title="title" class="h4 modal-title text-xs-center fw-bold mt">
          {{title}}
        </div>
      </div>
      <!--Modal Body-->
      <div class="modal-body">
        <div [innerHTML]="sessionExpireMsg"></div>
      </div>
      <!--Modal Footer-->
      <div class="modal-footer">
        <button type="button" class="btn btn-gray" (click)="onClose()">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
