import { Pipe, PipeTransform } from '@angular/core';

/*
 * Changes the case of the first letter of a given number of words in a string.
 */

@Pipe({ name: 'custTitleCase', pure: false })
export class TitleCase implements PipeTransform {
  transform(input: string, overRideText?: Array<string>): string {
    if (input == null) {
      return input;
    }
    if (overRideText && overRideText.length) {
      return input.length > 0
        ? input.replace(/\w\S*/g, function (txt) {
            for (let i = 0; i < overRideText.length; i++) {
              if (txt.toUpperCase() === overRideText[i].toUpperCase()) {
                return overRideText[i];
              }
            }
            return txt[0].toUpperCase() + txt.substr(1).toLowerCase();
          })
        : '';
    } else {
      return input.length > 0
        ? input.replace(
            /\w\S*/g,
            txt => txt[0].toUpperCase() + txt.substr(1).toLowerCase()
          )
        : '';
    }
  }
}
