import { Routes } from '@angular/router';
import { AuthGuard } from '../modules/login/services/auth-guard.service';
import { LayoutComponent } from './layout.component';
import { HelpComponent } from './help/help.component';
import { CanDeactivateGuardService } from '../modules/login/services/can-deactivate-guard.service';

// Define the function to determine whether to use AuthGuard
function shouldUseAuthGuard(): boolean {
  // Add your condition here
  return true; // or false based on your condition
}

export const layoutRoutes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canDeactivate: [CanDeactivateGuardService],
    children: [
      {
        path: 'landing',
        loadChildren: () =>
          import('../modules/landing/landing.module').then(
            m => m.LandingModule
          ),
          canActivate: shouldUseAuthGuard() ? [AuthGuard] : [],
      },        
      {
        path: 'labels',
        loadChildren: () =>
          import('../modules/material/material.module').then(
            m => m.MaterialModule
          ),
        canActivate: shouldUseAuthGuard() ? [AuthGuard] : [],
      },
      {
        path: 'graph',
        loadChildren: () =>
          import('../modules/graph/graph.module').then(
            m => m.GraphModule
          ),
          canActivate: shouldUseAuthGuard() ? [AuthGuard] : [],
      },      
      {
				path: "druglabel",
				loadChildren: () => import('../modules/druglabel/druglabel.module').then(
          m => m.DruglabelModule)
          ,
          canActivate: shouldUseAuthGuard() ? [AuthGuard] : [],
			},	
      {
				path: "adverse",
				loadChildren: () => import('../modules/adverse/adverse.module').then(
          m => m.AdverseModule)
          ,
          canActivate: shouldUseAuthGuard() ? [AuthGuard] : [],
			},	
      {
				path: "predict",
				loadChildren: () => import('../modules/predict/predict.module').then(
          m => m.PredictModule)
          ,
          canActivate: shouldUseAuthGuard() ? [AuthGuard] : [],
			},	
      {
				path: "inspection",
				loadChildren: () => import('../modules/inspection/inspection.module').then(
          m => m.InspectionModule)
          ,
          canActivate: shouldUseAuthGuard() ? [AuthGuard] : [],
			},	      
      {
				path: "labeling",
				loadChildren: () => import('../modules/labeling/labeling.module').then(
          m => m.LabelingModule)
          ,
          canActivate: shouldUseAuthGuard() ? [AuthGuard] : [],
			},	
      {
        path: 'videos',
        loadChildren: () =>
          import('../modules/material/material.module').then(
            m => m.MaterialModule
          ),
          canActivate: shouldUseAuthGuard() ? [AuthGuard] : [],
      },      
      {
        path: 'opensearchml',
        loadChildren: () =>
          import('../modules/opensearchml/opensearchml.module').then(
            m => m.OpenSearchMLModule
          ),
          canActivate: shouldUseAuthGuard() ? [AuthGuard] : [],         
      },         
      {
        path: 'submission',
        loadChildren: () =>
          import('../modules/submission/submission.module').then(
            m => m.SubmissionModule
          ),
          canActivate: shouldUseAuthGuard() ? [AuthGuard] : [],
      },      
      {
        path: 'forms',
        loadChildren: () =>
          import('../modules/fdaforms/fdaforms.module').then(
            m => m.FDAFormsModule
          ),
          canActivate: shouldUseAuthGuard() ? [AuthGuard] : [],          
      },      
      {
        path: 'chatbot',
        loadChildren: () =>
          import('../modules/chatbot/chatbot.module').then(
            m => m.chatBotModule
          ),
          canActivate: shouldUseAuthGuard() ? [AuthGuard] : [],
      },
      {
        path: 'admin',
        loadChildren: () =>
          import('../modules/admin/admin.module').then(m => m.AdminModule),
        canActivate: shouldUseAuthGuard() ? [AuthGuard] : [],
      },
      {
        path: 'help',
        component: HelpComponent,
        canDeactivate: [CanDeactivateGuardService],
      },
    ],
  },
];
