import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BaseComponent } from '../base/base.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.template.html',
  styleUrls: ['./confirm-modal.style.scss'],
})
export class ConfirmModalPopupComponent
  extends BaseComponent
  implements OnInit
{
  public onClose: Subject<boolean>;
  public title: string;
  public message: string;
  public yesText: string;
  public noText: string;
  public footer: boolean;

  public showDontAskAgain: boolean = false;
  public isDontAskAgainSelected: boolean = false;
  public isConfirmClicked: boolean = false;

  constructor(private _bsModalRef: BsModalRef) {
    super();
    this.footer = true;
  }

  public ngOnInit(): void {
    this.onClose = new Subject();
  }

  /**
   * Determines whether confirm on
   */
  public onConfirm(): void {
    this.onClose.next(true);
    this.isConfirmClicked = true;
    this._bsModalRef.hide();
  }

  /**
   * Determines whether cancel on
   */
  public onCancel(): void {
    this.onClose.next(false);
    this._bsModalRef.hide();
  }
}
