export const environment = {
  production: true,
  APIURL: 'https://genai.intellireviewer.com/api',
  COREAPIURL: 'https://genai.intellireviewer.com/api/core', 
  LABELINGAPIURL: 'https://genai.intellireviewer.com/api/labeling',
  LLMCOREAPIURL: 'https://genai.intellireviewer.com/api/llmcore',
  SEARCHAPIURL: 'https://genai.intellireviewer.com/api/search',
  STORAGEAPIURL: 'https://genai.intellireviewer.com/api/storage', 
  LLMHOSTAPIURL: 'https://genai.intellireviewer.com/api/llmhost', 
  SEARCHMLAPIURL: 'https://genai.intellireviewer.com/api/searchml',   
  INSPECTIONAPIURL: 'https://genai.intellireviewer.com/api/inspection',
  SEMANTICSEARCHAPIURL: 'https://cdersemantic.trlmapp.com/api/searchml/search', 
  APIGATEWAYURL: 'https://c4x4jmts24.execute-api.us-east-2.amazonaws.com/prod/',
  timeStamp: '11.20.2024 08:14:43 PM EST',
  PINGENABLED: true,
  PINGAUTHORITY: 'https://dev-73854748.okta.com/oauth2/default/',
  PINGCLIENTID: '0oabzfx5my7YnLwPF5d7',
  PINGREDIRECT: 'https://genai.intellireviewer.com/signin/callback',
  PINGLOGOUTREDIRECT: 'https://genai.intellireviewer.com',
  PINGSCOPES: 'openid email profile phone address',
  PINGGROUPS: [
    { role: 'CBU', group: 'Chatbot Demo User' },    
    { role: 'CIU', group: 'Cigarette Industry User' },
    { role: 'CWLR', group: 'Cigarette Warning Label Reviewer' },
    { role: 'DCRA', group: 'Compliance Reviewer' },
    { role: 'SRI', group: 'Retail Inspector' },   
  ],
  PINGSLODISABLE: true,
  userIdleEnabled: true,
  VIDEOSTORAGEBUCKET: 'cder-labeling-video-data',
  OCOE_LABELING_BUCKET_NAME: 'ocoe-labeling-leep-data',
  appTitle: 'IntelliReview',
  loginTitle: 'IntelliReview',
  DATAIKUURL: 'http://3.128.123.95/home',
};
