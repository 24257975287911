import { Component, ElementRef, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'read-more',
  templateUrl: './read-more.template.html',
  styleUrls: ['./read-more.style.scss'],
})
export class ReadMoreComponent implements OnChanges {
  @Input() headertitle: string;
  @Input() text: string;
  @Input() maxLength: number = 100;
  public currentText: string;
  public hideToggle: boolean = true;

  public isCollapsed: boolean = true;

  constructor(private elementRef: ElementRef) {}
  /**
   * Toggles view
   */
  public toggleView() {
    this.isCollapsed = !this.isCollapsed;
    this.determineView();
  }
  /**
   * Determines view
   * @returns
   */
  public determineView() {
    if (!this.text || this.text.length <= this.maxLength) {
      this.currentText = this.text;
      this.isCollapsed = false;
      this.hideToggle = true;
      return;
    }
    this.hideToggle = false;
    if (this.isCollapsed === true) {
      this.currentText = this.text.substring(0, this.maxLength) + '...';
    } else if (this.isCollapsed === false) {
      this.currentText = this.text;
    }
  }

  /**
   * on changes
   */
  ngOnChanges() {
    this.determineView();
  }
}
