import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../modules/login/services/auth.service';
import { environment } from '../../environments/environment';
import { PingAuthService } from '../modules/login/services/ping-auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  sessionToken: string;
  constructor(
    public auth: AuthService,
    private pingAuthService: PingAuthService
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const reExclude = /amazonaws.com/gi;
    if (environment.PINGENABLED && request.url.search(reExclude) === -1) {
      let headers = request.headers.set(
        'Authorization',
        this.pingAuthService.getAuthorizationHeaderValue()
      );
      request = request.clone({
        headers,
      });
    }
    return next.handle(request);
  }
}
