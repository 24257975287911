import { environment } from '../../../environments/environment';
// base url
const BASE_URL = environment.APIURL;
export const BASE_APIURL = environment.APIURL;
export const APIGATEWAYURL = environment.APIGATEWAYURL;
export const SEARCH_APIURL = environment.SEARCHAPIURL;
export const STORAGE_APIURL = environment.STORAGEAPIURL;
export const LLMCOREAPIURL = environment.LLMCOREAPIURL;
export const SEARCHML_APIURL = environment.SEARCHMLAPIURL;
export const LLMHOST_APIURL = environment.LLMHOSTAPIURL;
export const CORE_APIURL = environment.COREAPIURL;
export const LABELING_APIURL = environment.LABELINGAPIURL;
export const INSPECTION_APIURL = environment.INSPECTIONAPIURL;

export const SEMANTICSEARCHAPIURL = environment.SEMANTICSEARCHAPIURL;

// functionalities
export const INTERNAL = BASE_URL + '/internal';
export const STUDY = BASE_URL + '/study';
export const FILE = BASE_URL + '/files';
export const STORAGE = BASE_URL + '/storage';
export const PROCESSLABEL = APIGATEWAYURL;
export const MONGO = BASE_URL + '/mongo';
export const USER = BASE_URL + '/user';
export const MEDIA = BASE_URL + '/dynamo';
export const LABEL = BASE_URL + '/label';

// endpoints for Rekognition Model
export const startRekognitionModelUrl = LLMCOREAPIURL + '/labels/start';
export const stopRekognitionModelUrl = LLMCOREAPIURL + '/labels/stop';
export const getRekognitionModelStatusUrl = LLMCOREAPIURL + '/labels/status';

// endpoints for study
export const getAllStudiesUrl = STUDY + '/all';
export const getStudyDetailsUrl = STUDY + '/details';
export const getPendingStudiesUrl = STUDY + '/pending';
export const getApprovedStudiesUrl = STUDY + '/approved';
export const approveStudyUrl = STUDY + '/approve';
export const rejectStudyUrl = STUDY + '/reject';

// endpoints for chatbot
export const bedrockChatbotUrl = LLMCOREAPIURL + "/chat";
export const bedrockChatbotDemoUrl = LLMCOREAPIURL + "/chat_demo";
export const ragSearchUrl = LLMCOREAPIURL + "/rag_demo/vsearch";
export const text2sqlUrl = LLMCOREAPIURL + "/sql_demo/northwind";
export const ragSearchUrlWithKendra = LLMCOREAPIURL + "/rag_demo/kendra/vsearch";
export const dataInsightChatbotUrl = LLMCOREAPIURL + "/load_data/sqlite";
export const csvInsightChatbotUrl = LLMCOREAPIURL + "/agents_demo/csv_agent";
export const processReActDataUrl = LLMCOREAPIURL + "/agents_demo/lambda_agent";
export const processStockAnalysisUrl = LLMCOREAPIURL + "/agents_demo/stock_analysis";
export const processCustomerLookupUrl = LLMCOREAPIURL + "/agents_demo/customer_lookup";
export const adverseEventLoadDataUrl = CORE_APIURL + "/ae_demo/load_ae";

// endpoints for adverse events
export const adverseEventsUrl = SEARCH_APIURL + "/ae";
export const summarizeAdverseEventsSymptomsURL = CORE_APIURL + "/ae_demo/summarize_event";
export const summarizeBatchAdverseEventsURL = CORE_APIURL + "/ae_demo/summarize/batch";
export const adverseEventUpdateSummaryURL = SEARCH_APIURL + "/ae/summary";

// endpoints for predict events
export const predictEventsUrl = SEARCH_APIURL + "/predict";
export const summarizePredictEventsSymptomsURL = CORE_APIURL + "/ae_demo/summarize_event";

// endpoints for models
export const bedrockModelsUrl = LLMCOREAPIURL + "/models";
export const cfgaiModelsUrl= LLMCOREAPIURL + "/models/cfgai";

// endpoints for workspace
export const workspaceUrl = LLMCOREAPIURL + "/workspaces";


// endpoints for chat_demo
export const processEntityExtractionDataUrl = LLMCOREAPIURL + "/chat_demo/extract_entity";
export const processClassificationUrl = LLMCOREAPIURL + "/chat_demo/classify_intent";
export const processGraphQueryUrl = LLMCOREAPIURL + "/chat_demo/query_graph";
export const processFileClassificationUrl = LLMCOREAPIURL + "/chat_demo/file_classify";
export const processFileMatchingUrl = LLMCOREAPIURL + "/chat_demo/file_match";
export const processFileCorrectionUrl = LLMCOREAPIURL + "/chat_demo/file_correction";
export const processFileStandardizationUrl = LLMCOREAPIURL + "/chat_demo/file_standardize";
export const processAddressExtractionUrl = LLMCOREAPIURL + "/chat_demo/extract_addr";
export const processDischargeSummaryExtractionUrl = LLMCOREAPIURL + "/chat_demo/extract_discharge";
export const resetChatbotUrl = LLMCOREAPIURL + "/load_data/reset/opensearch/vector_db";
export const processChatbotDataUrl = LLMCOREAPIURL + "/load_data/opensearch/vector_db";
export const processcsvDataUrl = LLMCOREAPIURL + "/load_data/csv";

// endpoints for summarization
export const processSummarizationUrl = LLMCOREAPIURL + "/summarize";

export const processVideoForMedicalTranscriptionUrl = LLMCOREAPIURL + "/video_demo/transcribe_medical";
export const getFDADrugSummaryUrl = LLMCOREAPIURL + '/video_demo/fda_drug_label_info';
export const addDrugLabelItemToKBUrl = LLMCOREAPIURL + '/video_demo/druglabel/kbadd';
export const ragDrugLabelSearchUrl = LLMCOREAPIURL + "/video_demo/vsearch";
export const resetDrugLabelVectorDBUrl = LLMCOREAPIURL + "/video_demo/reset/druglabeldb";
export const redetectEntitiesUrl = LLMCOREAPIURL + "/video_demo/redetect_entities"; 
export const processDrugLabelRAGDocumentUrl = LLMCOREAPIURL + "/video_demo/load_data/opensearch/vector_db";
export const drugLabelComplianceRAGCheckUrl = LLMCOREAPIURL + "/video_demo/compliance/rag_check";

export const processVapingLabelUrl = LLMCOREAPIURL + "/vaping_demo/label";
export const pingVapingLabelUrl = LLMCOREAPIURL + "/vaping_demo/ping";

export const promptUrl = LLMCOREAPIURL + "/prompt";
export const getRAGDocumentsUrl = LLMCOREAPIURL + "/ragdocs";
export const getDrugLabelRAGDocumentsUrl = LLMCOREAPIURL + "/ragdocs/druglabel";

export const labelingUrl = LLMCOREAPIURL + "/labeling";

export const chatbotFileUrl = STORAGE_APIURL + "/upload/chatbot";

export const listPromptsUrl = LLMCOREAPIURL + '/prompt';

export const getMLGroupModelsUrl = SEARCHML_APIURL + '/mljobs/model_groups/search';
export const registerGroupModelsUrl = SEARCHML_APIURL + '/mljobs/model_groups/register';
export const getMLJobsPaginatedItemsUrl = SEARCHML_APIURL + '/mljobs';
export const getMLResetDemoDataUrl = SEARCHML_APIURL + '/mljobs/reset_demo';
export const getMLHybridPaginatedItemsUrl = SEARCHML_APIURL + '/search/hybrid';
export const getMLNeuralPaginatedItemsUrl = SEARCHML_APIURL + '/search/neural';
export const getMLLexicalPaginatedItemsUrl = SEARCHML_APIURL + '/search/lexical';
export const getMLIngestDataUrl = SEARCHML_APIURL + '/mljobs/job/ingest_data';
export const getMLImportMappingDataUrl = SEARCHML_APIURL + '/mljobs/job/import_map';

export const ragDrugCompareChatResponseUrl = LLMCOREAPIURL + "/rag_demo/dcsearch";

// endpoints for kendra search
export const getKendraSearchIndexUrl = SEARCH_APIURL + '/kendrasearch'

// endpoints for druglabel
export const deleteAllImageLabelsUrl =  LABEL + '/reset';
export const labelFileItemsUrl = LABEL + '/search';
export const labelFileItemByUUIDUrl = LABEL + '/uuid';
export const drugLabelFileGridCardLayoutItemsUrl =  LABEL + '/search';
export const recallendpointurl = 'https://etnu8m90md.execute-api.us-east-1.amazonaws.com/prod';
export const sideeffectsendpointurl = 'https://lfi5s68kb5.execute-api.us-east-1.amazonaws.com/prod';
export const downloadlinkendpoint = 'https://kadfji1anc.execute-api.us-east-1.amazonaws.com/prod';
export const druglabelendpointurl = LLMCOREAPIURL + '/druglabel_demo';
export const comparetwodocumentsurl = LLMCOREAPIURL + '/druglabel_demo/compare_two_docs/ask';

// endpoints for file
export const downloadFileUrl = FILE + '/signed';

// DynamoiDB
export const mediaQueryFileUrl = MEDIA + '/labels';

// SearchAPI - connect to OpenSearch
export const createMaterialFileUrl = SEARCH_APIURL + '/material';
export const updateMaterialFileUrl = SEARCH_APIURL + '/material';
export const materialFilePaginatedItemsUrl = SEARCH_APIURL + '/material/paginated';
export const materialFileItemsByUUIDUrl = SEARCH_APIURL + '/material/uuid';
export const fileGridCardLayoutItemsUrl = SEARCH_APIURL + '/material/paginated';
export const deleteAllMaterialFileItemsUrl = SEARCH_APIURL + '/material/all';
export const deleteSingleMaterialFileItemUrl = SEARCH_APIURL + '/material/single';
export const approveSingleMaterialFileItemUrl = SEARCH_APIURL + '/material/approve';
export const rejectSingleMaterialFileItemUrl = SEARCH_APIURL + '/material/reject';
export const assignReviewerUrl = SEARCH_APIURL + '/material/assign';

export const createVapingLabelUrl = SEARCH_APIURL + '/vaping';
export const vapingFilePaginatedItemsUrl = SEARCH_APIURL + '/vaping/paginated';
export const deleteAllVapingFileItemsUrl = SEARCH_APIURL + '/vaping/all';

export const createMaterialVideoFileUrl = SEARCH_APIURL + '/videos';
export const materialVideoFilePaginatedItemsUrl = SEARCH_APIURL + '/videos/paginated';
export const deleteSingleMaterialVideoFileItemUrl = SEARCH_APIURL + '/videos/single';
export const deleteAllMaterialVideoFileItemsUrl = SEARCH_APIURL + '/videos/all';
export const materialVideoFileItemsByUUIDUrl = SEARCH_APIURL + '/videos/uuid';

export const submissionDocsPaginatedItemsUrl = SEARCH_APIURL + '/submission/paginated';

// Drug label section compliance file
export const createDruglabelSectionComplianceFileUrl = SEARCH_APIURL + '/druglabel/section/compliance/file';
export const druglabelSectionCompliancePaginatedFilesUrl = SEARCH_APIURL + '/druglabel/section/compliance/file/paginated';
export const deleteSingleDruglabelSectionComplianceFileUrl = SEARCH_APIURL + '/druglabel/section/compliance/file/single';
export const deleteAllDruglabelSectionComplianceFilesUrl = SEARCH_APIURL + '/druglabel/section/compliance/file/all';
export const druglabelSectionComplianceFilesByUUIDUrl = SEARCH_APIURL + '/druglabel/section/compliance/file/uuid';

// Drug label section compliance items
export const createDruglabelSectionComplianceItemsUrl = SEARCH_APIURL + '/druglabel/section/compliance/items';
export const druglabelSectionCompliancePaginatedItemsUrl = SEARCH_APIURL + '/druglabel/section/compliance/items/paginated';
export const deleteSingleDruglabelSectionComplianceItemsUrl = SEARCH_APIURL + '/druglabel/section/compliance/items/single';
export const deleteAllDruglabelSectionComplianceItemsUrl = SEARCH_APIURL + '/druglabel/section/compliance/items/all';
export const druglabelSectionComplianceItemsByUUIDUrl = SEARCH_APIURL + '/druglabel/section/compliance/items/uuid';

// Drug label compliance configuration
export const createDruglabelSectionComplianceConfigUrl = SEARCH_APIURL + '/druglabel/section/compliance/config';
export const druglabelSectionCompliancePaginatedConfigUrl = SEARCH_APIURL + '/druglabel/section/compliance/config/paginated';
export const deleteSingleDruglabelSectionComplianceConfigUrl = SEARCH_APIURL + '/druglabel/section/compliance/config/single';
export const resetDruglabelSectionComplianceConfigUrl = SEARCH_APIURL + '/druglabel/section/compliance/config/all';
export const druglabelSectionComplianceConfigByUUIDUrl = SEARCH_APIURL + '/druglabel/section/compliance/config/uuid';

// Drug label section compare file
export const createDruglabelSectionCompareFileUrl = SEARCH_APIURL + '/druglabel/section/compare/file';
export const druglabelSectionComparePaginatedFilesUrl = SEARCH_APIURL + '/druglabel/section/compare/file/paginated';
export const deleteSingleDruglabelSectionCompareFileUrl = SEARCH_APIURL + '/druglabel/section/compare/file/single';
export const deleteAllDruglabelSectionCompareFilesUrl = SEARCH_APIURL + '/druglabel/section/compare/file/all';
export const druglabelSectionCompareFilesByUUIDUrl = SEARCH_APIURL + '/druglabel/section/compare/file/uuid';

// Drug label section compare items
export const createDruglabelSectionCompareItemsUrl = SEARCH_APIURL + '/druglabel/section/compare/items';
export const druglabelSectionComparePaginatedItemsUrl = SEARCH_APIURL + '/druglabel/section/compare/items/paginated';
export const deleteSingleDruglabelSectionCompareItemsUrl = SEARCH_APIURL + '/druglabel/section/compare/items/single';
export const deleteAllDruglabelSectionCompareItemsUrl = SEARCH_APIURL + '/druglabel/section/compare/items/all';
export const druglabelSectionCompareItemsByUUIDUrl = SEARCH_APIURL + '/druglabel/section/compare/items/uuid';

// Drug label compare configuration
export const createDruglabelSectionCompareConfigUrl = SEARCH_APIURL + '/druglabel/section/compare/config';
export const druglabelSectionComparePaginatedConfigUrl = SEARCH_APIURL + '/druglabel/section/compare/config/paginated';
export const deleteSingleDruglabelSectionCompareConfigUrl = SEARCH_APIURL + '/druglabel/section/compare/config/single';
export const resetDruglabelSectionCompareConfigUrl = SEARCH_APIURL + '/druglabel/section/compare/config/all';
export const druglabelSectionCompareConfigByUUIDUrl = SEARCH_APIURL + '/druglabel/section/compare/config/uuid';


export const processDruglabelSectionAddtoKBUrl = LLMCOREAPIURL + "/labeling/section/kb/add";
export const summarizeComplianceFindingsUrl = LLMCOREAPIURL + '/labeling/section/compliance/rag_check';

export const processDruglabelSectionFileUrl = LABELING_APIURL + "/leep/extract_sections";

export const processDruglabelComparePDFFileUrl = LABELING_APIURL + "/leep/extract_sections";

// LLM Hosting EndPoints
export const llmHostUrl = LLMHOST_APIURL + "/manage";
export const deployLLMHostUrl = llmHostUrl + "/deploy";
export const undeployLLMHostUrl = llmHostUrl + "/undeploy";

// Mockar0o
export const loadDemoDataUrl = 'https://api.mockaroo.com/api/1bfb2230?count=1000&key=cffc3a90';

// Storage
export const downloadMaterialFileUrl = STORAGE_APIURL + '/download/presignedurl';
export const downloadVapingFileUrl = STORAGE_APIURL + '/download/vaping/presignedurl';
export const stagingFilesUrl = STORAGE_APIURL + '/staging';
export const quarantineFilesUrl = STORAGE_APIURL + '/quarantine';
export const cleanFilesUrl =   STORAGE_APIURL + '/clean';
export const resetDemoDataUrl = STORAGE_APIURL + '/scan/reset_demo';
export const getNumberofQueuedMessagesUrl = STORAGE_APIURL + '/scan/sqs/num_messages';

// endpoints for user
export const userRoleUrl = USER + '/role';
export const listUsersUrl = USER + '/admin/listUsers';
export const updateUserRoleUrl = USER + '/admin/editRole';

// endpoints for establishments
export const establishmentUrl = INSPECTION_APIURL + '/establishment';
export const facilityUrl = INSPECTION_APIURL + '/facility';
export const inspectionUrl = INSPECTION_APIURL + '/inspection';
export const inspectorUrl = INSPECTION_APIURL + '/inspector';

// Search Image
export const findSimiliarImageItemsByDescriptionUrl = LLMCOREAPIURL + '/mm_demo/similar';

export function predictEventLoadDataUrl(predictEventLoadDataUrl: any, payload: { year: number; }) {
  throw new Error("Function not implemented.");
}

