<div class="js-sidebar-content">
  <header class="logo d-none d-md-block">
    <a href="index.html"><span class="text-warning">Sing</span> App</a>
  </header>
  <div class="sidebar-status d-md-none dropdown" dropdown [autoClose]="false" (isOpenChange)="toggleSidebarOverflow($event)"
    placement="bottom left">
    <a [routerLink]="" class="dropdown-toggle" dropdownToggle>
      <span class="thumb-sm avatar float-right">
        <img class="rounded-circle" src="assets/img/people/a5.jpg" alt="...">
      </span>
      <!-- .circle is a pretty cool way to add a bit of beauty to raw data.
           should be used with bg-* and text-* classes for colors -->
      <span class="circle bg-warning fw-bold text-gray-dark">
        13
      </span>
      &nbsp; Philip
      <strong>Smith</strong>
      <b class="caret"></b>
    </a>
    <app-notifications *dropdownMenu class="dropdown-menu dropdown-menu-left animated animated-fast fadeInUp"></app-notifications>
  </div>
  <!-- main notification links are placed inside of .sidebar-nav -->
  <ul class="sidebar-nav">
    <li>
      <a class="collapsed" data-target="#sidebar-dashboard" data-toggle="collapse" data-parent="#sidebar">
        <span class="icon">
          <i class="fi flaticon-home"></i>
        </span>
        Dashboard
        <i class="toggle fa fa-angle-down"></i>
      </a>
      <ul id="sidebar-dashboard" class="collapse">
        <li>
          <a [routerLink]=" ['dashboard'] ">Dashboard</a>
        </li>
        <li>
          <a [routerLink]=" ['widgets'] ">Widgets</a>
        </li>
      </ul>
    </li>
    <li>
      <a [routerLink]=" ['inbox'] ">
        <span class="icon">
          <i class="fi flaticon-paper-plane"></i>
        </span>
        Email
        <span class="badge badge-pill badge-warning">
          9
        </span>
      </a>
    </li>
    <li>
      <a [routerLink]=" ['charts'] ">
        <span class="icon">
          <i class="fi flaticon-controls"></i>
        </span>
        Charts
      </a>
    </li>
    <li>
      <a [routerLink]=" ['profile'] ">
        <span class="icon">
          <i class="fi flaticon-user"></i>
        </span>
        Profile
        <sup class="text-warning fw-semi-bold">
          new
        </sup>
      </a>
    </li>
    <li>
      <a [routerLink]=" ['package'] ">
        <span class="icon">
          <i class="fi flaticon-database-1"></i>
        </span>
        Sing Package
        <sup class="text-warning fw-semi-bold">
          4.0
        </sup>
      </a>
    </li>
    <li>
      <a class="collapsed" data-target="#sidebar-ecommerce" data-toggle="collapse" data-parent="#sidebar">
        <span class="icon">
          <i class="fi flaticon-diamond"></i>
        </span>
        E-commerce
        <i class="toggle fa fa-angle-down"></i>
      </a>
      <ul id="sidebar-ecommerce" class="collapse">
        <li>
          <a [routerLink]=" ['ecommerce/product-grid'] ">Product Grid</a>
        </li>
        <li>
          <a [routerLink]=" ['ecommerce/product-detail'] ">Product Detail</a>
        </li>
      </ul>
    </li>
  </ul>
  <!-- every .sidebar-nav may have a title -->
  <h5 class="sidebar-nav-title">Template
    <a class="action-link" href="#">
      <i class="glyphicon glyphicon-refresh"></i>
    </a>
  </h5>
  <ul class="sidebar-nav">
    <li>
      <!-- an example of nested submenu. basic bootstrap collapse component -->
      <a class="collapsed" data-target="#sidebar-forms" data-toggle="collapse" data-parent="#sidebar">
        <span class="icon">
          <i class="fi flaticon-list"></i>
        </span>
        Forms
        <i class="toggle fa fa-angle-down"></i>
      </a>
      <ul id="sidebar-forms" class="collapse">
        <li>
          <a [routerLink]=" ['forms/elements'] ">Form Elements</a>
        </li>
        <li>
          <a [routerLink]=" ['forms/validation'] ">Form Validation</a>
        </li>
        <li>
          <a [routerLink]=" ['forms/wizard'] ">Form Wizard</a>
        </li>
      </ul>
    </li>
    <li>
      <a class="collapsed" data-target="#sidebar-ui" data-toggle="collapse" data-parent="#sidebar">
        <span class="icon">
          <i class="fi flaticon-layers"></i>
        </span>
        UI Elements
        <i class="toggle fa fa-angle-down"></i>
      </a>
      <ul id="sidebar-ui" class="collapse">
        <li>
          <a [routerLink]=" ['ui/components'] ">Components</a>
        </li>
        <li>
          <a [routerLink]=" ['ui/notifications'] ">Notifications</a>
        </li>
        <li>
          <a [routerLink]=" ['ui/icons'] ">Icons</a>
        </li>
        <li>
          <a [routerLink]=" ['ui/buttons'] ">Buttons</a>
        </li>
        <li>
          <a [routerLink]=" ['ui/tabs-accordion'] ">Tabs & Accordion</a>
        </li>
        <li>
          <a [routerLink]=" ['ui/list-groups'] ">List Groups</a>
        </li>
      </ul>
    </li>
    <li>
      <a [routerLink]=" ['grid'] ">
        <span class="icon">
          <i class="fi flaticon-menu-4"></i>
        </span>
        Grid
      </a>
    </li>
    <li>
      <a class="collapsed" data-target="#sidebar-maps" data-toggle="collapse" data-parent="#sidebar">
        <span class="icon">
          <i class="fi flaticon-map-location"></i>
        </span>
        Maps
        <i class="toggle fa fa-angle-down"></i>
      </a>
      <ul id="sidebar-maps" class="collapse">
        <li>
          <a [routerLink]=" ['maps/google'] ">Google Maps</a>
        </li>
        <li>
          <a [routerLink]=" ['maps/vector'] ">Vector Maps</a>
        </li>
      </ul>
    </li>
    <li>
      <!-- an example of nested submenu. basic bootstrap collapse component -->
      <a class="collapsed" data-target="#sidebar-tables" data-toggle="collapse" data-parent="#sidebar">
        <span class="icon">
          <i class="fi flaticon-equal-1"></i>
        </span>
        Tables
        <i class="toggle fa fa-angle-down"></i>
      </a>
      <ul id="sidebar-tables" class="collapse">
        <li>
          <a [routerLink]=" ['tables/basic'] ">Tables Basic</a>
        </li>
        <li>
          <a [routerLink]=" ['tables/dynamic'] ">Tables Dynamic
            <sup class="bg-transparent text-danger fs-sm fw-bold">ng2</sup>
          </a>
        </li>
      </ul>
    </li>
    <li>
      <a class="collapsed" data-target="#sidebar-extra" data-toggle="collapse" data-parent="#sidebar">
        <span class="icon">
          <i class="fi flaticon-star"></i>
        </span>
        Extra
        <i class="toggle fa fa-angle-down"></i>
      </a>
      <ul id="sidebar-extra" class="collapse">
        <li>
          <a [routerLink]=" ['extra/calendar'] ">Calendar
            <sup class="bg-transparent text-danger fs-sm fw-bold">ng2</sup>
          </a>
        </li>
        <li>
          <a [routerLink]=" ['extra/invoice'] ">Invoice</a>
        </li>
        <li>
          <a [routerLink]=" ['/login'] ">Login Page</a>
        </li>
        <li>
          <a [routerLink]=" ['/error'] ">Error Page</a>
        </li>
        <li>
          <a [routerLink]=" ['extra/gallery'] ">Gallery
            <sup class="bg-transparent text-danger fs-sm fw-bold">ng2</sup>
          </a>
        </li>
        <li>
          <a [routerLink]=" ['extra/search'] ">Search Results</a>
        </li>
        <li>
          <a [routerLink]=" ['extra/timeline'] ">Time Line</a>
        </li>
      </ul>
    </li>
    <li>
      <a class="collapsed" data-target="#sidebar-levels" data-toggle="collapse" data-parent="#sidebar">
        <span class="icon">
          <i class="fi flaticon-folder-10"></i>
        </span>
        Menu Levels
        <i class="toggle fa fa-angle-down"></i>
      </a>
      <ul id="sidebar-levels" class="collapse">
        <li>
          <a href>Level 1</a>
        </li>
        <li>
          <a class="collapsed" data-target="#sidebar-sub-levels" data-toggle="collapse" data-parent="#sidebar-levels">
            Level 2
            <i class="toggle fa fa-angle-down"></i>
          </a>
          <ul id="sidebar-sub-levels" class="collapse">
            <li>
              <a href>Level 3</a>
            </li>
            <li>
              <a href>Level 3</a>
            </li>
          </ul>
        </li>
      </ul>
    </li>
    <li *ngIf="configFn.isScreen('xs') || configFn.isScreen('sm')">
      <a (click)="logout()">
        <span class="icon">
          <i class="fa fa-sign-out"></i>
        </span>
        Logout
      </a>
    </li>
  </ul>
  <h5 class="sidebar-nav-title">Labels
    <a class="action-link" href="#">
      <i class="glyphicon glyphicon-plus"></i>
    </a>
  </h5>
  <!-- some styled links in sidebar. ready to use as links to email folders, projects, groups, etc -->
  <ul class="sidebar-labels">
    <li>
      <a href="#">
        <!-- yep, .circle again -->
        <i class="fa fa-circle text-warning mr-xs"></i>
        <span class="label-name text-muted">My Recent</span>
      </a>
    </li>
    <li>
      <a href="#">
        <i class="fa fa-circle text-gray mr-xs"></i>
        <span class="label-name text-muted">Starred</span>
      </a>
    </li>
    <li>
      <a href="#">
        <i class="fa fa-circle text-danger mr-xs"></i>
        <span class="label-name text-muted">Background</span>
      </a>
    </li>
  </ul>
  <h5 class="sidebar-nav-title">Projects</h5>
  <!-- A place for sidebar notifications & alerts -->
  <div class="sidebar-alerts">
    <div class="alert fade show">
      <a href="#" class="close" data-dismiss="alert" aria-hidden="true">&times;</a>
      <span>Sales Report</span>
      <br>
      <div class="progress progress-xs mt-xs mb-0">
        <div class="progress-bar progress-bar-gray-light" role="progressbar" style="width: 16%" aria-valuenow="16" aria-valuemin="0"
          aria-valuemax="100"></div>
      </div>
      <small>Calculating x-axis bias... 65%</small>
    </div>
    <div class="alert fade show">
      <a href="#" class="close" data-dismiss="alert" aria-hidden="true">&times;</a>
      <span>Personal Responsibility</span>
      <br>
      <div class="progress progress-xs mt-xs mb-0">
        <div class="progress-bar bg-danger" role="progressbar" style="width: 23%" aria-valuenow="23" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
      <small>Provide required notes</small>
    </div>
  </div>
</div>
