import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'help',
  templateUrl: './help.template.html',
  styleUrls: ['./help.style.scss'],
})
export class HelpComponent implements OnInit {
  public modalRef: BsModalRef;
  constructor(private modalService: BsModalService, private http: HttpClient) {}

  ngOnInit() {}

  /**
   * downloads pdf file
   */
  public downloadPDF(blobURL, filename, isSafariBrowser) {
    let dwldLink = document.createElement('a');
    if (isSafariBrowser) {
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.download = filename;
    dwldLink.href = blobURL;
    document.body.appendChild(dwldLink);
    dwldLink.click();
    dwldLink.remove();
  }

  /**
   * reads pdf file
   */
  public readPDFFile(fileURL, fileName) {
    return this.http.get(fileURL, { responseType: 'blob' }).subscribe(res => {
      let file = new Blob([res], { type: 'application/pdf' });
      // if (navigator.msSaveBlob) { // IE 10+
      //   navigator.msSaveBlob(new Blob([file], { type: 'application/pdf' }),fileName);
      // }
      let fileURL = window.URL.createObjectURL(file);
      let isSafariBrowser =
        navigator.userAgent.indexOf('Safari') !== -1 &&
        navigator.userAgent.indexOf('Chrome') === -1;
      this.downloadPDF(fileURL, fileName, isSafariBrowser);
    });
  }
}
