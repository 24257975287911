import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NumberFilter } from 'ag-grid-community';

export interface StudyInfo {
  ID: number;
  AccrualPeriodicity: string;
  StudyStartDate: Date;
  StudyEndDate: Date;
  DataCollectionStartDate: Date;
  DataCollectionEndDate: Date;
  Version: string;
  Title: string;
  SubmitterName: string;
  Subject: string;
  SummaryDescription: string;
  Source: string;
  KeywordTags: string;
  registrationNumber: string;
  DataStandards: string;
  Contribution: string;
  Format: string;
  Identifier: string;
  IsPartOf: string;
  Restrictions: string;
  Rights: string;
  DataOwner: string;
  DataOwnerEmail: string;
  GrantNumber: string;
  IsFundedBy: string;
  DateCreated: Date;
  DateModified: Date;
}

@Injectable()
export class NIHStudyDataService {
  private _states: StudyInfo[];
  private observableStates: BehaviorSubject<StudyInfo[]>;
  private statesURL = 'assets/data/nihstudy.json';

  constructor(private http: HttpClient) {
    this._states = new Array<StudyInfo>();
    this.observableStates = <BehaviorSubject<StudyInfo[]>>(
      new BehaviorSubject([])
    );
    this.loadStudyInfo();
  }

  public loadStudyInfo() {
    return this.http.get<StudyInfo[]>(this.statesURL);
  }
}
