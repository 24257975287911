import {
  Directive,
  HostListener,
  ElementRef,
  OnInit,
  Input,
} from '@angular/core';

import { CustomNumberValidator } from './custom.number.validator';
import { CustomDecimalValidator } from './custom.decimal.validator';
import { CustomCurrencyValidator } from './custom.currency.validator';

@Directive({ selector: '[customValidator]' })
export class CustomValidatorDirective implements OnInit {
  @Input('customValidator') args;

  private el: HTMLInputElement;
  private validators = {};

  constructor(private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
    this.validators['number'] = new CustomNumberValidator();
    this.validators['decimal'] = new CustomDecimalValidator();
    this.validators['currency'] = new CustomCurrencyValidator();
  }

  ngOnInit() {}

  @HostListener('focus', ['$event.target.value'])
  onFocus(value) {
    if (this.el.value != null && this.el.value.trim() !== '') {
      this.args.ttype[this.args.errprop] =
        this.validators[this.args.valtype].validate(value);
    }
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value) {
    if (this.el.value != null && this.el.value.trim() !== '') {
      this.args.ttype[this.args.errprop] =
        this.validators[this.args.valtype].validate(value);
    }
  }
}
