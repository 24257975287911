import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { OAuthModule } from 'angular-oauth2-oidc';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AppComponent } from './app.component';
import { AppConfig } from './app.config';
import { appRoutes } from './app.routes';
import { ErrorComponent } from './error/error.component';
import { HomeComponent } from './home.component';
import { LayoutModule } from './layout/layout.module';
import { LoginModule } from './modules/login/login.module';
import { AuthGuard } from './modules/login/services/auth-guard.service';
import { AuthService } from './modules/login/services/auth.service';
import { CanDeactivateGuardService } from './modules/login/services/can-deactivate-guard.service';
import { ProfileService } from './modules/login/services/profile.service';
import { JwtInterceptor } from './_helpers';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { UserIdleModule } from 'angular-user-idle';
import { PingAuthService } from './modules/login/services/ping-auth.service';
import { AccordionModule } from 'ngx-bootstrap/accordion';

const APP_PROVIDERS = [AppConfig];

const SESSION_IDLE_TIMOUT_DURATION = 900; // 900; // 15 minutes
const SESSION_TIMEOUT_WARNING_DURATION = 60; // 1 minutes
const SESSION_TOKEN_REFRESH_DURATION = 120; // 2 minutes


@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent, ErrorComponent, HomeComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    LoginModule,
    HttpClientModule,
    LayoutModule,   
    AccordionModule.forRoot(),    
    LoggerModule.forRoot({
      serverLoggingUrl: '/api/logs',
      level: NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.ERROR
    }),
    NgxPermissionsModule.forRoot(),
    RouterModule.forRoot(appRoutes, {
      useHash: false,
      preloadingStrategy: PreloadAllModules,
    }),
    OAuthModule.forRoot(),
    // Optionally you can set time for `idle`, `timeout` and `ping` in seconds.
    // Default values: `idle` is 600 (10 minutes), `timeout` is 300 (5 minutes)
    // and `ping` is 120 (2 minutes).
    UserIdleModule.forRoot({
      idle: SESSION_IDLE_TIMOUT_DURATION,
      timeout: SESSION_TIMEOUT_WARNING_DURATION,
      ping: SESSION_TOKEN_REFRESH_DURATION,
    }),
  ],
  providers: [
    APP_PROVIDERS,
    AuthGuard,
    AuthService,
    PingAuthService,
    ProfileService,
    CanDeactivateGuardService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
  ],
})
export class AppModule {}
