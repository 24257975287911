import { AfterViewInit, Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from './modules/login/services/auth.service';

declare var jQuery: any;

let skipAuthorization: boolean = false;

@Component({
  selector: 'home',
  styleUrls: ['./home.style.scss'],
  templateUrl: './home.template.html',
  encapsulation: ViewEncapsulation.None,
  providers: [],
  host: {
    class: 'home-page app',
  },
})
export class HomeComponent implements AfterViewInit {
  // model = new Credentials("", "");
  showErrorFlag: boolean;
  alerts: Array<Object>;
  currentYear: string;
  showConfirmPopup: boolean = false;
  loginData: any;
  subscription: Subscription;
  public loggedIn: boolean;

  constructor(private router: Router, private auth: AuthService) {
    this.alerts = [
      {
        type: 'warning',
        msg: '<span class="fw-semi-bold">Warning:</span> Error Logging in',
      },
    ];

    this.showErrorFlag = false;
    this.currentYear = new Date().getFullYear().toString();
    this.auth.setTopNavBarShowFlag(false);
  }

  ngOnInit(): void {
    this.showErrorFlag = false;
  }

  ngOnDestroy() {
    // this.subscription.unsubscribe();
  }

  ngAfterViewInit() {
    // jQuery('#uname').focus();
  }

  clearLocalStorageContent() {}

  login() {}

  logout() {
    this.auth.signOut();
  }

  viewHelpResources() {}

  launch(){}
}
