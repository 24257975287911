<div class="pingcallback-wrapper">
  <div class="pingcallback pingcallback__container">
    <!--header section-->
    <header>
      <h1 class="d-none">Login Page</h1>
      <!-- <div class="row">
        <div class="col-sm-6">
          <div>
            <img
              src="assets/img/fda-logo-color.png"
              class="app-logo"
              alt="App Logo" />
          </div>
        </div>
      </div> -->
    </header>
    <div class="container">
      <main id="content" role="main" class="h100 mt70">
        <div class="row">
          <!-- Right half of the page with the login form -->
          <div
            [ngBusy]="{busy: busyLoggingIn, message: 'Authenticating User...', backdrop: false}"
            class="col-md-12 login-right pingcallback__center">
            <ngx-spinner
              bdColor="rgba(51,51,51,0.8)"
              name="sp4"
              color="#007CBA"
              [fullScreen]="true"
              type="pacman"
              size="large"></ngx-spinner>
          </div>
        </div>
      </main>
    </div>
  </div>
</div>
