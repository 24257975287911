import {
  Directive,
  EventEmitter,
  ElementRef,
  HostListener,
  Input,
  Output,
  Renderer2,
} from '@angular/core';

function setProperty(
  renderer: Renderer2,
  elementRef: ElementRef,
  propName: string,
  propValue: any
): void {
  renderer.setProperty(elementRef, propName, propValue);
}

@Directive({ selector: '[ngTableFiltering]' })
export class NgTableFilteringDirective {
  @Input() public ngTableFiltering: any = {
    filterString: '',
    columnName: '',
    placeholder: '',
    refresh: false,
  };

  @Output() public tableChanged: EventEmitter<any> = new EventEmitter();

  @Input()
  public get config(): any {
    return this.ngTableFiltering;
  }

  public set config(value: any) {
    this.ngTableFiltering = value;
  }

  private element: ElementRef;
  private renderer: Renderer2;

  @HostListener('input', ['$event.target.value'])
  public onChangeFilter(event: any): void {
    this.ngTableFiltering.refresh = false;
    this.ngTableFiltering.filterString = event;
    this.tableChanged.emit(this.ngTableFiltering);
  }

  @HostListener('keypress', ['$event', '$event.target.value'])
  keypress(event: KeyboardEvent, value) {
    if (event.which === 13 || event.keyCode === 13) {
      this.ngTableFiltering.refresh = true;
      this.tableChanged.emit(this.ngTableFiltering);
      return false;
    }
  }

  public constructor(element: ElementRef, renderer: Renderer2) {
    this.element = element;
    this.renderer = renderer;
    // Set default value for filter
    setProperty(
      this.renderer,
      this.element,
      'value',
      this.ngTableFiltering.filterString
    );
  }
}
