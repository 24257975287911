import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DataTable } from './DataTable';
import { DefaultSorter } from './DefaultSorter';
import { Paginator } from './Paginator';
import { BootstrapPaginator } from './BootstrapPaginator';
import { RowSelector } from './RowSelector';
import { RowSelectorHead } from './RowSelectorHead';

//import {DataTableNavigationModule} from "./DataTableNavigationModule"
declare var jQuery: any;

@NgModule({
  imports: [
    CommonModule,
    //DataTableNavigationModule
  ],
  declarations: [
    DataTable,
    DefaultSorter,
    Paginator,
    BootstrapPaginator,
    RowSelector,
    RowSelectorHead,
  ],
  exports: [
    DataTable,
    DefaultSorter,
    Paginator,
    BootstrapPaginator,
    RowSelector,
    RowSelectorHead,
  ],
})
export class DataTableModule {
  constructor() {}
}
