import {
  Component,
  Output,
  EventEmitter,
  ContentChildren,
  QueryList,
  AfterContentInit,
} from '@angular/core';
import { MDWizardStepComponent } from './wizard-step.component';

@Component({
  selector: 'md-form-wizard',
  template: `<div class="card">
    <div class="card-header">
      <ul class="nav nav-justified">
        <li
          class="nav-item"
          *ngFor="let step of steps"
          [ngClass]="{
            active: step.isActive,
            enabled: !step.isDisabled,
            disabled: step.isDisabled,
            completed: isCompleted
          }">
          <a (click)="goToStep(step)">{{ step.title }}</a>
        </li>
      </ul>
    </div>
    <div class="card-block">
      <ng-content></ng-content>
    </div>
    <div class="card-footer" [hidden]="isCompleted">
      <button
        type="button"
        class="btn btn-primary float-left"
        (click)="previous()"
        [hidden]="!hasPrevStep || !activeStep.showPrev">
        Previous
      </button>
      <button
        type="button"
        class="btn btn-primary float-right"
        (click)="next()"
        [disabled]="!activeStep.isValid"
        [hidden]="!hasNextStep || !activeStep.showNext">
        Next
      </button>
      <button
        type="button"
        class="btn btn-primary float-right"
        (click)="complete()"
        [disabled]="!activeStep.isValid"
        [hidden]="hasNextStep">
        Done
      </button>
    </div>
  </div>`,
  styles: [
    '.card { height: 100%; }',
    '.card-header { background-color: #fff; padding: 0; font-size: 1.25rem; }',
    '.card-block { overflow-y: auto; }',
    '.card-footer { background-color: #fff; border-top: 0 none; }',
    '.nav-item { padding: 1rem 0rem; border-bottom: 0.5rem solid #ccc; }',
    '.active { font-weight: bold; color: black; border-bottom-color: red !important; }',
    '.enabled { cursor: pointer; border-bottom-color: rgb(88, 162, 234); }',
    '.disabled { color: #ccc; }',
    '.completed { cursor: default; }',
  ],
})
export class MDWizardComponent implements AfterContentInit {
  @ContentChildren(MDWizardStepComponent)
  wizardSteps: QueryList<MDWizardStepComponent>;

  private _steps: Array<MDWizardStepComponent> = [];
  private _isCompleted: boolean = false;

  @Output() onStepChanged: EventEmitter<MDWizardStepComponent> =
    new EventEmitter<MDWizardStepComponent>();

  constructor() {}

  ngAfterContentInit() {
    this.wizardSteps.forEach(step => this._steps.push(step));
    this.steps[0].isActive = true;
  }

  get steps(): Array<MDWizardStepComponent> {
    return this._steps.filter(step => !step.hidden);
  }

  get isCompleted(): boolean {
    return this._isCompleted;
  }

  get activeStep(): MDWizardStepComponent {
    return this.steps.find(step => step.isActive);
  }

  set activeStep(step: MDWizardStepComponent) {
    if (step !== this.activeStep && !step.isDisabled) {
      this.activeStep.isActive = false;
      step.isActive = true;
      this.onStepChanged.emit(step);
    }
  }

  public get activeStepIndex(): number {
    return this.steps.indexOf(this.activeStep);
  }

  get hasNextStep(): boolean {
    return this.activeStepIndex < this.steps.length - 1;
  }

  get hasPrevStep(): boolean {
    return this.activeStepIndex > 0;
  }

  public goToStep(step: MDWizardStepComponent): void {
    if (!this.isCompleted) {
      this.activeStep = step;
    }
  }

  public next(): void {
    if (this.hasNextStep) {
      let nextStep: MDWizardStepComponent =
        this.steps[this.activeStepIndex + 1];
      this.activeStep.onNext.emit(this.activeStep);
      if (this.activeStep.isValidated) {
        nextStep.isDisabled = false;
        this.activeStep = nextStep;
      }
    }
  }

  public previous(): void {
    if (this.hasPrevStep) {
      let prevStep: MDWizardStepComponent =
        this.steps[this.activeStepIndex - 1];
      this.activeStep.onPrev.emit();
      prevStep.isDisabled = false;
      this.activeStep = prevStep;
    }
  }

  public complete(): void {
    this.activeStep.onComplete.emit();
    this._isCompleted = true;
  }
}
