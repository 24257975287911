export class MaterialInfo {
  uuid: string;
  status: string; // Compliant, Non-Compliant, Pending Review
  review_status: string; // Pending Approval, Rejected, Approved, Auto Approved
  reviewer: string; // System, Email  
  name: string;
  bbox_height: number;
  bbox_left: number;
  bbox_top: number;
  bbox_width: number;
  detect_area_in_sq_pixels: number;
  box_area_in_sq_pixels: number;
  prediction_area_percentage: number;
  rekognition_confidence: number;
  warning_text: string;
  url: string;
  created_at: Date;
  reviewed_at: Date;
  modified_at: Date;

  constructor(){
    this.status = "Pending Review";
    this.review_status = "Pending Approval";
    this.reviewer = "Not Assigned";
  }
}
