import { isNil } from 'ramda';
import { RegisteredAccountUser } from './reg-account-user.model';

export class LoginState {
  private accountConfirmed: boolean = false;
  private emailVerified: boolean = false;
  private loggedIn: boolean = false;
  private user: RegisteredAccountUser = null;
  private username: string = null;

  /**
   * Clears login state
   */
  clear() {
    this.accountConfirmed = false;
    this.emailVerified = false;
    this.loggedIn = false;
    this.user = null;
    this.username = null;
  }

  /**
   * Gets user
   * @returns user
   */
  getUser(): RegisteredAccountUser {
    return this.user;
  }

  /**
   * Gets username
   * @returns username
   */
  getUsername(): string {
    if (!isNil(this.user)) {
      return this.user.username;
    }
    return null;
  }

  /**
   * Determines whether account confirmed is
   * @returns true if account confirmed
   */
  isAccountConfirmed(): boolean {
    return this.accountConfirmed;
  }

  /**
   * Determines whether email verified is
   * @returns true if email verified
   */
  isEmailVerified(): boolean {
    return this.emailVerified;
  }

  /**
   * Determines whether logged in is
   * @returns true if logged in
   */
  isLoggedIn(): boolean {
    return this.loggedIn;
  }

  /**
   * Signs up
   * @param user
   */
  public signUp(user: RegisteredAccountUser) {
    this.user = user;
    this.accountConfirmed = false;
  }

  /**
   * Confirms registration
   */
  confirmRegistration() {
    this.user = null;
  }

  /**
   * Emails updated
   * @param username
   */
  public emailUpdated(username: string) {
    this.loggedIn = false;
    this.emailVerified = false;
    this.username = username;
  }

  /**
   * Verifies email
   */
  public verifyEmail() {
    this.emailVerified = true;
  }

  /**
   * Signs in failed unconfirmed
   * @param username
   */
  signInFailedUnconfirmed(username: string) {
    this.username = username;
    this.accountConfirmed = false;
  }

  /**
   * Signed in but email unverified
   */
  signedInButEmailUnverified() {
    this.accountConfirmed = true;
    this.emailVerified = false;
  }

  /**
   * Successfuls sign in
   */
  successfulSignIn() {
    this.loggedIn = true;
    this.accountConfirmed = true;
    this.emailVerified = true;
  }

  /**
   * Confirms signup
   */
  confirmSignup() {
    this.accountConfirmed = true;
    this.emailVerified = true;
  }
}
