import { Component, Input, OnChanges } from '@angular/core';
import { DataTable } from './DataTable';
import * as _ from 'lodash';

@Component({
  selector: 'mfBootstrapPaginator',
  template: `
    <mfPaginator #p [mfTable]="mfTable">
      <ul class="pagination" *ngIf="p.dataLength > p.rowsOnPage">
        <li [class.disabled]="p.activePage <= 1">
          <a
            tabindex="0"
            (click)="p.setPage(1)"
            (keyup.enter)="p.setPage(1)"
            (keyup.space)="p.setPage(1)"
            style="cursor: pointer"
            >&laquo;</a
          >
        </li>
        <li *ngIf="p.activePage > 4 && p.activePage + 1 > p.lastPage">
          <a
            tabindex="0"
            (click)="p.setPage(p.activePage - 4)"
            (keyup.enter)="p.setPage(p.activePage - 4)"
            (keyup.space)="p.setPage(p.activePage - 4)"
            style="cursor: pointer"
            >{{ p.activePage - 4 }}</a
          >
        </li>
        <li *ngIf="p.activePage > 3 && p.activePage + 2 > p.lastPage">
          <a
            tabindex="0"
            (click)="p.setPage(p.activePage - 3)"
            (keyup.enter)="p.setPage(p.activePage - 3)"
            (keyup.space)="p.setPage(p.activePage - 3)"
            style="cursor: pointer"
            >{{ p.activePage - 3 }}</a
          >
        </li>
        <li *ngIf="p.activePage > 2">
          <a
            tabindex="0"
            (click)="p.setPage(p.activePage - 2)"
            (keyup.enter)="p.setPage(p.activePage - 2)"
            (keyup.space)="p.setPage(p.activePage - 2)"
            style="cursor: pointer"
            >{{ p.activePage - 2 }}</a
          >
        </li>
        <li *ngIf="p.activePage > 1">
          <a
            tabindex="0"
            (click)="p.setPage(p.activePage - 1)"
            (keyup.enter)="p.setPage(p.activePage - 1)"
            (keyup.space)="p.setPage(p.activePage - 1)"
            style="cursor: pointer"
            >{{ p.activePage - 1 }}</a
          >
        </li>
        <li class="active">
          <a tabindex="0" style="cursor: pointer">{{ p.activePage }}</a>
        </li>
        <li *ngIf="p.activePage + 1 <= p.lastPage">
          <a
            tabindex="0"
            (click)="p.setPage(p.activePage + 1)"
            (keyup.enter)="p.setPage(p.activePage + 1)"
            (keyup.space)="p.setPage(p.activePage + 1)"
            style="cursor: pointer"
            >{{ p.activePage + 1 }}</a
          >
        </li>
        <li *ngIf="p.activePage + 2 <= p.lastPage">
          <a
            tabindex="0"
            (click)="p.setPage(p.activePage + 2)"
            (keyup.enter)="p.setPage(p.activePage + 2)"
            (keyup.space)="p.setPage(p.activePage + 2)"
            style="cursor: pointer"
            >{{ p.activePage + 2 }}</a
          >
        </li>
        <li *ngIf="p.activePage + 3 <= p.lastPage && p.activePage < 3">
          <a
            tabindex="0"
            (click)="p.setPage(p.activePage + 3)"
            (keyup.enter)="p.setPage(p.activePage + 3)"
            (keyup.space)="p.setPage(p.activePage + 3)"
            style="cursor: pointer"
            >{{ p.activePage + 3 }}</a
          >
        </li>
        <li *ngIf="p.activePage + 4 <= p.lastPage && p.activePage < 2">
          <a
            tabindex="0"
            (click)="p.setPage(p.activePage + 4)"
            (keyup.enter)="p.setPage(p.activePage + 4)"
            (keyup.space)="p.setPage(p.activePage + 4)"
            style="cursor: pointer"
            >{{ p.activePage + 4 }}</a
          >
        </li>
        <li [class.disabled]="p.activePage >= p.lastPage">
          <a
            tabindex="0"
            (click)="p.setPage(p.lastPage)"
            (keyup.enter)="p.setPage(p.lastPage)"
            (keyup.space)="p.setPage(p.lastPage)"
            style="cursor: pointer"
            >&raquo;</a
          >
        </li>
      </ul>
      <ul
        class="pagination pull-right pageResults"
        style="position:relative;"
        *ngIf="p.dataLength > minRowsOnPage">
        <li
          *ngFor="let rows of rowsOnPageSet"
          [class.active]="p.rowsOnPage === rows">
          <a
            tabindex="0"
            (click)="p.setRowsOnPage(rows)"
            (keyup.enter)="p.setRowsOnPage(rows)"
            (keyup.space)="p.setRowsOnPage(rows)"
            style="cursor: pointer"
            >{{ rows }}</a
          >
        </li>
      </ul>
      <ul
        class="pagination pull-right pageResults txt-gap"
        style="position:relative;padding-top:0.5rem"
        *ngIf="p.dataLength > minRowsOnPage">
        <li>
          <span class="fw-semi-bold txt-gap"
            >{{ p.dataLength }} Record(s) Found</span
          >
        </li>
      </ul>
    </mfPaginator>
  `,
})
export class BootstrapPaginator implements OnChanges {
  @Input('rowsOnPageSet') rowsOnPageSet = [];
  @Input('mfTable') mfTable: DataTable;

  minRowsOnPage = 0;

  ngOnChanges(changes: any): any {
    if (changes.rowsOnPageSet) {
      this.minRowsOnPage = _.min(this.rowsOnPageSet);
    }
  }
}
