import { UserAccountType } from './accounttype.enum';

/**
 *
 *
 * @export
 * @class RegistrationUser
 */
export class RegisteredAccountUser {
  /**
   * Username  of registered account user
   */
  username: string;
  /**
   * Account type of registered account user
   */
  accountType: UserAccountType;
  /**
   * First name  of registered account user
   */
  firstName: string;

  /**
   * Last name of registered account user
   */
  lastName: string;
  /**
   * Phone number of registered account user
   */
  phoneNumber: any;

  /**
   * Phone number of registered account user
   */
  phoneType: string;

  /**
   * Estb id of registered account user
   */
  estb_id: string;
  /**
   * Email of registered account user
   */
  email: string;
  /**
   * Confirm email of registered account user
   */
  confirmEmail: string;
  /**
   * Password of registered account user
   */
  password: string;
  /**
   * Confirm password of registered account user
   */
  confirmPassword: string;
  /**
   * Confirmation code of registered account user
   */
  confirmationCode: string;
  /**
   * Email verified of registered account user
   */
  emailVerified: boolean;
  /**
   * Reason for contacting ORG
   */
  reasonForContact: string;
  /**
   * Msg to be sent
   */
  message: string;
  public populate(user: any) {
    if (user) {
      this.firstName = user.username;
      this.lastName = user.username;
      let attributes = user.attributes;
      this.estb_id = attributes['custom:estb_id'];
      this.email = attributes['email'];
      this.emailVerified = attributes['email_verified'];
    }
  }
}
