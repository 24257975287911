import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

declare var jQuery: any;
@Component({
  selector: '[logout]',
  styleUrls: ['./login.style.scss'],
  templateUrl: './logout.template.html',
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'login-page app',
  },
})
export class LogoutComponent implements OnInit {
  showErrorFlag: boolean;
  alerts: Array<Object>;
  currentYear: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.alerts = [
      {
        type: 'warning',
        msg: '<span class="fw-semi-bold">Warning:</span> Error Logging in',
      },
    ];

    this.showErrorFlag = false;
    this.currentYear = new Date().getFullYear().toString();
  }

  ngOnInit(): void {
    this.showErrorFlag = false;
    sessionStorage.clear();
  }

  login() {
    this.router.navigate(['login']);
  }

  logout() {
    this.router.navigate(['logout']);
  }
}
