<div class="help__container mt70" id="sec-help">
  <div class="clearfix">
    <div class="row help__header">
      <div class="col-md-12">
        <h1 class="h1 fw-bold">Need Help?</h1>
      </div>
    </div>

    <div class="row help__header">
      <div class="col-md-8">
        <!-- First Section -->
        <section class="widget widget-light" id="sec-help1-instruct">
          <div class="mt10 fw-bold help__instructions">User Guide</div>
          <div class="mt10">
            For assistance with completing your registration and navigating the
            application.
          </div>
          <div class="mt20">
            <button
              type="button"
              class="btn btn-outline-primary"
              (click)="readPDFFile('assets/data/TRLMNGUserGuide.pdf','TRLMNGUserGuide.pdf')">
              Download User Guide (.pdf)
            </button>
          </div>
        </section>
      </div>
      <!--Second Column-->
      <div class="col-md-4">
        <section class="widget widget-light" id="sec-addp-instruct">
          <h3 class="h3 fw-bold">Need Assistance?</h3>
          <div class="mt10"></div>
        </section>
      </div>
    </div>
  </div>
</div>
