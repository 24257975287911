import { Pipe, PipeTransform } from '@angular/core';

/*
 * Changes a number value to an absolute value
 */

@Pipe({ name: 'abs', pure: false })
export class AbsoluteValuePipe implements PipeTransform {
  transform(input: number): number {
    if (!input && input !== 0) {
      return input;
    }
    return Math.abs(input);
  }
}
