import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { PaginationOptions } from '../../models/pagination.model';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.template.html',
  styleUrls: ['./pagination.style.scss'],
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() paginationOptions: PaginationOptions;
  //@Input() filter:any;
  @Input() totalFileCount: number;
  @Input() fileType: string;
  @Output() getPaginatedResult: EventEmitter<boolean> = new EventEmitter();

  public maxRecords: number = 10000;
  public maxTotalPage: number = 1;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.totalFileCount) {
      this.setMaxPage();
    }
  }

  /**
   * Set Max page size depends on total records available.
   */
  public setMaxPage() {
    this.paginationOptions.maxTotalPage =
      this.paginationOptions.totalPages >
      Math.ceil(this.maxRecords / Number(this.paginationOptions.selPageSize))
        ? Math.ceil(
            this.maxRecords / Number(this.paginationOptions.selPageSize)
          )
        : this.paginationOptions.totalPages;
  }

  /**
   * Method to reload  page data when the page size chnages
   */
  public onPageSizeChanged() {
    let that = this;
    //let value = this.paginationOptions.selPageSize;
    //this.filter.size = value;

    this.paginationOptions.totalPages = Math.ceil(
      this.totalFileCount / Number(this.paginationOptions.selPageSize)
    );
    this.setMaxPage();

    if (
      this.paginationOptions.currentPage > this.paginationOptions.maxTotalPage
    ) {
      this.paginationOptions.currentPage = this.paginationOptions.maxTotalPage;
      this.paginationOptions.jumpToPageNumber =
        this.paginationOptions.maxTotalPage;
    }

    //let from = (this.paginationOptions.currentPage - 1) * Number(this.paginationOptions.selPageSize);
    //this.filter.from = from;

    this.getPaginatedResult.emit(true);
  }

  /**
   * Change page and reload data
   * @param  pageNumber
   */
  public goToPage(pageNumber) {
    pageNumber =
      pageNumber > this.paginationOptions.maxTotalPage
        ? this.paginationOptions.maxTotalPage
        : pageNumber;
    pageNumber = pageNumber < 1 ? 1 : pageNumber;
    //if (pageNumber > 0 && pageNumber <= this.paginationOptions.maxTotalPage) {
    this.paginationOptions.currentPage = Number(pageNumber);
    this.paginationOptions.jumpToPageNumber = pageNumber;
    //let that = this;
    //let from = (pageNumber - 1) * Number(this.paginationOptions.selPageSize);
    //this.filter.from = from;
    //this.filter.size = this.paginationOptions.selPageSize;
    this.getPaginatedResult.emit(true);
    //}
  }
}
