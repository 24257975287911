import { Component } from '@angular/core';
import { TabUtilsHelper } from '../../util/tab-utils';

declare var jQuery: any;

@Component({
  template: '',
})
export class BaseComponent {
  /**
   * Show error flag of base component
   */
  public showErrorFlag: boolean;
  /**
   * Error title of base component
   */
  public errorTitle: string;
  /**
   * Error description of base component
   */
  public errorDescription: string;
  /**
   * Alerts  of base component
   */
  public alerts: Array<Object>;

  constructor() {
    this.resetError();
  }

  /**
   * Resets error
   */
  public resetError() {
    this.showErrorFlag = false;
    this.errorTitle = '';
    this.errorDescription = '';
    this.alerts = [];
  }

  /**
   * Sets error
   * @param title
   * @param description
   */
  public setError(title: string, description: string) {
    this.showErrorFlag = true;
    this.errorTitle = title;
    this.errorDescription = description;
  }

  /**
   * Shows error
   * @param error
   * @param messageType
   * @param title
   */
  public showError(
    error: string,
    messageType: string = 'warning',
    MessageTitle: string = ''
  ) {
    this.showErrorFlag = true;
    this.alerts = [];
    this.alerts.push({ type: messageType, msg: error, title: MessageTitle });
  }

  /**
   * Scrolls to element by id
   * @param topClass
   * @param elementId
   */
  public scrollToElementById(topClass: string, elementId: string) {
    try {
      jQuery('html, body, ' + topClass).animate(
        {
          /*Scroll to the top of element in respect to the
         '.content-wrap' since the value of .top will depend
         on scroll position*/
          scrollTop:
            jQuery('#' + elementId).offset().top -
            jQuery(topClass).offset().top,
        },
        1000
      );
    } catch (err) {}
  }

  /**
   * Scrolls to element by class
   * @param topClass
   * @param elemClass
   */
  public scrollToElementByClass(topClass: string, elemClass: string) {
    try {
      jQuery('html, body, ' + topClass).animate(
        {
          /*Scroll to the top of element in respect to the
        '.content-wrap' since the value of .top will depend
        on scroll position*/
          scrollTop:
            jQuery(elemClass).offset().top - jQuery(topClass).offset().top,
        },
        1000
      );
    } catch (err) {}
  }

  /**
   * Scrolls to top of page
   */
  public scrollToTopOfPage() {
    try {
      jQuery('html, body').animate(
        {
          /*Scroll to the top of element in respect to the
        '.content-wrap' since the value of .top will depend
        on scroll position*/
          scrollTop: 0,
        },
        1000
      );
    } catch (err) {}
  }

  /**
   * Prevents tab back
   * @param event
   * @param [condition]
   */
  public preventTabBack(event, condition?) {
    TabUtilsHelper.preventTabBack(event, condition);
  }
  /**
   * Prevents tab
   * @param event
   * @param [condition]
   */
  public preventTab(event, condition?) {
    TabUtilsHelper.preventTab(event, condition);
  }
}
