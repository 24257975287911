import { Pipe, PipeTransform } from '@angular/core';

const PADDING = '000000';

@Pipe({ name: 'customnumber' })
export class CustomNumberPipe implements PipeTransform {
  private DECIMAL_SEPARATOR: string;
  private THOUSANDS_SEPARATOR: string;

  constructor() {
    this.DECIMAL_SEPARATOR = '.';
    this.THOUSANDS_SEPARATOR = ',';
  }

  transform(value: number | string, fractionSize: number = 2): string {
    if (typeof value !== 'undefined' && value != null) {
      let [integer, fraction = ''] = (value || '')
        .toString()
        .split(this.DECIMAL_SEPARATOR);

      fraction =
        fractionSize > 0
          ? this.DECIMAL_SEPARATOR +
            (fraction + PADDING).substring(0, fractionSize)
          : '';

      integer = integer
        ? integer.replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_SEPARATOR)
        : '0';

      return integer + fraction;
    }
  }

  parse(value: string, fractionSize: number = 2): string {
    if (typeof value !== 'undefined' && value != null) {
      let [integer, fraction = ''] = (value || '').split(
        this.DECIMAL_SEPARATOR
      );

      integer = integer.replace(new RegExp(this.THOUSANDS_SEPARATOR, 'g'), '');

      fraction =
        parseInt(fraction, 10) > 0 && fractionSize > 0
          ? this.DECIMAL_SEPARATOR +
            (fraction + PADDING).substring(0, fractionSize)
          : '';

      return integer + fraction;
    }
  }
}
