import { Directive, HostListener } from '@angular/core';
import { Input } from '@angular/core';

@Directive({
  selector: '[confirmxyz]',
})
export class ConfirmDirective {
  @Input() confirmMessage: string = 'Are you sure you want to do this?';

  @HostListener('click', ['$event'])
  confirmFirst(event: Event) {
    return window.confirm(this.confirmMessage);
  }
}
