<div
  class="alert"
  [ngClass]="{'alert-danger tl-inline-alert-error':  type === 'ERROR', 
              'alert-warning tl-inline-alert-warning':type != 'ERROR', 
              'mt20': !isNoMargin, 'mb-0': isNoMargin,
              'spinner-wrapper':isSpinnerNeeded }"
  *ngIf="true">
  <!--Spinner is related the size of icon. Please adjust both sizes together if needed -->
  <i
    class="fas tl-icon"
    [ngClass]="[type === 'ERROR' ? 'fa-times-circle' : 'fa-exclamation-circle']"></i>
  <span *ngIf="isSpinnerNeeded" class="spinner-border txt-gap ml10"></span>
  <p class="fw-semi-bold" *ngIf="title">{{title}}</p>
  <p class="mb-0" *ngIf="description" [innerHTML]="description"></p>
  <ul *ngIf="descriptionList" class="desc-list">
    <li *ngFor="let descmsg of descriptionList">{{descmsg}}</li>
  </ul>
</div>
