<div class="homebox-wrapper">
  <div class="homebox homebox__container">
    <!--header section-->
    <header>
      <h1 class="d-none">Home</h1>
      <div class="row">
        <div class="col-sm-6">
          <!-- <img
            src="assets/img/APP_NEXTGEN.png"
            class="app-logo"
            alt="app Logo" /> -->
        </div>
        <div class="col-sm-6">
          <div class="helplinktext">
            <a [routerLink]="['/signin']" class="ml20"
              ><i class="fas fa-fw fa-sign-in-alt txt-gap"></i>Login</a
            >
          </div>
        </div>
      </div>
    </header>
    <!-- Header section ends-->

    <!-- Login section -->
    <div class="container">
      <main id="content" role="main" class="h100 mt70">
        <section class="widget indwel__widget" widget>
          <div class="widget-body">
            <div class="row indwel__getstarted ml20">
              <h1 loginclass="h1 fw-bold mlc">
                <img
                  src="assets/img/aiml.png"
                  class="aiml-logo"
                  alt="App Logo" />About IntelliReview App
              </h1>
            </div>
          </div>
          <hr />
          <div class="row mt20 ml20">
            This applications demonstrates GenAI use cases that aids the agency achieve its mission objectives of protecting the public health by ensuring the safety, efficacy, and security of human and veterinary drugs, biological products, and medical devices faster. 
          </div>
          <!-- <div class="row mt20 ml20">
            <h3>Use Cases:</h3>
          </div>
          <div class="row ml20">
            <div class="col-md-12">
              <accordion>
                <accordion-group #policyChatGroup>
                  <div accordion-heading style="width: 100%">
                    <span class="fw-bold"><i class="fas fa-fw fa-comment blue txt-gap"></i>Agency Policy Chatbot</span>
                    <i
                      class="float-right fas"
                      [ngClass]="{'fa-chevron-down': policyChatGroup?.isOpen, 'fa-chevron-right': !policyChatGroup?.isOpen}"></i>
                  </div>                 
                  <div class="row">
                    <div class="col-md-12">
                      <p>Use of LLM and RAG concepts to ask q&a to the agency policy chatbot</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <button class="btn btn-link floatr" type="button" (click)="launch()">
                        <i class="fas fa-rocket txt-gap"></i>Demo
                      </button>
                    </div>
                  </div>
                </accordion-group>                
                <accordion-group #accGroup>
                  <div accordion-heading style="width: 100%">
                    <span class="fw-bold"><i class="fas fa-fw fa-smoking blue txt-gap"></i>Review Tobacco Warning Labels</span>
                    <i
                      class="float-right fas"
                      [ngClass]="{'fa-chevron-down': accGroup?.isOpen, 'fa-chevron-right': !accGroup?.isOpen}"></i>
                  </div>                  
                 <p>Review tobacco warning labels for regulatory compliance.</p>
                </accordion-group>
                <accordion-group #drugAds>
                  <div accordion-heading style="width: 100%">
                    <span class="fw-bold"><i class="fas fa-fw fa-film blue txt-gap"></i>Review Drug Label Advertisements</span>
                    <i
                      class="float-right fas"
                      [ngClass]="{'fa-chevron-down': drugAds?.isOpen, 'fa-chevron-right': !drugAds?.isOpen}"></i>
                  </div>                     
                  <p>Use of AWS Comprehend Service to extract standard and custom
                    entities from extracted text</p>
                </accordion-group>
                <accordion-group #labelCompare>
                  <div accordion-heading style="width: 100%">
                    <span class="fw-bold"><i class="fas fa-fw fa-pills blue txt-gap"></i>Accelerate Drug Label Comparison</span>
                    <i
                      class="float-right fas"
                      [ngClass]="{'fa-chevron-down': labelCompare?.isOpen, 'fa-chevron-right': !labelCompare?.isOpen}"></i>
                  </div>                     
                  <p>Use of AWS Bedrock LLM for Chat Interactions</p>
                </accordion-group>
                <accordion-group #classifyReports>
                  <div accordion-heading style="width: 100%">
                    <span class="fw-bold"><i class="fas fa-fw fa-layer-group blue txt-gap"></i>Classify Bio Analytical Study Reports</span>
                    <i
                      class="float-right fas"
                      [ngClass]="{'fa-chevron-down': classifyReports?.isOpen, 'fa-chevron-right': !classifyReports?.isOpen}"></i>
                  </div>                     
                  <p>Use of AWS OpenSearch for neural semantic search with Bedrock</p>
                </accordion-group>    
                <accordion-group #knowledgeGraph>
                  <div accordion-heading style="width: 100%">
                    <span class="fw-bold"><i class="fas fa-fw fa-project-diagram blue txt-gap"></i>Create Domain Knowledge Graph</span>
                    <i
                      class="float-right fas"
                      [ngClass]="{'fa-chevron-down': knowledgeGraph?.isOpen, 'fa-chevron-right': !knowledgeGraph?.isOpen}"></i>
                  </div>                     
                  <p>Use of AWS OpenSearch for neural semantic search with Bedrock</p>
                </accordion-group>    
                <accordion-group #semanticSearch>
                  <div accordion-heading style="width: 100%">
                    <span class="fw-bold"><i class="fas fa-fw fa-search blue txt-gap"></i>Efficient Hybrid Semantic Search</span>
                    <i
                      class="float-right fas"
                      [ngClass]="{'fa-chevron-down': semanticSearch?.isOpen, 'fa-chevron-right': !semanticSearch?.isOpen}"></i>
                  </div>                     
                  <p>Use of AWS OpenSearch for neural semantic search with Bedrock</p>
                </accordion-group>    
                <accordion-group #smartDashboard>
                  <div accordion-heading style="width: 100%">
                    <span class="fw-bold"><i class="fas fa-fw fa-chart-line blue txt-gap"></i>Intelligent Dashboard</span>
                    <i
                      class="float-right fas"
                      [ngClass]="{'fa-chevron-down': smartDashboard?.isOpen, 'fa-chevron-right': !smartDashboard?.isOpen}"></i>
                  </div>                     
                  <p>Use of AWS OpenSearch for neural semantic search with Bedrock</p>
                </accordion-group>   
                <accordion-group #adverseEvent>
                  <div accordion-heading style="width: 100%">
                    <span class="fw-bold"><i class="fas fa-fw fa-clinic-medical blue txt-gap"></i>Adverse Event Analysis</span>
                    <i
                      class="float-right fas"
                      [ngClass]="{'fa-chevron-down': adverseEvent?.isOpen, 'fa-chevron-right': !adverseEvent?.isOpen}"></i>
                  </div>                     
                  <p>Use of AWS OpenSearch for neural semantic search with Bedrock</p>
                </accordion-group> 
                <accordion-group #inspectorAssist>
                  <div accordion-heading style="width: 100%">
                    <span class="fw-bold"><i class="fas fa-fw fa-binoculars blue txt-gap"></i>Rapid Inspection Assistant</span>
                    <i
                      class="float-right fas"
                      [ngClass]="{'fa-chevron-down': inspectorAssist?.isOpen, 'fa-chevron-right': !inspectorAssist?.isOpen}"></i>
                  </div>                     
                  <p>Use of AWS OpenSearch for neural semantic search with Bedrock</p>
                </accordion-group>                                                                                    
              </accordion>    
            </div>
          </div> -->
        </section>
      </main>
    </div>
    <!-- Login section Ends -->
  </div>
</div>
