import { Pipe, PipeTransform } from '@angular/core';

/*
 * Changes the case of the first letter of a given number of words in a string.
 */

@Pipe({ name: 'removeHyphen', pure: false })
export class RemoveHyphen implements PipeTransform {
  transform(input: string): string {
    if (input == null) {
      return input;
    }
    return input.length > 0 ? input.replace(/-/g, ' ') : '';
  }
}
