import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-alert-card',
  templateUrl: './alert-card.template.html',
  styleUrls: ['./alert-card.style.scss'],
})
export class AlertCardComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() descriptionList: Array<string>;
  @Input() isSpinnerNeeded: boolean;
  @Input() isNoMargin: boolean;

  @Input() type: string; // WARNING, ERROR

  constructor() {}

  ngOnInit() {}
}
