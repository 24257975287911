import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthService } from './auth.service';
import { environment } from '../../../../environments/environment';
import { PingAuthService } from './ping-auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private auth: AuthService,
    private oauthService: OAuthService,
    private pingAuthService: PingAuthService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (!environment.PINGENABLED || this.pingAuthService.isLoggedIn()) {
      return true;
    }
    this.router.navigate(['/signin']);
    return false;
  }
}
