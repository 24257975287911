<div class="modal fade" id="info-popup">
  <div class="modal-dialog" role="info-popup">
    <div class="modal-content">
      <div class="modal-header modal-title-bar">
        <div title="title" class="h4 modal-title text-xs-center fw-bold mt">
          {{title}}
        </div>
      </div>
      <!--Modal Body-->
      <div class="modal-body">
        <div [innerHTML]="msg"></div>
      </div>
      <!--Modal Footer-->
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-gray"
          (click)="onClose()"
          title="Close">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
