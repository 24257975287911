import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.template.html',
  styleUrls: ['./app-footer.style.scss'],
})
export class AppFooterComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
