import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { MaterialFileDetail } from '../model/material-filedetail.model';
import * as API_ENDPOINTS from '../../../shared/models/api-endpoints';
import { MaterialInfo } from '../model/material-info';
import { environment } from '../../../../environments/environment';
import { VapingLabelInfo } from '../model/vaping-label-info';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable()
export class MaterialLabelsService {
  private _materialFiles: BehaviorSubject<MaterialFileDetail[]> =
    new BehaviorSubject<MaterialFileDetail[]>([]);

  constructor(private http: HttpClient) {}

  get materialFiles(): MaterialFileDetail[] {
    return this._materialFiles.value;
  }

  set materialFiles(sf: MaterialFileDetail[]) {
    this._materialFiles.next(sf);
  }

  getDownloadPresignedFileUrl(__fileName: string) {
    const payload = { useremail: 'tester@email.com', key: __fileName };
    return this.http.post(API_ENDPOINTS.downloadMaterialFileUrl, payload).pipe(
      tap(_ => this.log()),
      catchError(this.handleError<any>())
    );
  }

  getAllMaterialFilesPaginated(filter: any) {
    let from = filter.page;
    let to = filter.rows;
    return this.http
      .get(
        API_ENDPOINTS.materialFilePaginatedItemsUrl +
          '?from=' +
          from +
          '&size=' +
          to
      )
      .pipe(
        tap(_ => this.log()),
        catchError(this.handleError<any>())
      );
  }

  getAllMaterialFilesByUUID(uuid: string) {
    return this.http
      .get(API_ENDPOINTS.materialFileItemsByUUIDUrl + '/' + uuid)
      .pipe(
        tap(_ => this.log()),
        catchError(this.handleError<any>())
      );
  }

  getCardGridPaginatedFileItems(from: Number, size: Number) {
    return this.http
      .get(
        API_ENDPOINTS.fileGridCardLayoutItemsUrl +
          '?from=' +
          from +
          '&size=' +
          size
      )
      .pipe(
        tap(_ => this.log()),
        catchError(this.handleError<any>())
      );
  }

  deleteSingleMaterialItem(uuid: string) {
    return this.http.delete(API_ENDPOINTS.deleteSingleMaterialFileItemUrl + '/' + uuid).pipe(
      tap(_ => this.log()),
      catchError(this.handleError<any>())
    );
  }

  approveSingleMaterialItem(uuid: string, materialInfo: MaterialInfo) {
    return this.http.put(API_ENDPOINTS.approveSingleMaterialFileItemUrl + '/' + uuid, materialInfo).pipe(
      tap(_ => this.log()),
      catchError(this.handleError<any>())
    );
  }

  assignReviewer(uuid: string, materialInfo: MaterialInfo) {
    return this.http.put(API_ENDPOINTS.assignReviewerUrl + '/' + uuid, materialInfo).pipe(
      tap(_ => this.log()),
      catchError(this.handleError<any>())
    );
  }

  rejectSingleMaterialItem(uuid: string, materialInfo: MaterialInfo) {
    return this.http.put(API_ENDPOINTS.rejectSingleMaterialFileItemUrl + '/' + uuid, materialInfo).pipe(
      tap(_ => this.log()),
      catchError(this.handleError<any>())
    );
  }

  deleteAllMaterialItems() {
    return this.http.delete(API_ENDPOINTS.deleteAllMaterialFileItemsUrl).pipe(
      tap(_ => this.log()),
      catchError(this.handleError<any>())
    );
  }

  populateDemoData() {
    return this.http.get(API_ENDPOINTS.loadDemoDataUrl).pipe(
      tap(_ => this.log()),
      catchError(this.handleError<any>())
    );
  }

  createMaterialInfo(materialInfo: MaterialInfo) {
    return this.http
      .post(API_ENDPOINTS.createMaterialFileUrl, materialInfo)
      .pipe(
        tap(_ => this.log()),
        catchError(this.handleError<any>())
      );
  }

  createVapingLabelInfo(vapingLabelInfo: VapingLabelInfo) {
    return this.http
      .post(API_ENDPOINTS.createVapingLabelUrl, vapingLabelInfo)
      .pipe(
        tap(_ => this.log()),
        catchError(this.handleError<any>())
      );
  }

  processWarningLabel(fileName: string) {
    let payload = 's3://ctpaiml-image-label-data/image_data/' + fileName;
    return this.http
      .post(API_ENDPOINTS.PROCESSLABEL, { s3_image_URI: payload })
      .pipe(
        tap(_ => this.log()),
        catchError(this.handleError<any>())
      );
  }

  processVapingLabel(fileName: string) {
    let payload = 's3://ctpaiml-image-label-data/vaping_labels/' + fileName;
    return this.http
      .post(API_ENDPOINTS.PROCESSLABEL, { s3_image_URI: payload })
      .pipe(
        tap(_ => this.log()),
        catchError(this.handleError<any>())
      );
  }

  startWarningLabelDetection(){
    return this.http
      .get(API_ENDPOINTS.startRekognitionModelUrl)
      .pipe(
        tap(_ => this.log()),
        catchError(this.handleError<any>())
      );
  }

  getWarningLabelModelStatus(){
    return this.http
      .get(API_ENDPOINTS.getRekognitionModelStatusUrl)
      .pipe(
        tap(_ => this.log()),
        catchError(this.handleError<any>())
      );
  }

  stopWarningLabelDetection(){
    return this.http
      .get(API_ENDPOINTS.stopRekognitionModelUrl)
      .pipe(
        tap(_ => this.log()),
        catchError(this.handleError<any>())
      );
  }


  /**
   *
   * @param materialFileReq
   */
  getPresignedUrlForFileUpload(presignedFileRequest: object) {
    return this.http
      .post(
        environment.STORAGEAPIURL + '/upload/ctplabels/presignedurl',
        presignedFileRequest,
        httpOptions
      )
      .pipe(
        tap(_ => this.log()),
        catchError(this.handleError<any>())
      );
  }

  /**
   *
   * @param materialFileReq
   */
  getPresignedUrlForFileDownload(presignedFileRequest: object) {
    return this.http
      .post(
        environment.STORAGEAPIURL + '/download/presignedurl',
        presignedFileRequest,
        httpOptions
      )
      .pipe(
        tap(_ => this.log()),
        catchError(this.handleError<any>())
      );
  }

  /**
   * Uploadfiles awss3
   * @param fileuploadurl
   * @param contenttype
   * @param file
   * @returns awss3
   */
  uploadfileAWSS3(
    fileuploadurl: string,
    contenttype: string,
    file: any
  ): Observable<any> {
    // this will be used to upload all csv files to AWS S3
    const headers = new HttpHeaders({
      'Content-Type': contenttype,
    });
    const req = new HttpRequest('PUT', fileuploadurl, file, {
      headers: headers,
      reportProgress: true, //This is required for track upload process
    });
    return this.http.request(req);
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>() {
    return (error: any): Observable<T> => {
      this.log();

      // Let the app keep running by returning an empty result.
      return throwError(error);
    };
  }

  /** Log a Attribute Service message with the MessageService */
  private log() {}
}
