// Service
import { Injectable } from '@angular/core';
import {
  Router,
  RouterEvent,
  NavigationEnd,
  NavigationStart,
} from '@angular/router';

/** A router wrapper, adding extra functions. */
@Injectable({
  providedIn: 'root',
})
export class RouteStateService {
  private previousUrl: string = undefined;
  private currentUrl: string = undefined;
  private isNavigationPop: boolean;
  private isInAppNaviation: boolean;

  constructor(private router: Router) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        const pageContent: any = document.querySelector('#content');
        if (pageContent) {
          pageContent.focus();
        }
        window.scrollTo(0, 0);
      } else if (
        event instanceof NavigationStart &&
        event.navigationTrigger == 'popstate'
      ) {
        // If the user is trying to navigate back set the flag
        // see can-deactivate-gaurd.service.ts
        this.setIsNavigationPop(true);
        window.scrollTo(0, 0);
      }
    });
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

  public getIsNavigationPop(): boolean {
    return this.isNavigationPop;
  }

  public setIsNavigationPop(clicked: boolean) {
    this.isNavigationPop = clicked;
  }

  public getIsInAppNaviation(): boolean {
    return this.isInAppNaviation;
  }

  public setIsInAppNaviation(clicked: boolean) {
    this.isInAppNaviation = clicked;
  }

  public determineIfInAppNavigationPopState() {
    let state = this.getIsNavigationPop() && !this.getIsInAppNaviation();
    this.setIsInAppNaviation(false);
    this.setIsNavigationPop(false);
    return state;
  }
}
