<aw-wizard-navigation-bar
  [direction]="navBarDirection"
  [ngStyle]="styles"
  *ngIf="navBarLocation == 'top' || navBarLocation == 'left'"
  [ngClass]="{
    vertical: navBarLocation == 'left',
    horizontal: navBarLocation == 'top',
    small: navBarLayout == 'small',
    'large-filled': navBarLayout == 'large-filled',
    'large-filled-symbols': navBarLayout == 'large-filled-symbols',
    'large-empty': navBarLayout == 'large-empty',
    'large-empty-symbols': navBarLayout == 'large-empty-symbols'
  }">
</aw-wizard-navigation-bar>

<div
  [ngClass]="{
    'wizard-steps': true,
    vertical: navBarLocation == 'left' || navBarLocation == 'right',
    horizontal: navBarLocation == 'top' || navBarLocation == 'bottom'
  }">
  <ng-content></ng-content>
</div>

<aw-wizard-navigation-bar
  [direction]="navBarDirection"
  [ngStyle]="styles"
  *ngIf="navBarLocation == 'bottom' || navBarLocation == 'right'"
  [ngClass]="{
    vertical: navBarLocation == 'right',
    horizontal: navBarLocation == 'bottom',
    small: navBarLayout == 'small',
    'large-filled': navBarLayout == 'large-filled',
    'large-filled-symbols': navBarLayout == 'large-filled-symbols',
    'large-empty': navBarLayout == 'large-empty',
    'large-empty-symbols': navBarLayout == 'large-empty-symbols'
  }">
</aw-wizard-navigation-bar>
