<div class="container">
  <main id="content" class="widget-login-container" role="main">
    <div class="row">
      <div
        class="col-xl-4 col-md-6 col-xs-10 offset-xl-4 offset-md-3 offset-xs-1">
        <h1 class="widget-login-logo animated fadeInUp">
          <i class="fa fa-circle text-gray"></i>
          DMAT
          <i class="fa fa-circle text-warning"></i>
        </h1>
        <section class="widget widget-login animated fadeInUp">
          <header>
            <label title="login title" class="h4 no-margin fw-bold"
              >You are logged out of DMAT. To log back in,
              <a [routerLink]="['../..']">click here</a></label
            >
          </header>
          <div class="widget-body">
            <form class="login-form mt-lg">
              <!-- <div *ngIf="showErrorFlag" class="row">
                  <div class="form-group row">
                    <div class="offset-xs-1 col-lg-10 col-xs-10">
                      <alert *ngFor="let alert of alerts; let i = index" [type]="alert.type + ' alert-sm'">
                          <div [innerHTML]="alert.msg"></div><div>Email us for assistance at: </div>
                      </alert>
                    </div>
                  </div>
              </div> -->
            </form>
          </div>
        </section>
      </div>
    </div>
  </main>
  <footer class="page-footer">
    <label title="footer">{{currentYear}} &copy; Deloitte.</label>
  </footer>
</div>
