<div class="row">
  <div class="col-md-12 text-right pagination-wrapper">
    <div class="total-wrapper">
      <span class="fw-bold"
        >{{ totalFileCount == 0 ? 0 : ((paginationOptions.currentPage - 1) *
        paginationOptions.selPageSize) + 1 | number: '1.0-0' }}</span
      >
      to
      <span class="fw-bold"
        >{{ (paginationOptions.currentPage * paginationOptions.selPageSize) >
        totalFileCount ? totalFileCount : (paginationOptions.currentPage *
        paginationOptions.selPageSize | number: '1.0-0' )}}</span
      >
      of
      <span class="fw-bold"
        >{{ totalFileCount > maxRecords ? maxRecords : totalFileCount | number:
        '1.0-0' }}</span
      >
      <span *ngIf="totalFileCount > maxRecords" class="fw-bold">+</span>
      {{fileType}}
    </div>

    <div class="jump-to-wrapper">
      Jump to Page
      <input
        class="jump-to-textbox"
        [(ngModel)]="paginationOptions.jumpToPageNumber"
        numericOnly
        aria-label="Jump to Page"
        (keyup.enter)="goToPage($event.target.value)" />
      <button
        type="button"
        title="Go"
        class="btn btn-outline-primary"
        (click)="goToPage(paginationOptions.jumpToPageNumber)">
        Go
      </button>
    </div>

    <div class="page-size-wrapper">
      Show
      <select
        [(ngModel)]="paginationOptions.selPageSize"
        [ngModelOptions]="{standalone: true}"
        aria-label="Show"
        (change)="onPageSizeChanged()">
        <option [ngValue]="ps" *ngFor="let ps of [10, 20, 30, 40, 50, 60, 70]">
          {{ps}}
        </option>
      </select>
    </div>

    <div class="page-link-wrapper">
      <button
        aria-label="initial"
        type="button"
        title="Previous"
        class="btn btn-link link-item"
        [disabled]="paginationOptions.currentPage==1"
        (click)="goToPage(paginationOptions.currentPage-1)">
        <i class="fa fa-angle-left"></i>
      </button>
      <button
        aria-label="first"
        type="button"
        class="btn btn-link link-item"
        (click)="goToPage(1)"
        *ngIf="paginationOptions.currentPage > 2">
        1
      </button>
      <span *ngIf="paginationOptions.currentPage-1 > 2">... </span>
      <button
        aria-label="prev"
        type="button"
        class="btn btn-link link-item"
        (click)="goToPage(paginationOptions.currentPage-1)"
        *ngIf="paginationOptions.currentPage > 1">
        {{paginationOptions.currentPage-1}}
      </button>
      <span class="current-page link-item"
        >{{paginationOptions.currentPage}}
      </span>
      <button
        aria-label="current"
        type="button"
        class="btn btn-link link-item"
        (click)="goToPage(paginationOptions.currentPage+1)"
        *ngIf="paginationOptions.maxTotalPage > paginationOptions.currentPage">
        {{paginationOptions.currentPage+1}}
      </button>

      <span
        *ngIf="paginationOptions.currentPage+1 >= 2 && paginationOptions.maxTotalPage > paginationOptions.currentPage+1"
        >...</span
      >

      <button
        aria-label="next"
        type="button"
        class="btn btn-link link-item"
        (click)="goToPage(paginationOptions.maxTotalPage)"
        *ngIf="paginationOptions.maxTotalPage > paginationOptions.currentPage+1">
        {{paginationOptions.maxTotalPage}}
      </button>
      <button
        aria-label="last"
        type="button"
        title="Next"
        class="btn btn-link link-item"
        [disabled]="paginationOptions.currentPage== paginationOptions.maxTotalPage"
        (click)="goToPage(paginationOptions.currentPage+1)">
        <i class="fa fa-angle-right"></i>
      </button>
    </div>
  </div>
</div>
