import { Component, OnInit, OnDestroy } from '@angular/core';
import { PingAuthService } from '../../services/ping-auth.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-ping-callback',
  templateUrl: './ping-callback.template.html',
  styleUrls: ['./ping-callback.style.scss'],
})
export class PingCallbackComponent implements OnInit, OnDestroy {
  public loginStatus = 'Logging In...';
  public busyLoggingIn: Promise<any>;

  constructor(
    private pingAuthService: PingAuthService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private auth: AuthService
  ) {}

  showSpinner(name: string) {
    this.spinner.show(name);
  }

  hideSpinner(name: string) {
    this.spinner.hide(name);
  }

  async ngOnInit() {
    try {
      this.showSpinner('sp4');
      this.auth.addSessionExpiryTimers();
      this.busyLoggingIn = this.pingAuthService.completeAuthentication();
    } catch (error) {
      // PINGAUTHTODO: What alert/page to display if user is not logged in
      // tslint:disable-next-line: no-console
      console.log(error);
      this.loginStatus = 'User not authorized. Redirecting to Login...';
      setTimeout(() => {
        this.router.navigate(['welcome']);
      }, 2000);
    }
  }

  ngOnDestroy() {
    this.hideSpinner('sp4');
  }
}
