import { Component, ElementRef, OnDestroy } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams, IAfterGuiAttachedParams, ICellRendererParams } from 'ag-grid-community';

@Component({
  templateUrl: './ag-grid-header-comp.template.html',
  styleUrls: ['./ag-grid-header-comp.style.scss'],
})
export class SortableHeaderComponent implements OnDestroy, IHeaderAngularComp {
  public params: any;
  // public sorted: string;
  public sortedState: string;
  private elementRef: ElementRef;
  public isSortable: boolean;

  constructor(elementRef: ElementRef) {
    this.elementRef = elementRef;
  }

  refresh(params: IHeaderParams): boolean {
    return true;
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {}

  agInit(params: any): void {
    this.params = params;
    this.isSortable =
      this.params.enableSorting ||
      (this.params.column.userProvidedColDef &&
        this.params.column.userProvidedColDef.customSortable);
    this.params.column.addEventListener(
      'sortChanged',
      this.getCurrentSortState.bind(this)
    );
    this.getCurrentSortState();
  }

  ngOnDestroy() {}

  onMenuClick() {
    this.params.showColumnMenu(this.querySelector('.customHeaderMenuButton'));
  }

  onSortRequested(event) {
    if (
      this.params.enableSorting ||
      (this.params.column.userProvidedColDef &&
        this.params.column.userProvidedColDef.customSortable)
    ) {
      let currentSortOrder = this.getNextSortState();
      this.params.setSort(currentSortOrder, event.shiftKey);
      this.params.context.componentParent['getPaginatedResult']();
    }
  }

  /**
   * Get the next sorting order
   * */
  getNextSortState() {
    if (this.params.column.isSortAscending()) {
      this.sortedState = 'desc';
    } else if (this.params.column.isSortDescending()) {
      this.sortedState = '';
    } else {
      this.sortedState = 'asc';
    }
    return this.sortedState;
  }

  /**
   * Get the current sorting order
   * */
  getCurrentSortState() {
    if (this.params.column.isSortAscending()) {
      this.sortedState = 'asc';
    } else if (this.params.column.isSortDescending()) {
      this.sortedState = 'desc';
    } else {
      this.sortedState = '';
    }
  }

  private querySelector(selector: string) {
    return <HTMLElement>(
      this.elementRef.nativeElement.querySelector(
        '.customHeaderMenuButton',
        selector
      )
    );
  }
}
