import { HttpHeaders } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { OnComponentErrorHandler } from '../../../core/coreerror.handler';
import { BaseComponent } from '../../../shared/components/base/base.component';
import { ConfirmModalPopupComponent } from '../../../shared/components/confirm-modal/confirm-modal.component';
import { Credentials } from '../model/credentials.model';
import { LoginState } from '../model/login-state.model';
import { RegisteredAccountUser } from '../model/reg-account-user.model';
import { AuthService } from '../services/auth.service';
import { ProfileService } from '../services/profile.service';
import * as _ from 'lodash';
import { PingAuthService } from '../services/ping-auth.service';

declare var jQuery: any;

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'login',
  styleUrls: ['./login.style.scss'],
  templateUrl: './login.template.html',
  encapsulation: ViewEncapsulation.None,
  providers: [BsModalService],
})
export class LoginComponent
  extends BaseComponent
  implements OnInit, AfterViewInit, OnComponentErrorHandler, OnDestroy
{
  public model = new Credentials('', '');
  public currentYear: string;
  public loginTitle: string;
  public isLoginButtonClicked: boolean;

  public busySignIn: Subscription;
  public busyUserExists: Subscription;

  public showPasswordFlag: boolean;
  public bsConfirmModalRef: BsModalRef;
  public buildTimeStamp: string;

  public loginRole: string;

  public ackMessageHTML: string;
  public ackMessage: string;
  public loginError: string;
  public errorMessages: string[] = [];
  public errorMessageList: string[];
  public isErrorCard: boolean;

  public userAcknowledged: boolean = false;

  constructor(
    private auth: AuthService,
    private modalService: BsModalService,
    private permissionsService: NgxPermissionsService,
    private router: Router,
    private oauthService: OAuthService,
    private changeDetector: ChangeDetectorRef,
    private pingAuthService: PingAuthService
  ) {
    super();
    this.currentYear = new Date().getFullYear().toString();
    this.showPasswordFlag = false;
    this.isErrorCard = false;
    this.errorMessageList = [
      'Invalid credentials or did not acknowledge message.',
    ];

    this.loginRole = 'CBU';

    this.buildTimeStamp = environment.timeStamp;

    let line1 = 'WARNING NOTICE:\n';
    let lineh1 = '<h2>WARNING NOTICE:</h2>';

    let line2 =
      'For public facing web pages to which the public has privileged access.';
    let lineh2 =
      '<p>For public facing web pages to which the public has privileged access';

    let line3 =
      'You are accessing a web site which may contain information that must be protected under the U.S. Privacy Act or other sensitive information and is intended for authorized use only.';

    let line4 =
      'Unauthorized attempts to upload information, change information, or use of this web site may result in disciplinary action, civil, and/or criminal penalties.';
    let lineh4 =
      'Unauthorized attempts to upload information, change information, or use of this web site may result in disciplinary action, civil, and/or criminal penalties.</p>';

    let line5 =
      'Unauthorized users of this web site should have no expectation of privacy regarding any communications or data processed by this web site.';
    let lineh5 =
      '<p>Unauthorized users of this web site should have no expectation of privacy regarding any communications or data processed by this web site.</p>';

    let line6 =
      'Anyone accessing this web site expressly consents to monitoring of their actions and all communication or data transitioning or stored on or related to this web site and is advised that if such monitoring reveals possible evidence of criminal activity, ORG may provide that evidence to law enforcement officials.';
    let lineh6 =
      '<p>Anyone accessing this web site expressly consents to monitoring of their actions and all communication or data transitioning or stored on or related to this web site and is advised that if such monitoring reveals possible evidence of criminal activity, ORG may provide that evidence to law enforcement officials.</p>';

    this.ackMessage = line1 + line2 + line3 + line4 + line5 + line6;
    this.ackMessageHTML = lineh1 + lineh2 + line3 + lineh4 + lineh5 + lineh6;
  }

  ngOnInit(): void {
    this.showErrorFlag = false;
    this.isLoginButtonClicked = false;
    this.auth.setTopNavBarShowFlag(false);
    this.permissionsService.flushPermissions();
    this.loginTitle = environment.loginTitle;
    this.model.username = 'innovrally@gmail.com';
    this.model.password = 'H@pp!n3ss';
  }

  ngAfterViewInit() {
    jQuery('#uname').focus();

    jQuery('#ack-text').slimScroll({
      height: '150px',
      width: '100%',
    });
  }

  ngOnDestroy(): void {
    if (this.busySignIn) {
      this.busySignIn.unsubscribe();
    }
    if (this.busyUserExists) {
      this.busyUserExists.unsubscribe();
    }
  }

  get mockAuth(): boolean {
    return !environment.PINGENABLED;
  }

  onResetError(): void {
    this.showErrorFlag = false;
    this.alerts = [];
    //this.errorMessages = [];
  }

  onShowError(err: string): void {
    this.showErrorFlag = true;
    this.alerts.push({ type: 'warning', msg: err });
  }

  onShowBodyError(err: string): void {
    this.showErrorFlag = true;
    this.alerts.push({ type: 'warning', msg: err });
  }

  getHeaders(): HttpHeaders {
    return new HttpHeaders().set(
      'Authorization',
      this.oauthService.authorizationHeader()
    );
  }

  /**
   * Step 1: User logins
   *
   * @returns
   * @memberof LoginComponent
   */
  public async login() {
    let that = this;
    this.isErrorCard = false;
    // MAR-208 - Prevent user from clicking the login button multiple times
    if (this.isLoginButtonClicked) {
      return true;
    }

    this.onResetError();

    // if (environment.skipOkta === true) {
    jQuery('#frm-login').parsley().validate();
    if (jQuery('#frm-login').parsley().isValid()) {
      this.userAcknowledged = true;
    } else {
      this.isErrorCard = true;
      //this.addValidationErrorMessage('ack-chk');
      return;
    }

    if (this.userAcknowledged) {
      if (this.mockAuth) {
        sessionStorage.setItem('profilename', that.model.username);
        sessionStorage.setItem('profileemail', that.model.username);
        sessionStorage.setItem('profilerole', that.loginRole);        
        let status: LoginState = that.auth.getLoginState();
        status.successfulSignIn();
        that.changeDetector.detectChanges();
        // that.loadUserPermissions();
        that.auth.addSessionExpiryTimers();
        this.auth.setTopNavBarShowFlag(true);
      } else {
        window.onbeforeunload = function (e) {
          // the absence of a returnValue property on the event will guarantee the browser unload happens
          delete e['returnValue'];
        };
        this.isLoginButtonClicked = true;
        sessionStorage.setItem('profilerole', that.loginRole);             
        this.pingAuthService.startAuthentication();
      }
      return;
    }
    // }

    // jQuery('#frm-login').parsley().validate();

    // if (jQuery('#frm-login').parsley().isValid()) {
    //   let that = this;
    //   this.isLoginButtonClicked = true;

    //   that.signIn();
    // } else {
    //   this.isErrorCard = true;
    // }
  }

  /**
   *
   *
   * @memberof LoginComponent
   */
  public logout() {
    this.router.navigate(['login']);
  }

  /**
   * Signs in
   */
  public async signIn() {
    this.resetLoginError();
  }

  public resetLoginError() {
    if (this.loginError) {
      if (this.loginError === 'LOCKED_OUT') {
        this.isErrorCard = true;
      } else if (this.loginError === 'Authentication failed') {
        this.isErrorCard = true;
      } else {
        this.isErrorCard = true;
      }
      this.isLoginButtonClicked = false;
    }
  }

  /**
   * Loads user permissions from the USER table
   *
   * @memberof LoginComponent
   */
  public loadUserPermissions() {
    let that = this;
    let useremail = sessionStorage.getItem('profileemail');
    let user: RegisteredAccountUser = new RegisteredAccountUser();
    user.email = useremail;

    if (that.loginRole) {
      let internalUserPermissions: string[] = [];
      internalUserPermissions.push(that.loginRole);
      that.permissionsService.flushPermissions();
      that.permissionsService.loadPermissions(internalUserPermissions);
    }

    that.router.navigate(['labels/review']);

  }

  /**
   * Shows ack popup
   */
  public showAckPopup() {
    const initialState = {
      message: this.ackMessageHTML,
      title: 'Please acknowledge the following',
      footer: false,
    };
    this.bsConfirmModalRef = this.modalService.show(
      ConfirmModalPopupComponent,
      {
        initialState,
        class: 'modal-md modal-center',
        animated: true,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true,
      }
    );

    this.bsConfirmModalRef.content.onClose.subscribe(() => {});
  }
}
