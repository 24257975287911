<div class="modal-header">
  <h4 class="modal-title pull-left">{{title}}</h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p *ngIf="customMessage">{{customMessage}}</p>
  <progressbar [value]="completion*10">{{completion*10}}%</progressbar>
</div>
<div class="modal-footer text-center">
  <button type="button" class="btn btn-link" (click)="bsModalRef.hide()">
    Close
  </button>
</div>
