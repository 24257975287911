import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-progress-bar-popup',
  templateUrl: './progress-bar-popup.template.html',
})
export class ProgressBarPopupComponent implements OnInit {
  @Input() title: string;
  @Input() customMessage: string;
  @Input() time: number;
  public completion: number = 0;
  public onHide: boolean;

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit() {
    setInterval(() => {
      if (this.time > 0) {
        this.time--;
        this.completion++;
      } else {
        this.bsModalRef.hide();
      }
    }, 1000);
  }
}
