<ul class="steps-indicator steps-{{ numberOfWizardSteps }}">
  <li
    [attr.id]="step.stepId"
    *ngFor="let step of wizardSteps"
    [ngClass]="{
      current: isCurrent(step),
      editing: isEditing(step),
      done: isDone(step),
      optional: isOptional(step),
      completed: isCompleted(step),
      navigable: isNavigable(step),
      error: isError(step)
    }">
    <a [awGoToStep]="step">
      <div class="label">
        <ng-container
          *ngIf="step.stepTitleTemplate"
          [ngTemplateOutlet]="
            step.stepTitleTemplate.templateRef
          "></ng-container>
        <ng-container *ngIf="!step.stepTitleTemplate">{{
          step.stepTitle
        }}</ng-container>
      </div>
      <div
        class="step-indicator"
        [ngStyle]="{
          'font-family': step.stepSymbolTemplate
            ? ''
            : step.navigationSymbol.fontFamily
        }">
        <ng-container
          *ngIf="step.stepSymbolTemplate"
          [ngTemplateOutlet]="
            step.stepSymbolTemplate.templateRef
          "></ng-container>
        <ng-container *ngIf="!step.stepSymbolTemplate">{{
          step.navigationSymbol.symbol
        }}</ng-container>
      </div>
    </a>
  </li>
</ul>
