import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { BaseComponent } from '../base/base.component';
import { MaterialLabelsService } from '../../../modules/material/service/material-labels.service';
import { MaterialInfo } from '../../../modules/material/model/material-info';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { isNil } from 'ramda';

@Component({
  selector: 'view-file-popup',
  templateUrl: './view-file-popup.component.html',
  styleUrls: ['./view-file-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ViewFilePopupComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  fileName: string;
  fileNames: string[] = [];
  subHeader: string;

  isIndustryUser: boolean = false;
  showFindings: boolean = false;

  loadUrl: string;
  currentIndex: number;
  currentMaterialInfo: MaterialInfo;
  isViewFormatValid: boolean;
  isInitView: boolean = false;
  passNotes: string[] = [];
  failNotes: string[] = [];

  uuid: string;

  private busyGetPredefinedUrl: Subscription;
  busyLoadFileDetails: Subscription;

  imgconfig: any = {
    btnIcons: {
      // The icon classes that will apply to the buttons. By default, font-awesome is used.
      zoomIn: 'fa fa-plus',
      zoomOut: 'fa fa-minus',
      rotateClockwise: 'fa fa-redo',
      rotateCounterClockwise: 'fa fa-undo',
      next: 'fa fa-arrow-right',
      prev: 'fa fa-arrow-left',
      fullscreen: 'fa fa-arrows-alt',
    },
  };

  constructor(
    private bsModalRef: BsModalRef,
    private materialLabelsService: MaterialLabelsService
  ) {
    super();
    this.showFindings = false;
  }

  ngOnInit() {
    this.isFileValidFormat();

    /**
     * Its a work around for Material Icon load issue. Currently its not loading for the first time
     * So as a work around, we are reinitializing the compoent for the first time
     */
    let materialViewedStatus = sessionStorage.getItem('materialviewed');
    if (!materialViewedStatus) {
      this.isInitView = true;
      sessionStorage.setItem('materialviewed', 'yes');
      setTimeout(() => {
        this.isInitView = false;
      }, 1000);
    }

    const foundIndex = this.fileNames.indexOf(this.fileName);
    this.currentIndex = foundIndex > -1 ? foundIndex : 0;
    this.loadMaterialFileDetails();
  }

  ngOnDestroy() {
    if (this.busyGetPredefinedUrl) {
      this.busyGetPredefinedUrl.unsubscribe();
    }

    if (this.busyLoadFileDetails) {
      this.busyLoadFileDetails.unsubscribe();
    }
  }

  didPass() {
    if(!this.isIndustryUser){
      return (this.currentMaterialInfo && (this.currentMaterialInfo.review_status === 'Auto Approved' || this.currentMaterialInfo.review_status === 'Approved'));      
    }
    return false;
  }

  needsReview() {
    if(!this.isIndustryUser){    
      return (this.currentMaterialInfo && (this.currentMaterialInfo.review_status === 'Pending Approval' || this.currentMaterialInfo.review_status === 'Pending Review'));
    }
    return false;    
  }

  rejected() {
    if(!this.isIndustryUser){       
      return (this.currentMaterialInfo && this.currentMaterialInfo.review_status === 'Rejected');
    }
    return false;       
  }

  loadMaterialFileDetails() {
    let that = this;
    this.passNotes = [];
    this.failNotes = [];
    this.busyLoadFileDetails = this.materialLabelsService
      .getAllMaterialFilesByUUID(this.uuid)
      .subscribe(
        (result) => {
          if(!isNil(result) && !isNil(result.data) && result.data.length > 0){
            that.currentMaterialInfo = new MaterialInfo();            
            that.currentMaterialInfo.uuid = result.data[0]._id;
            that.currentMaterialInfo.name = result.data[0].name;
            that.currentMaterialInfo.bbox_left = result.data[0].bbox_left;
            that.currentMaterialInfo.bbox_top = result.data[0].bbox_top;
            that.currentMaterialInfo.bbox_width = result.data[0].bbox_width;
            that.currentMaterialInfo.bbox_height = result.data[0].bbox_height;
            that.currentMaterialInfo.detect_area_in_sq_pixels =
              result.data[0].detect_area_in_sq_pixels;
            that.currentMaterialInfo.box_area_in_sq_pixels =
              result.data[0].box_area_in_sq_pixels;
            that.currentMaterialInfo.prediction_area_percentage =
              result.data[0].prediction_area_percentage;
            that.currentMaterialInfo.rekognition_confidence =
              result.data[0].rekognition_confidence;
            that.currentMaterialInfo.warning_text = result.data[0].warning_text;
            that.currentMaterialInfo.review_status = result.data[0].review_status;
            that.currentMaterialInfo.status = result.data[0].status;
          }
          that.validate(that.currentMaterialInfo);
        },
        failed => {}
      );
  }

  validate(materialInfo: MaterialInfo) {
    this.failNotes = [];
    this.passNotes = [];
    this.showFindings = this.isIndustryUser ? false : true;
    if(this.isIndustryUser && (this.didPass() || this.rejected()) ){
      this.showFindings = true;
    }

    if(!isNil(materialInfo)){
      if(!isNil(materialInfo.prediction_area_percentage)){
        if (materialInfo.prediction_area_percentage < 49.5) {
          this.failNotes.push(`Label Coverage of ${materialInfo.prediction_area_percentage} is < 49.5%`);
        } else {
          this.passNotes.push(`Label Coverage of ${materialInfo.prediction_area_percentage} >= 49.5%`);
        }
      }
    }

  }

  closeModal() {
    this.bsModalRef.hide();
  }

  private isFileValidFormat() {
    const viewFormats = ['png', 'jpg', 'jpeg', 'gif', 'pdf'];
    const fileExtension =
      this.fileName.indexOf('.') > -1
        ? this.fileName.split('.').pop().toLowerCase()
        : '';
    this.isViewFormatValid = viewFormats.indexOf(fileExtension) > -1;
  }

  downloadFile() {
    let that = this;
    let xmlhttp = new XMLHttpRequest();
    xmlhttp.open('GET', this.loadUrl);
    xmlhttp.responseType = 'blob';
    xmlhttp.onload = function () {
      // FileviewerUtils.download(that.fileName, this.response);
    };
    xmlhttp.send();
  }
}
