import { Directive, ElementRef, HostListener, Input } from '@angular/core';

/**
 * Directive which allows only alpha numeric values from 0 to 9, a to z and A to Z.
 * Handles user input, copy paste and drop events.
 */
@Directive({
  selector: '[alphaNumericOnly]',
})
export class AlphaNumericOnlyDirective {
  constructor(private _el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this._el.nativeElement.value;

    this._el.nativeElement.value = initalValue.replace(/[^a-zA-Z0-9]*/g, '');
    if (initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
