import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
} from '@angular/core';

@Directive(
  // tslint:disable-next-line: directive-selector
  { selector: '[aClick]' }
)
export class CustomAnchorClickDirective {
  @Output('aClick') callBack = new EventEmitter<boolean>();

  constructor(private elementRef: ElementRef) {}

  @HostListener('keypress', ['$event'])
  keyenter(event: KeyboardEvent) {
    if (event.which === 32 || event.which === 13) {
      this.callBack.emit(true);
    }
  }

  @HostListener('click', ['$event'])
  mouseclick(event: MouseEvent) {
    this.callBack.emit(true);
  }
}
