import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  Router,
} from '@angular/router';
import { AuthConfig } from './auth.config';
import { AuthService } from './modules/login/services/auth.service';
import { environment } from '../environments/environment';

declare var window: any;
declare var jQuery: any;

/**
 * AppComponent - Main Application Component
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.template.html',
  styleUrls: ['./app.style.scss'],
  providers: [],
})
export class AppComponent
  extends AuthConfig
  implements OnInit, OnDestroy, AfterViewChecked
{
  public sessionExpireMsg: string =
    'Your session is about to expire.  Click close to continue.';
  public title: string = 'Session Timeout';
  firstParam: string;
  isAuthenticated: boolean;

  constructor(
    public auth: AuthService,
    private titleService: Title,
    private router: Router,
    private cd: ChangeDetectorRef
  ) {
    super();
    //OKTA
    const config = new AuthConfig();
    config.redirectUri = window.location.origin;
    config.scope = 'openid profile email';
    config.oidc = true;
  }

  ngAfterViewChecked() {
    this.cd.detectChanges();
  }

  /**
   * Adds date validators
   */
  addDateValidators() {
    //has no future date
    window.Parsley.addValidator('maxdate', {
      validateString: function (value, requirement) {
        let timestamp = Date.parse(value);
        let maxTs = Date.parse(requirement);
        if (isNaN(timestamp)) {
          return true;
        }
        return timestamp < maxTs;
      },
      messages: {
        en: 'Error: Future date is not allowed',
      },
    });

    // has no past date
    window.Parsley.addValidator('mindate', {
      validateString: function (value, requirement) {
        let timestamp = Date.parse(value);
        let minTs = Date.parse(requirement);
        if (isNaN(timestamp)) {
          return true;
        }
        return timestamp > minTs;
      },
      messages: {
        en: 'Error: Future date required.',
      },
    });

    window.Parsley.addValidator('validdate', {
      validateString: function (value) {
        let timestamp = Date.parse(value);
        if (!isNaN(timestamp)) {
          return true;
        }
        return false;
      },
      messages: {
        en: 'Error: Invalid Date',
      },
    });

    window.Parsley.addValidator('dateformat', {
      validateString: function (value) {
        let timestamp = Date.parse(value);
        if (!isNaN(timestamp)) {
          return true;
        }
        return false;
      },
      messages: {
        en: 'Error: Must enter valid date in MM/DD/YYYY format',
      },
    });

    // gt, gte, lt, lte, notequalto extra validators
    let parseRequirement = function (requirement) {
      if (isNaN(+requirement)) {
        return parseFloat(jQuery(requirement).val());
      } else {
        return +requirement;
      }
    };

    // Greater than validator
    window.Parsley.addValidator('gt', {
      validateString: function (value, requirement) {
        return parseFloat(value) > parseRequirement(requirement);
      },
      priority: 32,
    });

    // Greater than or equal to validator
    window.Parsley.addValidator('gte', {
      validateString: function (value, requirement) {
        return parseFloat(value) >= parseRequirement(requirement);
      },
      priority: 32,
    });

    // Less than validator
    window.Parsley.addValidator('lt', {
      validateString: function (value, requirement) {
        return parseFloat(value) < parseRequirement(requirement);
      },
      priority: 32,
    });

    // Less than or equal to validator
    window.Parsley.addValidator('lte', {
      validateString: function (value, requirement) {
        return parseFloat(value) <= parseRequirement(requirement);
      },
      priority: 32,
    });
  }

  /**
   * on init
   */
  async ngOnInit() {
    this.addDateValidators();

    // Sets application title
    this.setTitle(environment.appTitle);

    let context = this;
    window.addEventListener('beforeunload', function () {
      // context.busyAuthentication.unsubscribe();
      // context.auth.expiring.unsubscribe();
      context.onClickLogout();
    });

    // Prevent dragging and dropping of files anywhere on the app except the drop zone.
    // Otherwise, browser will try to open the image or other files and exits the application
    window.addEventListener(
      'dragover',
      function (e) {
        // e = e || event;
        e.preventDefault();
      },
      false
    );

    window.addEventListener(
      'drop',
      function (e) {
        // e = e || event;
        e.preventDefault();
      },
      false
    );
  }

  login() {
    this.router.navigate(['login']);
  }
  /**
   * on destroy
   */
  ngOnDestroy() {
    this.onClickLogout();
  }

  /**
   * Determines whether click logout on
   */
  public onClickLogout() {
    this.auth.signOut();
  }

  /**
   * Determines whether close on
   */
  public onClose() {
    this.auth.removeSessionExpiryTimers();
  }

  /**
   * Determines whether logged in is
   * @returns
   */
  public isLoggedIn() {
    if (this.auth.loggedIn.value) {
      return true;
    }
    return false;
  }

  public isTopNavbarShown() {
    if (this.auth.isTopNavBarShown()) {
      return true;
    }
    return false;
  }

  /**
   * Sets title
   * @param newTitle
   */
  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
}
