<div class="loginbox-wrapper">
  <!--header section-->
  <header class="login-header">
    <h1 class="d-none">Login Page</h1>
    <div class="row">
      <div class="col-sm-6">
        <!-- <img
          src="assets/img/APP_NEXTGEN.svg"
          class="app-logo"
          alt="App Logo" /> -->
      </div>
      <div class="col-sm-6">
        <div class="helplinktext">
          <!-- <a [routerLink]="['/home']" class="ml20"><i class="fas fa-fw fa-home txt-gap"></i>Home</a>  -->
          <!-- <a [routerLink]="['/help']" target="_blank" class="ml20">Need Help?</a> -->
        </div>
      </div>
    </div>
  </header>
  <!-- Header section ends-->

  <div class="loginbox loginbox__container">
    <!-- Login section -->
    <div class="container">
      <main id="content" role="main" class="h100 mt70">
        <div class="row h100">
          <!-- Left half of the page with background image -->
          <div class="col-md-3 login-left align-self-center"></div>
          <!-- Right half of the page with the login form -->
          <div class="col-md-6 login-right">
            <form
              class="login-form mt-lg"
              id="frm-login"
              role="form"
              data-parsley-priority-enabled="false"
              novalidate="novalidate"
              data-parsley-validate
              data-parsley-focus="first">
              <h1
                title="login title"
                class="fw-bold mb20 text-center loginbox__apptitle">
                <img
                  src="assets/img/aiml.png"
                  class="aiml-logo"
                  alt="App Logo" />{{loginTitle}}
              </h1>
              <h2 title="login title" class="no-margin fw-bold">
                Authorized Users - Sign in.
              </h2>
              <!-- Error Card -->
              <div *ngIf="isErrorCard" role="alert" aria-live="assertive">
                <app-alert-card
                  title="Could not sign in."
                  type="ERROR"
                  [descriptionList]="errorMessageList"></app-alert-card>
              </div>
              <!-- Entering As Role - For Testing Purpose -->
              <!-- <div *ngIf="mockAuth" class="form-group mt20">
                <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12">
                    <label class="control-label fw-bold" for="login_rolename">Role</label>
                    <select [(ngModel)]="loginRole" [ngModelOptions]="{standalone: true}" class="form-control" id="login_rolename">
                    <option value="DDA">DCC Data Admin</option>
                    <option value="DHA">COVID Data Hub Data Admin</option>                    
                    <option value="ORG">ORG Researcher</option>    
                    <option value="ADM">Admin</option>                          
                  </select>
                  </div>
                </div>
              </div>               -->
              <!-- Please acknowledge -->
              <div class="form-group">
                <div class="row">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-8">
                        <label class="control-label fw-bold" for="ack-text"
                          >Please acknowledge the following</label
                        >
                      </div>
                      <div class="col-md-4 text-right">
                        <label
                          class="control-label fw-bold txt-gap"
                          for="expand-popup"
                          >Expand</label
                        >
                        <a
                          id="expand-popup"
                          class="mlr10 mt10"
                          (click)="showAckPopup()"
                          title="Expand Popup">
                          <i class="fas fa-expand-alt"></i>
                        </a>
                      </div>
                    </div>
                    <textarea
                      id="ack-text"
                      class="form-control login__textarea"
                      readonly
                      wrap="hard">
                    {{ackMessage}}
                    </textarea>
                  </div>
                </div>
              </div>
              <!-- I have read and understood -->
              <!-- <div class="tl-custom-control">
                <input type="checkbox" class="tl-custom-control-input" id="ack-chk" required [checked]="(userAcknowledged) ? true : null"
                  data-parsley-required-message="You must acknowledge the 'I have read and understood this message'"
                  data-parsley-errors-messages-disabled>
                <label class="tl-custom-control-label" for="ack-chk">I have read and understood this message</label>
              </div> -->

              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12">
                  <label class="control-label fw-bold" for="login_rolename"
                    >Role</label
                  >
                  <select
                    [(ngModel)]="loginRole"
                    [ngModelOptions]="{standalone: true}"
                    class="form-control"
                    id="login_rolename">
                    <option selected value="CBU">
                      Demo User
                    </option>                    
                    <option selected value="CIU">
                      Cigarette Industry User
                    </option>
                    <option value="CWLR">
                      Cigarette Warning Label Reviewer
                    </option>
                    <option value="DCRA">Drugs Compliance Reviewer</option>
                    <option value="SRI">Retail Inspector</option>
                    <option value="ADMIN">Admin Access</option>                    
                  </select>
                </div>
              </div>

              <!-- Username -->
              <!-- <div class="form-group mt20">
                <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12">
                    <label class="control-label fw-bold" for="login_uname">Username</label>
                    <input type="email" class="form-control" id="login_uname" name="username" aria-label="username"
                      [(ngModel)]="model.username" title="Enter username" required data-parsley-trigger="change"
                      data-parsley-error-message="Entry invalid. Please be sure your entry is in email format."
                      data-parsley-required-message="Username entered does not match records.">
                  </div>
                </div>
              </div> -->

              <!-- Password -->
              <!-- <div class="form-group">
                <div class="row">
                  <div class="col-xl-12 col-lg-12 ol-md-12">
                    <label class="control-label fw-bold" for="login_pwd">Password</label>
                    <div class="input-group">
                      <input class="form-control pwd-control" id="login_pwd" name="password" length="40"
                        [type]="showPasswordFlag ? 'text' : 'password'" [(ngModel)]="model.password"
                        title="Password must contain at least 1 uppercase, 1 lowercase and 1 special character"
                        aria-label="Password must contain at least 1 uppercase, 1 lowercase and 1 special character"
                        required data-parsley-required-message="Password entered does not match records."
                        data-parsley-error-message="Password entered does not match records." data-parsley-required
                        data-parsley-errors-messages-disabled>
                      <div class="input-group-append">
                        <a *ngIf="showPasswordFlag" class="mlr10 mt10" (click)="showPassword()" title="Show Password">
                          <i class="fas fa-eye"></i>
                        </a>
                        <a *ngIf="!showPasswordFlag" class="mlr10 mt10" (click)="showPassword()" title="Hide Password">
                          <i class="fas fa-eye-slash"></i>
                        </a>
                      </div>
                    </div>
                    <span class="errorpasswordsinput"></span>
                  </div>
                </div>
              </div>               -->

              <div class="clearfix">
                <div class="row mt20">
                  <div class="col-xl-6 col-lg-6 col-md-6">
                    <button
                      id="btn-login"
                      type="submit"
                      (click)="login()"
                      class="btn btn-primary btn-block">
                      <span
                        *ngIf="isLoginButtonClicked"
                        class="spinner-border spinner-border-sm txt-gap"></span>
                      <span *ngIf="isLoginButtonClicked">Signing In...</span>
                      <span *ngIf="!isLoginButtonClicked">Sign In</span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row mt10">
                  <div class="col-xl-12 col-lg-12 col-md-12">
                    <small class="text-muted text-right"
                      >UI Build {{buildTimeStamp}}</small
                    >
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-3"></div>
        </div>
      </main>
    </div>
    <!-- Login section Ends -->
  </div>
</div>  
