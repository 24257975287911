import {
  Directive,
  HostListener,
  ElementRef,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { CustomNumberPipe } from './custom-number.pipe';

declare var jQuery: any;

@Directive({
  selector: '[customDecimalFormatter]',
  providers: [CustomNumberPipe],
})
export class CustomDecimalFormatterDirective implements OnInit, OnChanges {
  @Input('customDecimalFormatter') fractionSize: number;
  @Input('valueChg') valChg: number;

  private el: HTMLInputElement;

  constructor(
    private elementRef: ElementRef,
    private numberPipe: CustomNumberPipe
  ) {
    this.el = this.elementRef.nativeElement;
  }

  moveCursorToEnd(el) {
    if (typeof el.selectionStart === 'number') {
      el.selectionStart = el.selectionEnd = el.value.length;
    } else if (typeof el.createTextRange !== 'undefined') {
      el.focus();
      let range = el.createTextRange();
      range.collapse(false);
      range.select();
    }
  }

  ngOnInit() {
    if (this.el.value != null && this.el.value.trim() !== '') {
      this.el.value = this.numberPipe.transform(this.el.value);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    for (let propName in changes) {
      if (propName === 'valChg') {
        let chg = changes[propName];
        if (chg.currentValue) {
          let isFocused: boolean = jQuery(this.el).is(':focus');
          if (
            this.el.value != null &&
            this.el.value.trim() !== '' &&
            !isFocused
          ) {
            setTimeout(
              _ =>
                (this.el.value = this.numberPipe.transform(
                  this.el.value,
                  this.fractionSize
                ))
            );
          }
        }
      }
    }
  }

  @HostListener('focus', ['$event.target.value'])
  onFocus(value) {
    if (this.el.value != null && this.el.value.trim() !== '') {
      this.el.value = this.numberPipe.parse(value, this.fractionSize);
      this.el.select();
      // this.moveCursorToEnd(this.el);
    }
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value) {
    if (this.el.value != null && this.el.value.trim() !== '') {
      this.el.value = this.numberPipe.transform(value, this.fractionSize);
    }
  }

  // Do not allow commas and more than one decimal
  isInvalidKeyEntry(event, value): boolean {
    if (event.key === ',') {
      return true;
    }

    if (event.key === '.') {
      if (
        Math.abs(
          event.currentTarget['selectionEnd'] -
            event.currentTarget['selectionStart']
        ) === 0
      ) {
        if (value.indexOf('.') > -1) {
          return true;
        }
      }
    }

    return false;
  }

  @HostListener('keypress', ['$event', '$event.target.value'])
  keypress(event: KeyboardEvent, value) {
    if (this.isInvalidKeyEntry(event, value)) {
      event.preventDefault();
      return false;
    }

    let inputVal = this.frac(Number(value + event.key));
    if (
      (String(inputVal).length <= 2 + this.fractionSize && +event.key !== 0) ||
      (+event.key === 0 &&
        value.indexOf('.') !== -1 &&
        value.split('.')[1].length + 1 <= this.fractionSize)
    ) {
      if (
        ((event.which >= 48 && event.which <= 57) ||
          (event.which >= 37 && event.which <= 40) ||
          event.which === 46) &&
        !event.shiftKey
      ) {
        return true;
      }
    } else if (
      event.currentTarget['selectionEnd'] <= value.split('.')[0].length ||
      event.currentTarget['selectionEnd'] !==
        event.currentTarget['selectionStart']
    ) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  frac = function (dnum) {
    return +(+dnum)
      .toExponential()
      .replace(
        /(-?)(\d+(\.?)\d*)e(.+)/,
        function (m, neg, num, dot, offset): any {
          let zeroes = Array(Math.abs(offset) + 2).join('0');
          num = (zeroes + num + (dot ? '' : '.') + zeroes).split('.');
          return +(neg + '.' + num.join('').slice(+offset + num[0].length));
        }
      );
  };
}
