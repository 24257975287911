import { Directive, ElementRef, HostListener, Input } from '@angular/core';

/**
 * Directive which allows only numeric values from 0 to 9.
 * Handles user input, copy paste and drop events.
 */
@Directive({
  selector: '[numericOnly]',
})
export class NumericOnlyDirective {
  constructor(private _el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this._el.nativeElement.value;

    this._el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
    if (initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
