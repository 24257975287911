<!-- Modal Header -->
<div class="modal-header" confirmpopup ngxModalDraggable>
  <h1
    class="h2 modal-title text-xs-left fw-bold mt confirmpopup__header"
    tabindex="-1"
    id="confirm-title">
    {{title}}
  </h1>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    tabindex="1001"
    (click)="onCancel()"
    (keydown)="preventTabBack($event)">
    <span aria-hidden="true"><i class="fas fa-times"></i></span>
  </button>
</div>
<!-- Modal Body -->
<div class="modal-body">
  <div class="row ml20 mr20">
    <div class="col-md-12">
      <form
        id="wannskipform"
        role="form"
        class="form-horizontal"
        autocomplete="off"
        data-parsley-priority-enabled="false"
        novalidate="novalidate"
        data-parsley-validate
        data-parsley-focus="first">
        <div [innerHTML]="message"></div>
      </form>
    </div>
  </div>
  <div *ngIf="showDontAskAgain" class="row ml20 mr20">
    <div class="col-md-12">
      <div class="center ml50 mt10 mb10 tl-custom-control">
        <input
          type="checkbox"
          class="tl-custom-control-input"
          id="warning-message"
          [(ngModel)]="isDontAskAgainSelected"
          [ngModelOptions]="{standalone: true}" />
        <label class="tl-custom-control-label" for="warning-message"
          >Don't ask me this again</label
        >
      </div>
    </div>
  </div>
</div>
<!-- Modal Footer -->
<div *ngIf="footer === true" class="modal-footer modal-footer-modified">
  <div class="row ml20 mr20">
    <div class="col-md-12">
      <form id="estaddressfooter" class="form-horizontal">
        <div class="form-group">
          <div class="row">
            <div class="col-md-12">
              <button
                type="submit"
                id="btn-save"
                tabindex="1002"
                class="btn btn-primary col-md-12"
                (click)="onConfirm()">
                {{yesText}}
              </button>
            </div>
          </div>
        </div>
        <div class="form-group" *ngIf="noText">
          <div class="row">
            <div class="col-md-12">
              <button
                type="button"
                class="btn btn-link col-md-12"
                tabindex="1003"
                (click)="onCancel()"
                (keydown)="preventTab($event)">
                {{noText}}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
