import { Directive, HostListener, ElementRef, OnInit } from '@angular/core';
import { CustomCurrencyPipe } from './custom.currency.pipe';

@Directive({ selector: '[customCurrencyFormatter]' })
export class CustomCurrencyFormatterDirective implements OnInit {
  private el: HTMLInputElement;
  private isAutofilled: boolean;

  constructor(
    private elementRef: ElementRef,
    private currencyPipe: CustomCurrencyPipe
  ) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {
    this.el.value = this.currencyPipe.transform(this.el.value);
    this.isAutofilled = false;
  }

  @HostListener('focus', ['$event.target.value'])
  onFocus(value) {
    this.el.value = this.currencyPipe.parse(value); // opossite of transform
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value) {
    this.el.value = this.currencyPipe.transform(value);
  }

  @HostListener('change', ['$event.target.value'])
  onChange(value) {
    if (this.isAutofilled) {
      return;
    }
    this.el.value = this.currencyPipe.transform(value);
    this.isAutofilled = true;
  }
}
