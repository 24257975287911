import { Directive, HostListener, ElementRef, OnInit } from '@angular/core';
import { CustomNumberPipe } from './custom-number.pipe';

@Directive({
  selector: '[customNumberValidator]',
  providers: [CustomNumberPipe],
})
export class CustomNumberVaidatorDirective implements OnInit {
  private el: HTMLInputElement;

  constructor(
    private elementRef: ElementRef,
    private numberPipe: CustomNumberPipe
  ) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {}

  @HostListener('focus', ['$event.target.value'])
  onFocus(value) {
    if (this.el.value != null && this.el.value.trim() !== '') {
      // this.el.value = this.numberPipe.parse(value,0);
    }
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value) {
    if (this.el.value != null && this.el.value.trim() !== '') {
      // this.el.value = this.numberPipe.transform(value,0);
    }
  }
}
