<div class="modal fade" id="confirm-popup">
  <div class="modal-dialog" role="confirm-popup">
    <div class="modal-content">
      <div class="modal-header">
        <div title="title" class="h4 modal-title text-xs-center fw-bold mt">
          {{title}}
        </div>
      </div>
      <!--Modal Body-->
      <div class="modal-body">
        <div>
          <span><b>{{msg}}</b></span>
        </div>
      </div>
      <!--Modal Footer-->
      <div class="modal-footer">
        <button
          type="button"
          id="btn-del-doc"
          class="btn btn-primary"
          [attr.title]="documentDesc"
          (click)="delete()">
          Confirm
        </button>
        <button
          type="button"
          class="btn btn-gray"
          (click)="reset()"
          title="Cancel">
          Cancel
        </button>
        <p title="Deleting"></p>
      </div>
    </div>
  </div>
</div>
