import {
  Directive,
  Input,
  EventEmitter,
  ElementRef,
  HostListener,
  Inject,
  Renderer2,
} from '@angular/core';

declare let jQuery: any;

@Directive({
  selector: '[focusOn]',
})
export class FocusDirective {
  @Input('focusOn') divElement = 'form';

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    const ele = this.divElement;
    setTimeout(function () {
      jQuery(ele)
        .find('a,input,button,textarea,select')
        .filter(':first')
        .focus();
    }, 500);
  }
}
