<div class="chat-sidebar-chat chat-sidebar-panel" [ngClass]="{'open': open}">
  <h6 class="title">
    <a (click)="closeChatArea()">
      <i class="fa fa-angle-left mr-xs"></i>
      {{conversation.name}}
    </a>
  </h6>
  <ul class="message-list bg-gray-light">
    <li *ngFor="let message of conversation.messages | SearchPipe : searchMessage" class="message" [ngClass]="{'from-me': message.fromMe}">
    <span class="thumb-sm">
        <img class="rounded-circle" src="{{message.fromMe ? 'assets/img/avatar.png' : conversation.image}}" alt="...">
    </span>
      <div class="message-body">{{message.text}}</div>
    </li>
  </ul>
  <footer class="chat-sidebar-footer">
    <input class="form-control fs-mini"
           [(ngModel)]="newMessage" (keyup.enter)="addMessage()"
           type="text" placeholder="Type your message">
  </footer>
</div>
