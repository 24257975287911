import { Component, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../../modules/login/services/auth.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: '[session-expired-modal]',
  templateUrl: './session-expired.modal.html',
  encapsulation: ViewEncapsulation.None,
})
export class SessionExpiredPopupComponent {
  timeToExpire: number;
  keepSessionAliveClicked: boolean;

  constructor(private auth: AuthService, public bsModalRef: BsModalRef) {
    this.keepSessionAliveClicked = false;
  }

  ngOnInit() {}

  ngOnDestroy() {}

  keepSessionAlive() {
    this.keepSessionAliveClicked = true;
    this.refreshSession();
    this.bsModalRef.hide();
  }

  refreshSession() {}

  logout() {
    this.cleanupSessionExpiration();
    // sessionStorage.clear();
    // this.auth.signOut();
  }

  cleanupSessionExpiration() {
    this.bsModalRef.hide();
  }
}
