import { Injectable } from '@angular/core';

@Injectable()
export class ErrorInterceptor {
  constructor() {}

  // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //     return next.handle(request).pipe(catchError(err => {
  //         if (err.status === 401) {
  //             // auto logout if 401 response returned from api
  //             // this.authenticationService.logout();
  //             location.reload(true);
  //         }

  //         const error = err.error.message || err.statusText;
  //         return throwError(error);
  //     }));
  // }
}
