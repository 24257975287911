import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.template.html',
  styleUrls: ['./info-card.style.scss'],
})
export class InfoCardComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() title2: string;
  @Input() description2: string;  
  @Input() type: string; // WARNING, ERROR

  constructor() {}

  ngOnInit() {}
}
