import { OnInit, Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-file-upload-failed-popup',
  templateUrl: './file-upload-failed-popup.template.html',
})
export class FileUploadFailedPopupComponent implements OnInit {
  failedFiles: [];

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit() {}
}
