import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { DataTable, SortEvent } from './DataTable';

@Component({
  selector: 'mfDefaultSorter',
  template: `
    <a
      tabindex="0"
      class="text-nowrap table-col-hdr "
      style="cursor: pointer"
      (click)="sort()"
      (keyup.enter)="sort()"
      (keyup.space)="sort()"
      [ngClass]="alignBy">
      <ng-content></ng-content>
      <span
        *ngIf="isSortedByMeAsc"
        class="glyphicon glyphicon-triangle-top"
        aria-hidden="true"></span>
      <span
        *ngIf="isSortedByMeDesc"
        class="glyphicon glyphicon-triangle-bottom"
        aria-hidden="true"></span>
    </a>
  `,
})
export class DefaultSorter implements OnInit, OnChanges {
  @Input('by') sortBy: string;
  @Input('align') alignBy: string;

  isSortedByMeAsc: boolean = false;
  isSortedByMeDesc: boolean = false;

  public constructor(private mfTable: DataTable) {}

  public ngOnInit(): void {
    this.mfTable.onSortChange.subscribe((event: SortEvent) => {
      this.isSortedByMeAsc =
        event.sortBy === this.sortBy && event.sortOrder[0] === 'asc';
      this.isSortedByMeDesc =
        event.sortBy === this.sortBy && event.sortOrder[0] === 'desc';
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    for (let propName in changes) {
      if (propName === 'align') {
        this.alignBy = changes[propName].currentValue;
      }
    }
  }

  sort() {
    if (this.isSortedByMeAsc) {
      this.mfTable.setSort(this.sortBy, ['desc']);
    } else {
      this.mfTable.setSort(this.sortBy, ['asc']);
    }
  }
}
