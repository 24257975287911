<div class="chat-sidebar-content">
  <header class="chat-sidebar-header">
    <h4 class="chat-sidebar-title">Documents</h4>
    <div class="form-group m-0">
      <div class="input-group">
        <input class="form-control fs-mini" [(ngModel)]="searchText" type="text" placeholder="Search...">
        <span class="input-group-append">
            <span class="input-group-text">
              <i class="fa fa-search"></i>
            </span>
          </span>
      </div>
    </div>
  </header>
  <div class="chat-sidebar-contacts chat-sidebar-panel" [ngClass]="{'open': !chatMessageOpened}">
    <h5 class="sidebar-nav-title">Today</h5>
    <div class="list-group chat-sidebar-user-group">
      <a *ngFor="let conversation of conversations.todayConversations | SearchPipe : searchText"
         (click)="deactivateLink($event); openConversation(conversation)"
         class="list-group-item">
        <span class="thumb-sm mr">
            <img class="rounded-circle" src="{{conversation.image}}" alt="...">
        </span>
        <div class="mr-auto">
          <h6 class="message-sender">{{conversation.name}}</h6>
          <p class="message-preview">{{conversation.lastMessage}}</p>
        </div>
        <i class="fa fa-circle ml-1 text-{{conversation.status}}"></i>
      </a>
    </div>

    <h5 class="sidebar-nav-title">Last Week</h5>
    <div class="list-group chat-sidebar-user-group">
      <a *ngFor="let conversation of conversations.lastWeekConversations | SearchPipe : searchText"
         (click)="deactivateLink($event); openConversation(conversation)"
         class="list-group-item">
        <span class="thumb-sm mr">
            <img class="rounded-circle" src="{{conversation.image}}" alt="...">
        </span>
        <div class="mr-auto">
          <h6 class="message-sender">{{conversation.name}}</h6>
          <p class="message-preview">{{conversation.lastMessage}}</p>
        </div>
        <i class="fa fa-circle ml-1 text-{{conversation.status}} float-right"></i>
      </a>
    </div>
  </div>
  <app-chat-message [searchMessage]="searchText" [conversation]="activeConversation" [open]="chatMessageOpened" (chatMessageClosed)="chatMessageOpened = false"></app-chat-message>
</div>
