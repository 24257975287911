import { CustomNumberPipe } from './custom-number.pipe';
import { Pipe } from '@angular/core';

// @Pipe({ name: "custom-currency" })
@Pipe({ name: 'customnumber' })
export class CustomCurrencyPipe extends CustomNumberPipe {
  private CURRENCY_SIGN: string;

  constructor() {
    super();
    this.CURRENCY_SIGN = '$';
  }

  transform(value: number | string, fractionSize: number = 2): string {
    return this.CURRENCY_SIGN + super.transform(value, fractionSize);
  }

  parse(value: string, fractionSize: number = 2): string {
    value = value.replace(new RegExp('\\' + this.CURRENCY_SIGN, 'g'), '');
    return super.parse(value, fractionSize);
  }
}
