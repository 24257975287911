import { Directive, HostListener, ElementRef, OnInit } from '@angular/core';

@Directive({ selector: '[customPercent]' })
export class CustomPercentDirective implements OnInit {
  private el: HTMLInputElement;

  constructor(private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {}

  @HostListener('keypress', ['$event', '$event.target.value'])
  keypress(event: KeyboardEvent, value) {
    let inputVal = Number(value + event.key);
    if (inputVal <= 100) {
      if (
        ((event.which >= 48 && event.which <= 57) ||
          (event.which >= 37 && event.which <= 40) ||
          event.which === 46) &&
        !event.shiftKey
      ) {
        return true;
      }
    } else {
      event.preventDefault();
      return false;
    }
  }
}
