import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import 'jquery-slimscroll';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AppFooterComponent } from './app-footer/app-footer.component';
import { LayoutComponent } from './layout.component';
import { layoutRoutes } from './layout.routes';
import { NotificationsLoadDirective } from './notifications/notifications-load.directive';
import { NotificationsComponent } from './notifications/notifications.component';
import { TopNavbarComponent } from './top-navbar/top-navbar.component';
import { SessionExpiredPopupComponent } from './session-expired/session-expired.component';
import { SharedModule } from '../shared/shared.module';
import { HelpComponent } from './help/help.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ChatSidebarComponent } from './chat-sidebar/chat-sidebar.component';
import { ChatMessageComponent } from './chat-sidebar/chat-message/chat-message.component';
import { SidebarComponent } from './sidebar/sidebar.component';

@NgModule({
  imports: [
    CommonModule,
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    RouterModule.forChild(layoutRoutes),
    FormsModule,
    NgxPermissionsModule,
    SharedModule,
    ModalModule,
  ],
  declarations: [
    LayoutComponent,
    NotificationsComponent,
    NotificationsLoadDirective,
    TopNavbarComponent,
    ChatSidebarComponent,    
    AppFooterComponent,
    SessionExpiredPopupComponent,
    HelpComponent,
    ChatMessageComponent,
    SidebarComponent    
  ],
  providers: [BsModalService],
  exports: [TopNavbarComponent, AppFooterComponent],
  // entryComponents: [SessionExpiredPopupComponent, HelpComponent],
})
export class LayoutModule {}
