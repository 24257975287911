<div class="modal-header">
  <h4 class="modal-title pull-left fw-bold">File Upload Failed</h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>
    The upload of following files cannot be completed due to technical issues.
  </p>
  <ul>
    <li *ngFor="let ff of failedFiles">{{ff["fileName"]}}</li>
  </ul>
  <p class="pt-2">
    If you have any questions, please reach out to
    <a href="mailto:NHelpdesk@nih.gov"
      >Helpdesk&#64;nih.gov</a
    >. In your reply, provide your associated email address, reason for contact,
    and Registration ID #.
  </p>
</div>
<div class="modal-footer justify-content-center">
  <button
    type="button"
    class="btn btn-primary w-100"
    (click)="bsModalRef.hide()">
    Cancel
  </button>
</div>
