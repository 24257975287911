import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { isNil } from 'ramda';
import { LoginState } from '../../modules/login/model/login-state.model';
import { AuthService } from '../../modules/login/services/auth.service';
import { environment } from '../../../environments/environment';
import { NgxPermissionsService } from 'ngx-permissions';
import { PingAuthService } from '../../modules/login/services/ping-auth.service';

@Component({
  selector: 'app-top-navbar',
  templateUrl: './top-navbar.template.html',
  styleUrls: ['./top-navbar.style.scss'],
})
export class TopNavbarComponent implements OnInit {
  portalName: string = '';
  loggedUsername: string = 'NA';

  constructor(
    private router: Router,
    private auth: AuthService,
    private oauthService: OAuthService,
    private permissionService: NgxPermissionsService,
    private pingAuthService: PingAuthService
  ) {}

  ngOnInit() {
    this.portalName = 'Industry Portal';
  }

  canEditProfile() {
    return this.isLoggedIn();
  }

  isLoggedIn() {
    if (this.auth.getLoginState().isLoggedIn()) {
      return true;
    }
    return false;
  }

  gotoHomePage() {
    this.auth.redirectToHome();
  }

  isDDAHome() {
    if (
      this.permissionService.getPermission('DDA') &&
      this.permissionService.getPermission('DDA').name === 'DDA'
    ) {
      return true;
    }
    return false;
  }

  navigateToHome() {
    if (this.isLoggedIn()) {
      this.auth.redirectToHome();
    } else {
      this.router.navigate(['/signin']);
    }
  }

  onGotoDataIku(){
    const dataikuUrl = environment.DATAIKUURL;
    window.open(dataikuUrl, '_blank');
  }

  onGotoOpenSearch(){
    const openSearchUrl = "https://genai.intellireviewer.com/_dashboards"
    window.open(openSearchUrl, '_blank');
  }

  isMyHomePageShown() {
    let status: LoginState = this.auth.getLoginState();
    return this.isLoggedIn() && status.isLoggedIn();
  }

  isMyProfileShown() {
    // let status: LoginState = this.auth.getLoginState();
    // if (this.isLoggedIn() && status.isLoggedIn() && this.auth.isIndustryMode()) {
    //   let user: any = this.auth.getLoggedUserIdentity();
    //   if(!isNil(user)){
    //     this.loggedUsername = user.name;
    //   }
    //   return true;
    // }
    return false;
  }

  gotoMyProfile() {
    this.router.navigate(['profile']);
  }

  launchUserGuide() {
    this.router.navigate(['help']);
  }

  checkNotifications() {}

  onClickLogout() {
    sessionStorage.clear();
    if (environment.PINGENABLED) {
      this.pingAuthService.startLogOut();
    } else {
      this.auth.signOut();
    }
  }

  onClickHome(){
    this.router.navigate(['landing/home']);
  }

  toggleChat(): void {
    // this.toggleChatEvent.emit(null);
  }

  onClickChatDemo() {
    this.router.navigate(['chatbot/ask']);
  }

  onClickSubmissions(){
    this.router.navigate(['submission/scan']);
  }  

  onClickOpenSearchML(){
    this.router.navigate(['opensearchml/home']);  
  }

  onClickLabelReview( ){
    this.router.navigate(['labels/review']);
  }

  onPromptManager(){
    this.router.navigate(['chatbot/promptmanager']);
  }

  onClickArchitectureReview( ){
    this.router.navigate(['opensearchml/gallery']);
  }

  onClickLLMHosting(){
    this.router.navigate(['chatbot/llmhosting']);
  }

  onClickReviewVideos(){
    this.router.navigate(['videos/analyze']);
  }
}
