import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

declare var jQuery: any;

/**
 *
 *
 * @export
 * @class InfoBoxPopup
 * @implements {OnInit}
 */
@Component({
  selector: '[info-box]',
  templateUrl: './infobox.template.html',
})
export class InfoBoxPopup implements OnInit {
  // pass any local model data
  @Input() model: any;

  // pass message to be displayed
  @Input() msg: string;

  // pass title of the info box
  @Input() title: string;

  // callback when the popup is cancelled
  @Output() hideModal = new EventEmitter<boolean>();

  currentModel: any;

  ngOnInit() {}

  constructor() {}

  /**
   * Hide the modal window when the OK button is clicked
   *
   * @memberof InfoBoxPopup
   */
  public onClose() {
    jQuery('#info-popup').modal('hide');
    this.hideModal.emit(true);
  }

  // handle when new data is brought to scope
  ngOnChanges(changes: SimpleChanges) {
    for (let propName in changes) {
      if (propName === 'model') {
        let chg = changes[propName];
        if (chg.currentValue) {
          this.currentModel = chg.currentValue;
        }
      }
    }
  }
}
