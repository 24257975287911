<nav class="topnav topnav__container">
  <ul class="topnav__main-nav">
    <li>
      <div class="no-printme">
        <a (click)="navigateToHome()">
          <img
            src="assets/img/aiml-title.png"
            class="app-logo"
            alt="App Logo" />
        </a>
      </div>
    </li>
    <li class="mt20 mr20">
      <div class="topnav__appname">
        <!-- <span class="fw-bold"
          ><i class="fas fa-fw fa-cog txt-gap"></i>AI/ML Demo</span
        > -->
      </div>
    </li>
    <!-- <li class="" *ngIf="isLoggedIn()">
      <a  *ngIf="isDDAHome(); else showOtherHome" class="mt20 nav-links"  [routerLink]="['welcome']"    routerLinkActive="nav-links-active">
        <i class="fas fa-fw fa-home txt-gap"></i>Home
      </a>
      <ng-template #showOtherHome>
        <a class="mt20 nav-links"  [routerLink]="['/labels/review']"    routerLinkActive="nav-links-active">
          <i class="fas fa-fw fa-home txt-gap"></i>Home
        </a>
      </ng-template>
    </li> -->
    <li
      class="nav-item dropdown"
      *ngIf="isMyProfileShown(); else showLogoutLink">
      <a
        class="mt20 nav-link dropdown-toggle no-printme"
        href="javascript:void(0);"
        id="navbarDropdown"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false">
        <i class="fas fa-fw fa-user txt-gap"></i>My Account
      </a>
      <div class="dropdown-menu no-printme" aria-labelledby="navbarDropdown">
        <a class="dropdown-item plr20 no-printme" (click)="gotoMyProfile()"
          >My Profile</a
        >
        <a class="dropdown-item plr20 no-printme" (click)="onClickLogout()"
          >Logout</a
        >
      </div>
    </li>

    <ng-template #showLogoutLink>
      <!-- <li class="" *ngxPermissionsOnly="['CBU', 'ADMIN']">
        <a class="mt20 no-printme nav-links" (click)="onClickChatDemo()">
          <i class="fas fa-fw fa-comment txt-gap"></i>Chat Demo</a
        >
      </li>       
      <li class="" *ngxPermissionsOnly="['CBU', 'ADMIN']">
        <a class="mt20 no-printme nav-links" (click)="onPromptManager()">
          <i class="fas fa-fw fa-terminal txt-gap"></i>Prompt Manager</a
        >
      </li>     
      <li class="" *ngxPermissionsOnly="['CBU', 'ADMIN']">
        <a class="mt20 no-printme nav-links" (click)="onClickLLMHosting()">
          <i class="fas fa-fw fa-server txt-gap"></i>LLM Hosting</a
        >
      </li>           -->
      <!-- <li class="" *ngxPermissionsOnly="['CIU', 'CWLR', 'SRI', 'ADMIN']">
        <a class="mt20 no-printme nav-links" (click)="onClickLabelReview()">
          <i class="fas fa-fw fa-book txt-gap"></i>Review Labels</a
        >
      </li>   -->
      <!-- <li class="mr20 text-white" *ngxPermissionsOnly="['DCRA']">
        <a class="mt20 no-printme nav-links" (click)="onClickReviewVideos()">
          <i class="fas fa-fw fa-film txt-gap"></i>Drug Advertisements</a
        >
      </li>  
      <li class="mr20 text-white" *ngxPermissionsOnly="['DCRL']">
        <a class="mt20 nav-links" [routerLink]="['druglabel/text']" routerLinkActive="nav-links-active">
          <i class="fas fa-fw fa-pills txt-gap"></i>Drug Labels</a>      
      </li>                      -->
      <!-- <li class="" *ngxPermissionsOnly="['SSU']">
        <a class="mt20 no-printme nav-links" (click)="onClickOpenSearchML()">
          <i class="fas fa-fw fa-search txt-gap"></i>OpenSearch ML</a
        >
      </li>   -->
      <li class="">
        <a class="mt20 no-printme nav-links" (click)="onClickHome()">
          <i class="fas fa-fw fa-home txt-gap"></i>Home</a
        >
      </li>         
      <li class="" *ngxPermissionsOnly="['CWLR', 'SRI', 'ADMIN']">
        <a class="mt20 no-printme nav-links" (click)="onClickSubmissions()">
          <i class="fas fa-fw fa-inbox txt-gap"></i>Submissions</a
        >
      </li>    
      <li class="" *ngxPermissionsOnly="['CBU', 'ADMIN']">
        <a class="mt20 no-printme nav-links" (click)="onClickArchitectureReview()">
          <i class="fas fa-fw fa-building txt-gap"></i>Architecture</a
        >
      </li>  
      <!-- <li class="dropdown" dropdown placement="bottom right" [autoClose]="lalse" *ngIf="(isLoggedIn())">
        <a href="javascript:void(0)" dropdownToggle class="mt20 no-printme nav-links dropdown-toggle dropdown-toggle-notifications"  
            id="notifications-dropdown-toggle">
          <i class="fas fa-fw fa-bell"></i>
          <span class="ml-1 circle bg-warning text-white fw-bold mb-10">10</span>
        </a>
        <app-notifications *dropdownMenu class="dropdown-menu dropdown-menu-right animated animated-last ladeInUp mt20"></app-notifications>
      </li>   -->
      <!-- <li class="dropdown" dropdown placement="bottom right" [autoClose]="lalse">
        <a href="#" dropdownToggle class="nav-link dropdown-toggle dropdown-toggle-notifications"
           id="notifications-dropdown-toggle">
           <span class="avatar thumb-sm float-left mr-2">
              <img class="rounded-circle" src="assets/img/people/a5.jpg" alt="..." />
            </span>
            <span class="small">Philip <span class="fw-semi-bold">Smith</span></span>
            <span class="ml-1 circle bg-warning text-white fw-bold">13</span>
          <b class="caret"></b>
        </a>
        <app-notifications *dropdownMenu class="dropdown-menu dropdown-menu-right animated animated-last ladeInUp"></app-notifications>
      </li>                   -->
      <li class="" *ngIf="(isLoggedIn())">
        <a class="mt20 no-printme nav-links" (click)="onGotoOpenSearch()">
          <i class="fas fa-fw fa-external-link-alt txt-gap"></i>Opensearch</a
        >
      </li>        
      <li class="" *ngIf="(isLoggedIn())">
        <a class="mt20 no-printme nav-links" (click)="onGotoDataIku()">
          <i class="fas fa-fw fa-external-link-alt txt-gap"></i>Dataiku</a
        >
      </li>      
      <li class="" *ngIf="(isLoggedIn())">
        <a class="mt20 no-printme nav-links" (click)="onClickLogout()">
          <i class="fas fa-fw fa-sign-out-alt txt-gap"></i>Logout</a
        >
      </li>
      <li>
        <a
          class="mt20 no-printme nav-links"
          href="https://ctp-aiml-dev.trlmapp.com/user_guide/"
          target="_blank">
          <i class="fas fa-fw fa-question txt-gap"></i>Help</a
        >
      </li>
    </ng-template>
    <!-- <li class="mr20" *ngIf="!(isLoggedIn() )"><a class="mt20 " target="_blank" [routerLink]="['/help']">Need
        Help?</a></li>
    <li class="mr20 " *ngIf="(isLoggedIn() )"><a class="mt20 no-printme"
        target="_blank"[routerLink]="['/help']">Need Help?</a></li> -->
  </ul>
</nav>
