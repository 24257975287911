import { CommonModule, TitleCasePipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AccessDeniedComponent } from '../../authentication/components/access.denied.component';
import { SharedModule } from '../../shared/shared.module';
import { LoginComponent } from './components/login.component';
import { LogoutComponent } from './components/logout.component';
import { CanDeactivateGuardService } from './services/can-deactivate-guard.service';
import { PingCallbackComponent } from './components/ping-callback/ping-callback.component';
import { PingLogoutComponent } from './components/ping-logout/ping-logout.component';
import { PingUnauthorizedComponent } from './components/ping-unauthorized/ping-unauthorized.component';

export const routes = [
  { path: '', component: LoginComponent },
  { path: 'callback', component: PingCallbackComponent },
  { path: 'logout', component: PingLogoutComponent },
  { path: 'unauthorized', component: PingUnauthorizedComponent },
];

@NgModule({
  declarations: [
    LoginComponent,
    LogoutComponent,
    AccessDeniedComponent,
    PingCallbackComponent,
    PingLogoutComponent,
    PingUnauthorizedComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    ModalModule,
    RouterModule.forChild(routes),
  ],
  exports: [LoginComponent],
  providers: [TitleCasePipe, CanDeactivateGuardService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  // entryComponents: [],
})
export class LoginModule {
  static routes = routes;
}
