import { Pipe, PipeTransform } from '@angular/core';
/*
 * Format the phone number as xxx-xxx-xxxx
 * Takes permit number and formats it.
 * Usage:
 *   value | phoneNumber
 * Example:
 *   {{ xxxxxxxxxx |  phoneNumber}}
 *   formats to: xxx-xxx-xxxx
 */
@Pipe({ name: 'phoneNumberPipe' })
export class PhoneNumberPipe implements PipeTransform {
  transform(value: string, countryCode: string): string {
    let formattedValue = '';
    if (!value) {
      return value;
    }
    let phone = value.toString();
    if (countryCode) {
      if (
        countryCode === '+1' ||
        countryCode === '1' ||
        countryCode === '+01' ||
        countryCode === '001' ||
        countryCode === '+001'
      ) {
        let plainStr = phone.replace('-', '');
        if (plainStr.length === 10) {
          formattedValue =
            '(' +
            plainStr.substring(0, 3) +
            ') ' +
            plainStr.substring(3, 6) +
            '-' +
            plainStr.substring(6);
        } else {
          formattedValue = value;
        }
      } else {
        formattedValue = value;
      }
    } else {
      formattedValue = value;
    }
    return formattedValue;
  }
}
