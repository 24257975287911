<footer class="footer footer__container">
  <div class="pt20 footer_box">
    <!-- First Row -->
    <div class="row footer__container">
      <div class="col-lg-5 no-printme">
        <span class="text-white"></span>
      </div>
      <div class="col-lg-7 text-right no-printme">
      </div>
    </div>
    <!-- Second Row -->
    <div class="row footer__container no-printme">
      <div class="col-lg-12">
        <span class="text-white">&copy; 2024 Deloitte</span>
        <!-- <span class="ml20 text-white">Placeholder</span>
        <span class="ml20 text-white">Placeholder</span>
        <span class="ml20 text-white ">Placeholder</span> -->
      </div>
    </div>
  </div>
</footer>
