<div style="display: flex; align-items: center">
  <div
    [hidden]="!params.enableMenu"
    class="customHeaderMenuButton"
    (click)="onMenuClick()">
    <i class="fas fa-bars"></i>
  </div>
  <div class="customHeaderLabel" (click)="onSortRequested($event)">
    {{params.displayName}}
  </div>
  <span *ngIf="this.sortedState === 'desc'">
    <i class="fa fa-long-arrow-alt-down customSortLabel"></i>
  </span>
  <span *ngIf="this.sortedState === 'asc'">
    <i class="fa fa-long-arrow-alt-up customSortLabel"></i>
  </span>
</div>
