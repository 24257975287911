<div class="container">
  <main id="content" class="error-container" role="main">
    <div class="row justify-content-center">
      <div class="col-xl-4 col-md-6 col-10">
        <div class="error-container">
          <h1 class="error-code">404</h1>
          <p class="error-info">
            Opps, it seems that this page does not exist.
          </p>
          <p class="error-help mb">If you are sure it should, search for it.</p>
          <form (ngSubmit)="searchResult()" method="get">
            <div class="form-group">
              <input
                class="form-control input-no-border"
                type="text"
                placeholder="Search Pages" />
            </div>
            <button type="submit" class="btn btn-inverse">
              Search <i class="fa fa-search text-warning ml-xs"></i>
            </button>
          </form>
        </div>
      </div>
    </div>
  </main>

  <footer class="page-footer">
    <span>&copy; ORG - CovidHub Application</span>
  </footer>
</div>
