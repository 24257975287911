// PINGAUTHCODE

import { Injectable } from '@angular/core';
import {
  UserManagerSettings,
  User,
  UserManager,
  OidcClient,
} from 'oidc-client';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { LoginState } from '../model/login-state.model';
import { AuthService } from './auth.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { ProfileService } from './profile.service';
import { isNil } from 'ramda';
import { RegisteredAccountUser } from '../model/reg-account-user.model';

@Injectable({
  providedIn: 'root',
})
export class PingAuthService {
  private user: User = null;
  private userManager: UserManager;
  private client: OidcClient;

  constructor(
    private router: Router,
    private auth: AuthService,
    private profileService: ProfileService,
    private permissionsService: NgxPermissionsService
  ) {
    this.client = new OidcClient(this.pingConfig);
    this.userManager = new UserManager(this.pingConfig);
    this.userManager.getUser().then(user => {
      this.user = user;
    });
  }

  get pingConfig(): UserManagerSettings {
    return {
      authority: environment.PINGAUTHORITY,
      client_id: environment.PINGCLIENTID,
      redirect_uri: environment.PINGREDIRECT,
      post_logout_redirect_uri: environment.PINGLOGOUTREDIRECT,
      response_type: 'code',
      scope: environment.PINGSCOPES,
      filterProtocolClaims: true,
      loadUserInfo: true,
      // metadata: {
      //   issuer: environment.PINGAUTHORITY.replace(/\/$/, ''),   // Looks like training space is not in the right issuer url
      //   authorization_endpoint: `${environment.PINGAUTHORITY}as/authorization.oauth2`,
      //   userinfo_endpoint: `${environment.PINGAUTHORITY}idp/userinfo.openid`,
      //   end_session_endpoint: `${environment.PINGAUTHORITY}idp/startSLO.ping`,
      //   jwks_uri: `${environment.PINGAUTHORITY}pf/JWKS`,
      //   token_endpoint: `${environment.PINGAUTHORITY}as/token.oauth2`,
      //   revocation_endpoint: `${environment.PINGAUTHORITY}as/revoke_token.oauth2`,
      //   scopes_supported: ['address','phone','openid','profile','name','groups','preferred_username','email']
      // }
    };
  }

  isLoggedIn(): boolean {
    return this.user != null && !this.user.expired;
  }

  // tslint:disable-next-line: no-any
  getClaims(): any {
    return this.user.profile;
  }

  getAuthorizationHeaderValue(): string {
    if (!this.user) {
      return '';
    }
    return `${this.user.token_type} ${this.user.access_token}`;
  }

  getAuthorizationHeaderValueIdToken(): string {
    if (!this.user) {
      return '';
    }
    return `${this.user.id_token}`;
  }

  startAuthentication(): Promise<void> {
    return this.userManager.signinRedirect();
  }

  getUserRole() {
    return this.profileService;
  }

  loadUserPermissions(loginRole) {
    let that = this;
    let useremail = sessionStorage.getItem('profileemail');
    let user: RegisteredAccountUser = new RegisteredAccountUser();
    user.email = useremail;

    if (loginRole) {
      let internalUserPermissions: string[] = [];
      internalUserPermissions.push(loginRole);
      that.permissionsService.flushPermissions();
      that.permissionsService.loadPermissions(internalUserPermissions);
    }
  }

  completeAuthentication(): Promise<void> {
    let that = this;
    return this.userManager.signinRedirectCallback().then(
      user => {
        that.user = user;
        sessionStorage.setItem('profilename', that.user.profile.name);
        sessionStorage.setItem('profileemail', that.user.profile.email);
        let status: LoginState = that.auth.getLoginState();
        status.successfulSignIn();
        const loginRole = sessionStorage.getItem('profilerole');  
        // that.loadUserPermissions(loginRole);
        this.auth.setTopNavBarShowFlag(true);
        this.defaultRedirect(loginRole);
        // that.profileService
        //   .getUserRole(that.user.profile.email)
        //   .toPromise()
        //   .then((userrole: any) => {
        //     let internalUserPermissions: string[] = [];
        //     if (!isNil(userrole) && !isNil(userrole.role_name)) {
        //       internalUserPermissions = this.internalUserRoles(
        //         userrole.role_name
        //       );
        //       if (internalUserPermissions.length > 0) {
        //         this.permissionsService.flushPermissions();
        //         this.permissionsService.loadPermissions(
        //           internalUserPermissions
        //         );
        //         this.auth.setTopNavBarShowFlag(true);
        //         this.defaultRedirect(userrole.role_name);
        //       } else {
        //         console.log('User does not have a role');
        //         this.deniedRedirect();
        //       }
        //     }
        //   });


      },
      err => {
        console.log(err);
        this.deniedRedirect();
      }
    );
  }

  startSilentLogin() {
    return this.userManager.signinSilent().then(u => {
      this.user = u;
      // TODO: Implement silent token renewal to prevent logging in again
      this.defaultRedirect('');
    });
  }

  completeSilentLogin() {
    this.userManager.signinSilentCallback().then(u => {
      this.user = u;
      this.defaultRedirect('');
    });
  }

  startLogOut() {
    this.auth.getLoginState().clear();
    this.auth.loggedIn.next(false);
    this.permissionsService.flushPermissions();
    this.auth.removeSessionExpiryTimers();
    if (environment.PINGSLODISABLE) {
      this.user = null;
      this.logoutRedirect();
    } else {
      // PING has custom logic for SLO
      // window.location.href = `${this.pingConfig.metadata.end_session_endpoint}?TargetResource=${encodeURIComponent(this.pingConfig.post_logout_redirect_uri)}`;
      this.userManager
        .signoutRedirect({ id_token_hint: this.user.id_token })
        .then(resp => {});
    }
  }

  completeLogout() {
    this.userManager.signoutRedirectCallback().then(resp => {
      this.defaultRedirect('');
    });
  }

  defaultRedirect(roleName: string) {
    this.router.navigate(['landing/home']);

  }

  deniedRedirect() {
    this.router.navigate(['/signin/unauthorized']);
  }

  logoutRedirect() {
    this.router.navigate(['']);
  }

  // internalUserRoles(roleName: string) {
  //   let selectedRoles = [];

  //   switch (roleName) {
  //     case 'DCC DATA ADMIN':
  //       selectedRoles.push('DDA');
  //       break;

  //     case 'HUB DATA ADMIN':
  //       selectedRoles.push('DHA');
  //       break;

  //     case 'ORG RESEARCHER':
  //       selectedRoles.push('ORG');
  //       break;

  //     case 'SUPER USER':
  //       selectedRoles.push('ADM');
  //       break;

  //     default:
  //       break;
  //   }

  //   return selectedRoles;
  // }
}
