import { Component, OnInit } from '@angular/core';
import { PingAuthService } from '../../services/ping-auth.service';

@Component({
  selector: 'app-ping-logout',
  templateUrl: './ping-logout.component.html',
  styleUrls: ['./ping-logout.component.css'],
})
export class PingLogoutComponent implements OnInit {
  constructor(private pingAuthService: PingAuthService) {}

  ngOnInit() {
    this.pingAuthService.completeLogout();
  }
}
