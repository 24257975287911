import { Component } from '@angular/core';

@Component({
  moduleId: module.id,
  // tslint:disable-next-line: component-selector
  selector: 'login',
  templateUrl: 'access.denied.html',
})
export class AccessDeniedComponent {
  constructor() {}
}
