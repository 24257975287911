import {
  Directive,
  Input,
  EventEmitter,
  SimpleChange,
  OnChanges,
  DoCheck,
  IterableDiffers,
  IterableDiffer,
  Output,
} from '@angular/core';
import * as _ from 'lodash';
import { ReplaySubject } from 'rxjs';

export interface SortEvent {
  sortBy: string | string[];
  sortOrder: any[];
}

export interface PageEvent {
  activePage: number;
  rowsOnPage: number;
  dataLength: number;
}

export interface DataEvent {
  length: number;
}

export interface SelectEvent {}

@Directive({
  selector: 'table[mfData]',
  exportAs: 'mfDataTable',
})
export class DataTable implements OnChanges, DoCheck {
  private inputDataLength: number;

  @Input('mfData') public inputData: any[] = [];
  @Input('mfSortBy') public sortBy: string | string[] = '';
  @Input('mfSortOrder') public sortOrder: any[] = ['asc'];
  @Output('mfSortByChange') public sortByChange = new EventEmitter<
    string | string[]
  >();
  @Output('mfSortOrderChange') public sortOrderChange = new EventEmitter<
    any[]
  >();
  @Output('mfOnPageChange') public onServerPageChange =
    new EventEmitter<PageEvent>();
  @Input('mfRowsOnPage') public rowsOnPage = 1000;
  @Input('mfActivePage') public activePage = 1;
  @Input('mfAmountOfRows') public amountOfRows = 0;
  @Input('mfIsServerPagination') public isServerPaginationage: boolean = true;

  @Output('mfSelectedEntities')
  public selectedEntitiesEmitter = new EventEmitter();
  public selectedEntities: any[] = [];
  public data: any[];

  public onDataChange = new EventEmitter<DataEvent>();
  public onSortChange = new ReplaySubject<SortEvent>(1);
  public onPageChange = new EventEmitter<PageEvent>();
  public onSelectChange = new EventEmitter<SelectEvent>();
  private mustRecalculateData = false;
  private diff: IterableDiffer<{}>;

  public constructor(private differs: IterableDiffers) {
    this.diff = differs.find([]).create(null);
  }

  public addRemoveSelectedEntity($event: any) {
    this.onSelectChange.emit({});
    this.updateSelectedEntities();
    this.selectedEntitiesEmitter.emit(this.selectedEntities);
  }

  public updateSelectedEntities() {
    this.selectedEntities = this.inputData.filter(x => x.__isSelected__);
  }

  public selectAllRows() {
    this.inputData.forEach(data => {
      data.__isSelected__ = true;
    });
    this.updateSelectedEntities();
    this.selectedEntitiesEmitter.emit(this.selectedEntities);
  }

  public deselectAllRows() {
    this.inputData.forEach(data => {
      data.__isSelected__ = false;
    });
    this.updateSelectedEntities();
    this.selectedEntitiesEmitter.emit(this.selectedEntities);
  }

  public getSort(): SortEvent {
    return { sortBy: this.sortBy, sortOrder: this.sortOrder };
  }

  public setSort(sortBy: string | string[], sortOrder: any[]): void {
    if (this.sortBy !== sortBy || this.sortOrder !== sortOrder) {
      this.sortBy = sortBy;
      this.sortOrder = sortOrder;
      //  _.includes(["asc", "desc"], sortOrder[0])
      // 	? sortOrder
      // 	: "asc";
      this.mustRecalculateData = true;
      this.onSortChange.next({ sortBy: sortBy, sortOrder: sortOrder });
      this.sortByChange.emit(this.sortBy);
      this.sortOrderChange.emit(this.sortOrder);
    }
  }

  public getPage(): PageEvent {
    return {
      activePage: this.activePage,
      rowsOnPage: this.rowsOnPage,
      dataLength: this.inputData.length,
    };
  }

  public setPage(activePage: number, rowsOnPage: number): void {
    if (this.rowsOnPage !== rowsOnPage || this.activePage !== activePage) {
      this.activePage =
        this.activePage !== activePage
          ? activePage
          : this.calculateNewActivePage(this.rowsOnPage, rowsOnPage);
      this.rowsOnPage = rowsOnPage;
      this.mustRecalculateData = true;
      if (this.isServerPaginationage) {
        this.onServerPageChange.emit({
          activePage: this.activePage,
          rowsOnPage: this.rowsOnPage,
          dataLength: this.amountOfRows,
        });
      } else {
        this.onPageChange.emit({
          activePage: this.activePage,
          rowsOnPage: this.rowsOnPage,
          dataLength: this.inputData ? this.inputData.length : 0,
        });
      }
    }
  }

  private calculateNewActivePage(
    previousRowsOnPage: number,
    currentRowsOnPage: number
  ): number {
    let firstRowOnPage = (this.activePage - 1) * previousRowsOnPage + 1;
    let newActivePage = Math.ceil(firstRowOnPage / currentRowsOnPage);
    return newActivePage;
  }

  private recalculatePage() {
    let lastPage = Math.ceil(this.amountOfRows / this.rowsOnPage);
    this.activePage = lastPage < this.activePage ? lastPage : this.activePage;
    this.activePage = this.activePage || 1;

    if (this.isServerPaginationage) {
      this.onPageChange.emit({
        activePage: this.activePage,
        rowsOnPage: this.rowsOnPage,
        dataLength: this.amountOfRows,
      });
    } else {
      this.onPageChange.emit({
        activePage: this.activePage,
        rowsOnPage: this.rowsOnPage,
        dataLength: this.inputData.length,
      });
    }
  }

  public ngOnChanges(changes: { [key: string]: SimpleChange }): any {
    if (changes['rowsOnPage'] && !this.isServerPaginationage) {
      this.rowsOnPage = changes['rowsOnPage'].previousValue;
      this.setPage(this.activePage, changes['rowsOnPage'].currentValue);
      this.mustRecalculateData = true;
    }
    if (changes['sortBy'] || changes['sortOrder']) {
      // if (!_.includes(["asc", "desc"], this.sortOrder)) {
      // 	this.sortOrder = "asc";
      // }
      if (this.sortBy) {
        this.onSortChange.next({
          sortBy: this.sortBy,
          sortOrder: this.sortOrder,
        });
      }
      this.mustRecalculateData = true;
    }
    if (changes['inputData']) {
      this.inputData = changes['inputData'].currentValue || [];
      this.recalculatePage();
      this.mustRecalculateData = true;
    }
  }

  private addIsSelectedProperty() {
    this.inputData.forEach(x => {
      if (x.__isSelected__ == null) {
        Object.defineProperty(x, '__isSelected__', {
          value: false,
          writable: true,
        });
      }
    });
  }

  public ngDoCheck(): any {
    let changes = this.diff.diff(this.inputData);
    if (changes) {
      this.recalculatePage();
      this.mustRecalculateData = true;
    }
    if (this.mustRecalculateData || this.isInputDataChanged()) {
      this.addIsSelectedProperty();
      this.inputData = this.inputData || [];
      this.onPageChange.emit({
        activePage: this.activePage,
        rowsOnPage: this.rowsOnPage,
        dataLength: this.inputData.length,
      });
      this.onDataChange.emit({
        length: this.inputData.length,
      });
      this.fillData();
      this.mustRecalculateData = false;
    }
  }

  private isInputDataChanged(): boolean {
    let isDataLengthChanged = this.inputDataLength !== this.inputData.length;
    this.inputDataLength = this.inputData.length;

    return isDataLengthChanged;
  }

  private fillData(): void {
    this.activePage = this.activePage;
    this.rowsOnPage = this.rowsOnPage;

    let offset = (this.activePage - 1) * this.rowsOnPage;
    let data = this.inputData;
    let sortBy = this.sortBy;
    if (!this.isServerPaginationage) {
      if (typeof sortBy === 'string' || sortBy instanceof String) {
        data = _.orderBy(
          data,
          this.caseInsensitiveIteratee(<string>sortBy),
          this.sortOrder
        );
      } else {
        data = _.orderBy(data, sortBy, this.sortOrder);
      }
      data = _.slice(data, offset, offset + this.rowsOnPage);
    }
    this.data = data;
  }

  private caseInsensitiveIteratee(sortBy: string) {
    return (row: any): any => {
      let value = row;
      for (let sortByProperty of sortBy.split('.')) {
        if (value) {
          value = value[sortByProperty];
        }
      }
      if ((value && typeof value === 'string') || value instanceof String) {
        return value.toLowerCase();
      }
      return value;
    };
  }
}
