import { Component, OnDestroy, OnInit } from '@angular/core';

interface Image {
  src: string;
  alt: string;
}


@Component({
  selector: 'app-gallery',
  templateUrl: './galleryviewer.template.html',
  styleUrls: ['./galleryviewer.style.scss']
})
export class GalleryViewerComponent  implements OnInit, OnDestroy {

  loadUrl: string;
  noWrapSlides: boolean = true;
  loopInterval = 0;
  
  images: string[] = [
    'assets/img/architecture.png',
    'assets/img/os_semantic_term.png',
    'assets/img/opensearch_ml_flow.png',    
    'assets/img/alert_based_agent.png',  
    'assets/img/batch_celery_sqs_tasks.png',   
    'assets/img/how_rag_works.png',  
    'assets/img/agent_orchestration.png',
    'assets/img/aws_genai_services.png',         
  ];


  imgconfig: any = {
    btnIcons: {
      // The icon classes that will apply to the buttons. By default, font-awesome is used.
      zoomIn: 'fa fa-plus',
      zoomOut: 'fa fa-minus',
      rotateClockwise: 'fa fa-redo',
      rotateCounterClockwise: 'fa fa-undo',
      next: 'fa fa-arrow-right',
      prev: 'fa fa-arrow-left',
      fullscreen: 'fa fa-arrows-alt',
    },
  };

  ngOnInit(): void {
    this.loadUrl = "assets/img/architecture.png";
  }

  ngOnDestroy(): void {
  }

  openImageViewer(imageUrl: string) {
    // const viewer = new ImageViewer();
    // viewer.show(imageUrl);
  }

  prev() {
    throw new Error('Method not implemented.');
  }

  next() {
    throw new Error('Method not implemented.');
  }
}