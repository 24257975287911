<!-- Modal Header -->
<div class="modal-header d-block viewfile" ngxModalDraggable>
  <h1
    class="h2 modal-title text-xs-left fw-bold mt viewfile__header"
    tabindex="-1"
    id="downprod-title">
    <i *ngIf="didPass()" class="fas fa-fw fa-check-circle green-check-20"></i>
    <i *ngIf="rejected()" class="fas fa-fw fa-times-circle red-cross-20"></i>
    <i *ngIf="needsReview()" class="fas fa-fw fa-exclamation-triangle orange-warning"></i>  
    {{ fileName }}
  </h1>
  <h6 class="viewfile__header">{{ subHeader }}</h6>
  <button
    type="button"
    class="close pull-right close_btn"
    aria-label="Close"
    tabindex="1001"
    (click)="closeModal()"
    (keydown)="preventTabBack($event)">
    <span aria-hidden="true"><i class="fas fa-times"></i></span>
  </button>
</div>

<!--Modal Body-->
<div
  class="modal-body viewfile__scrolldiv"
  [ngBusy]="{
    busy: busyLoadFileDetails,
    message: 'Loading File Information...',
    backdrop: false
  }">
  <div>
    <tabset [justified]="false">
      <tab id="tab-image" heading="Image" class="viewfile__picturetab">
        <div class="image-wrapper">
          <angular-image-viewer
            [src]="[loadUrl]"
            [config]="imgconfig"
            [screenHeightOccupied]="300"></angular-image-viewer>
        </div>
      </tab>
      <tab *ngIf="showFindings" id="tab-notes" class="viewfile__tabnotes" heading="Findings">
        <section>
          <div *ngIf="passNotes.length > 0" class="row mb20">
            <div class="col-md-12">
              <span class="fw-bold mb10">Pass Criterion</span>
            </div>
            <ng-container *ngFor="let note of passNotes; let i = index">
              <div class="col-md-12">
                <span>{{ note }}</span>
              </div>
            </ng-container>
          </div>
          <div *ngIf="failNotes.length > 0" class="row">
            <div class="col-md-12">
              <span class="fw-bold mb10">Fail Criterion</span>
            </div>
            <ng-container *ngFor="let note of failNotes; let i = index">
              <div class="col-md-12">
                <span>{{ note }}</span>
              </div>
            </ng-container>
          </div>
        </section>
      </tab>
    </tabset>
  </div>
</div>
