<div class="alert tl-inline-info-card" *ngIf="true">
  <div>
    <span class="fas fa-info-circle"></span>
    <span class="ml10 fw-semi-bold">{{title}}</span>
    <span class="ml10"[innerHTML]="description"></span> 
  </div>
  <div>
    <span class="fas fa-info-circle"></span>
    <span class="ml10 fw-semi-bold">{{title2}}</span>
    <span class="ml10"[innerHTML]="description2"></span> 
  </div>
</div>
