import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { ActionCode } from '../models/authorization.types';
import { AuthorizationService } from '../../modules/login/services/authorization.service';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[laDisableIfUnauthorized]',
})
export class DisableIfUnauthorizedDirective implements OnInit {
  @Input('laDisableIfUnauthorized') permission: ActionCode;
  constructor(
    private el: ElementRef,
    private authService: AuthorizationService
  ) {}

  ngOnInit() {
    if (!this.authService.hasPermission(this.permission)) {
      this.el.nativeElement.disabled = true;
    }
  }
}
