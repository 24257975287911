import { Routes } from '@angular/router';
import { ErrorComponent } from './error/error.component';
import { LoginComponent } from './modules/login/components/login.component';
import { CanDeactivateGuardService } from './modules/login/services/can-deactivate-guard.service';
import { HomeComponent } from './home.component';
import { OktaCallbackComponent } from '@okta/okta-angular';

export const appRoutes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },  
  {
    path: 'home',
    component: HomeComponent,
  },  
  {
    path: '',
    loadChildren: () =>
      import('./layout/layout.module').then(m => m.LayoutModule),
  },
  {
    path: 'signin',
    loadChildren: () =>
      import('./modules/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  {
    path: '**',
    component: ErrorComponent,
  },
];
