<div role="alert" class="row success" aria-live="assertive">
  <div class="col-lg-9 col-xs-9">
    <div class="card success__ok">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-1">
            <i class="h4 fas fa-fw fa-check-circle green-check txt-gap"></i>
          </div>
          <div class="ml20 col-lg-10">
            <div class="row">
              <span class="h3 success__title"> {{title}} </span>
            </div>
            <div
              class="row success__description"
              [innerHTML]="description"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
