<main
  id="content"
  class="container chatbot-container p-0"
  role="main"
  tabindex="-1">
  <div class="router-component" #routerComponent>
    <router-outlet></router-outlet>
  </div>
</main>
<app-scroll-top></app-scroll-top>
