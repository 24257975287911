import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { BehaviorSubject } from 'rxjs';
import { LoginState } from '../model/login-state.model';
import { isNil } from 'ramda';
import { environment } from '../../../../environments/environment';
import { UserIdleService } from 'angular-user-idle';

enum AuthMode {
  COGNITO = 1,
  OKTA,
}

@Injectable()
export class AuthService {
  public authMode: AuthMode;

  public accessToken: string = null;
  private loginState: LoginState = new LoginState();
  public loggedIn: BehaviorSubject<boolean>;
  public showTopNavBar: boolean = false;

  // /* session timeout */
  // public expiring: Subject<boolean> = new Subject<boolean>();
  // private expiringSubscription: Subscription = null;
  // private expiredSubscription: Subscription = null;

  constructor(
    private router: Router,
    private permissionService: NgxPermissionsService,
    private userIdle: UserIdleService
  ) {
    this.loggedIn = new BehaviorSubject<boolean>(false);
  }

  /* These two calls are for passing user information */
  /* During registration only */
  public getLoginState(): LoginState {
    return this.loginState;
  }

  public getAccessToken() {
    let that = this;
    const p = new Promise<string>(resolve => {
      resolve(that.accessToken);
    });
    return p;
  }

  /** signout */
  public signOut() {
    this.loginState.clear();
    this.loggedIn.next(false);
    this.permissionService.flushPermissions();
    this.removeSessionExpiryTimers();

    this.router.navigate(['/signin']);
  }

  removeSessionExpiryTimers() {
    if (environment.userIdleEnabled) {
      this.userIdle.stopWatching();
    }
  }

  addSessionExpiryTimers() {
    if (environment.userIdleEnabled) {
      this.userIdle.startWatching();
    }
  }

  public isTopNavBarShown() {
    return this.showTopNavBar;
  }

  public setTopNavBarShowFlag(show: boolean) {
    this.showTopNavBar = show;
  }

  public getSignedDisplayName() {
    let useremail = 'Demo User';
    if (environment.PINGENABLED) {
      useremail = sessionStorage.getItem('profilename');
    } else {
      if (
        !isNil(this.permissionService.getPermission('ORG')) &&
        this.permissionService.getPermission('ORG').name === 'ORG'
      ) {
        useremail = 'mev@deloitte.com';
      }
      if (
        !isNil(this.permissionService.getPermission('DHA')) &&
        this.permissionService.getPermission('DHA').name === 'DHA'
      ) {
        useremail = 'manmitra@deloitte.com';
      }
      if (
        !isNil(this.permissionService.getPermission('DDA')) &&
        this.permissionService.getPermission('DDA').name === 'DDA'
      ) {
        useremail = 'rabalasubramani3@deloitte.com';
      }
    }
    return useremail;
  }

  public getSignedUsername() {
    let useremail = '';
    if (environment.PINGENABLED) {
      useremail = sessionStorage.getItem('profileemail');
    } else {
      if (
        !isNil(this.permissionService.getPermission('ORG')) &&
        this.permissionService.getPermission('ORG').name === 'ORG'
      ) {
        useremail = 'mev@deloitte.com';
      }
      if (
        !isNil(this.permissionService.getPermission('DHA')) &&
        this.permissionService.getPermission('DHA').name === 'DHA'
      ) {
        useremail = 'manmitra@deloitte.com';
      }
      if (
        !isNil(this.permissionService.getPermission('DDA')) &&
        this.permissionService.getPermission('DDA').name === 'DDA'
      ) {
        useremail = 'rabalasubramani3@deloitte.com';
      }
    }
    return useremail;
  }

  /**
   * redirect to home page
   */
  public redirectToHome() {
      this.router.navigate(['/landing/home']);
  }

}
