<div class="modal-header" ngxModalDraggable>
  <h1 class="h2 modal-title text-xs-left fw-bold mt" id="session-timeout-title">
    Session Timeout
  </h1>
</div>
<!--Modal Body-->
<div class="modal-body">
  <div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-2">
      <div style="font-size: 40px; color: red">
        <i aria-hidden="true" class="fa fa-exclamation-triangle"></i>
      </div>
    </div>
    <div class="col-md-8">
      <h2 class="h3 fw-semi-bold">
        <span>Your session is about to expire.</span>
      </h2>
      <h2 class="h3 fw-semi-bold">
        <span>Do you want to continue working?</span>
      </h2>
      <h3 class="h4">
        <span
          >You will be logged out in {{timeToExpire * 1000 |
          date:'H:mm:ss':'UTC'}}</span
        >
      </h3>
    </div>
  </div>
</div>
<!--Modal Footer-->
<div class="modal-footer modal-footer-modified">
  <div class="row ml20 mr20">
    <div class="col-md-12">
      <form id="estaddressfooter" class="form-horizontal">
        <div class="form-group">
          <div class="row">
            <div class="col-md-12">
              <button
                type="submit"
                id="keep-signed-in"
                class="btn btn-primary col-md-12"
                (click)="keepSessionAlive()">
                Yes, Keep me signed in
              </button>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-md-12">
              <button
                type="button"
                id="sign-me-out"
                class="btn btn-link col-md-12"
                (click)="logout()">
                No, Sign me out
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
