import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { MessageService } from '../../../_services';
import * as API_ENDPOINTS from '../../../shared/models/api-endpoints';

/**
 * Service - returns all user profile information
 *
 * @export
 * @class ProfileService
 */
@Injectable()
export class ProfileService {
  constructor(
    private http: HttpClient,
    private messageService: MessageService
  ) {}

  public getUserRole(email: string) {
    return this.http.get(API_ENDPOINTS.userRoleUrl + '?email=' + email);
  }

  /**
   *
   *
   * @private
   * @param {string} message
   * @memberof ProfileService
   */
  private log() {
    this.messageService.add('Profile Service: ${message}');
  }
}
