import { CommonModule, CurrencyPipe, TitleCasePipe } from '@angular/common';
import { NgModule, SecurityContext } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { BusyConfig, NgBusyModule } from 'ng-busy';
import { NgSelect2Module } from 'ng-select2';
import { AlertModule } from 'ngx-bootstrap/alert';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxFilesizeModule } from 'ngx-filesize';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { MomentModule } from 'ngx-moment';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ToastrModule } from 'ngx-toastr';
import { ArchwizardModule } from '../archwizard/archwizard.module';
import { WidgetModule } from './../layout/widget/widget.module';
import { AlertCardComponent } from './components/alert-card/alert-card.component';
import { BaseComponent } from './components/base/base.component';
import { ConfirmModalPopupComponent } from './components/confirm-modal/confirm-modal.component';
import { ConfirmPopupPh } from './components/confirmpopup/confirm-popup.ph.component';
import { InfoBoxPopup } from './components/confirmpopup/infobox.component';
import { ErrorCardComponent } from './components/error-card/error-card.component';
import { InfoCardComponent } from './components/info-card/info-card.component';
import { NgxCheckboxComponent } from './components/ngx-checkbox/ngx-checkbox.component';
import { NgxCheckboxDirective } from './components/ngx-checkbox/ngx-checkbox.directive';
import { ReadMoreComponent } from './components/read-more/read-more.component';
import { SuccessCardComponent } from './components/success-card/success-card.component';
import { MDWizardStepComponent } from './components/wizard/wizard-step.component';
import { MDWizardComponent } from './components/wizard/wizard.component';
import { NgTableFilteringDirective } from './datatable/datatable.filtering.directive';
import { DataTableModule } from './datatable/DataTableModule';
import { NKDatetimeModule } from './datetime/ng2-datetime-module';
import { AlphaNumericOnlyDirective } from './directives/alpha-numeric-only.directive';
import { AlphaNumericHyphenSlashDirective } from './directives/alphanumhypslash.directive';
import { CustomAnchorClickDirective } from './directives/anchorClick.directive';
import { ChecklistDirective } from './directives/checklist.directive';
import { ConfirmDirective } from './directives/confirm.directive';
import { CustomCurrencyFormatterDirective } from './directives/custom-currency-formatter.directive';
import { CustomDecimalFormatterDirective } from './directives/custom-decimal-formatter.directive';
import { CustomIntegerDirective } from './directives/custom-integer.directive';
import { CustomNumberFormatterDirective } from './directives/custom-number-formatter.directive';
import { CustomNumberVaidatorDirective } from './directives/custom-number-validator.directive';
import { CustomNumberPipe } from './directives/custom-number.pipe';
import { CustomPercentDirective } from './directives/custom-percent.directive';
import { CustomCurrencyPipe } from './directives/custom.currency.pipe';
import { DisableIfUnauthorizedDirective } from './directives/disable-if-unauthorized.directive';
import { DragDropDirective } from './directives/drag-drop.directive';
import { NgxModalDraggableDirective } from './directives/draggable.directive';
import { FocusDirective } from './directives/focus.directive';
import { FocusOutDirective } from './directives/focusOut.directive';
import { HideIfUnauthorizedDirective } from './directives/hide-if-unauthorized.directive';
import { NumericOnlyDirective } from './directives/numeric-only.directive';
import { CustomNumericsDirective } from './directives/numerics.directive';
import { AbsoluteValuePipe } from './pipes/absolute-value.pipe';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { DatePipe } from './pipes/date.pipe';
import { DurationFormatPipe } from './pipes/duration-format.pipe';
import { EscapeHtmlPipe } from './pipes/keep-html.pipe';
import { LoopIndexPipe } from './pipes/loopindex.pipe';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';
import { RemoveHtmlPipe } from './pipes/remove-html.pipe';
import { RemoveHyphen } from './pipes/removehyphen.pipe';
import { SortByPipe } from './pipes/sort-by.pipe';
import { TitleCase } from './pipes/titlecase.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { LoaderService } from './service/loader.service';
import { CustomValidatorDirective } from './validators/custom.validator.directive';
import { PaginationComponent } from './components/pagination/pagination.component';
import { ScrollTopComponent } from './components/scroll-top/scroll-top.component';
import { BackButtonDirective } from './directives/back-button.directive';
import { ProgressBarPopupComponent } from './components/progress-bar-popup/progress-bar-popup.component';
import { SortableHeaderComponent } from './components/ag-grid-header-comp/ag-grid-header-comp.component';
import { SafePipe } from './pipes/safe.pipe';
import { FileUploadFailedPopupComponent } from './components/file-upload-failed-popup/file-upload-failed-popup.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ViewFilePopupComponent } from './components/view-file-popup/view-file-popup.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NIHStudyDataService } from './service/nih-study.service';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { GalleryViewerComponent } from './components/galleryviewer/galleryviewer.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { NgxHighlightWordsComponent } from './components/ngx-highlight-words/ngx-highlight-words.component';
import { KTimeAgoPipe } from './pipes/time-ago.pipe';
import { TreeviewModule } from '@charmedme/ngx-treeview';
import { DropdownTreeviewSelectComponent } from './components/dropdown-treeview-select/dropdown-treeview-select.component';
import { SearchPipe } from './pipes/search.pipe';
import { CopyDirective } from './directives/copy.directive';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MarkdownModule } from 'ngx-markdown'
import { FullScreenDirective } from './directives/full-screen.directive';
import { AngularImageViewerComponent } from './components/image-viewer/image-viewer.component';
import { NgxTextDiffComponent } from './components/ngx-text-diff/ngx-text-diff.component';
import { FormatLinePipe } from './pipes/format-line.pipe';
import { ContainerDirective } from './directives/ngx-text-diff-container.directive';
import { LoaderSpinnerComponent } from './components/loader-spinner/loader-spinner.component';
import { CustomBusyComponent } from './components/custom-busy/custom-busy.component';
import { HighlightDirective } from './directives/highlight.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TabsModule.forRoot(),
    AccordionModule,
    ButtonsModule,
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    AlertModule.forRoot(),
    ProgressbarModule.forRoot(),
    NgxWebstorageModule.forRoot(),
    CarouselModule.forRoot(),
    AgGridModule,
    NgxFileDropModule,
    MomentModule,
    FlexLayoutModule,
    ArchwizardModule,
    NgBusyModule.forRoot(new BusyConfig({
      message: '',
      backdrop: false,
      template: CustomBusyComponent,      
      delay: 200,
      minDuration: 600,
      templateNgStyle: { "background-color": "black" }
    })),
    WidgetModule,
    NKDatetimeModule,
    NgxFilesizeModule,
    NgSelect2Module,
    NgxJsonViewerModule,
    NgxSpinnerModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,    
    PdfViewerModule,  
    MarkdownModule.forRoot({
      sanitize: SecurityContext.NONE
    }),   
    TreeviewModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
  ],
  declarations: [
    PhoneNumberPipe,
    TitleCase,
    FocusDirective,
    DatePipe,
    CustomNumericsDirective,
    ConfirmPopupPh,
    CustomDecimalFormatterDirective,
    CopyDirective,
    EscapeHtmlPipe,
    RemoveHtmlPipe,
    KTimeAgoPipe,
    SearchPipe,    
    CustomValidatorDirective,
    CustomCurrencyPipe,
    CustomCurrencyFormatterDirective,
    CustomNumberFormatterDirective,
    CustomNumberPipe,
    AbsoluteValuePipe,
    SortByPipe,
    CustomNumberVaidatorDirective,
    CustomPercentDirective,
    DateAgoPipe,
    ConfirmModalPopupComponent,
    FileUploadFailedPopupComponent,
    AngularImageViewerComponent,
    ProgressBarPopupComponent,
    FullScreenDirective,
    CustomIntegerDirective,
    NumericOnlyDirective,
    AlphaNumericOnlyDirective,
    RemoveHyphen,
    CustomAnchorClickDirective,
    DragDropDirective,
    FocusOutDirective,
    LoopIndexPipe,
    DisableIfUnauthorizedDirective,
    HideIfUnauthorizedDirective,
    BaseComponent,
    NgTableFilteringDirective,
    BackButtonDirective,
    InfoBoxPopup,
    ConfirmDirective,
    TruncatePipe,
    ChecklistDirective,
    MDWizardComponent,
    MDWizardStepComponent,
    NgxModalDraggableDirective,
    ReadMoreComponent,
    SuccessCardComponent,
    ErrorCardComponent,
    AlphaNumericHyphenSlashDirective,
    DurationFormatPipe,
    AlertCardComponent,
    NgxCheckboxComponent,
    NgxCheckboxDirective,
    InfoCardComponent,
    PaginationComponent,
    ScrollTopComponent,
    SortableHeaderComponent,
    SafePipe,
    ViewFilePopupComponent,
    GalleryViewerComponent,
    NgxHighlightWordsComponent,
    DropdownTreeviewSelectComponent,
    NgxTextDiffComponent,
    FormatLinePipe,
    ContainerDirective,
    LoaderSpinnerComponent,
    CustomBusyComponent,
    HighlightDirective
  ],
  exports: [
    CommonModule,
    DataTableModule,
    AlertModule,
    FormsModule,
    ReactiveFormsModule,
    TabsModule,
    AccordionModule,
    NgxFilesizeModule,
    NgxWebstorageModule,
    NgxJsonViewerModule,    
    ButtonsModule,
    TooltipModule,
    FlexLayoutModule,
    CarouselModule,
    NKDatetimeModule,
    WidgetModule,
    BsDatepickerModule,
    BsDropdownModule,
    ProgressbarModule,
    AgGridModule,
    NgxFileDropModule,
    MomentModule,
    NgxPermissionsModule,
    NgSelect2Module,
    NgxSpinnerModule,
    TreeviewModule,
    ArchwizardModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,    
    PdfViewerModule,   
    MarkdownModule,     
    ToastrModule,
    PhoneNumberPipe,
    KTimeAgoPipe,
    SearchPipe,     
    NgBusyModule,
    TitleCase,
    RemoveHyphen,
    BaseComponent,
    FocusDirective,
    DatePipe,
    AbsoluteValuePipe,
    SortByPipe,
    CustomNumericsDirective,
    CopyDirective,    
    ConfirmPopupPh,
    CustomDecimalFormatterDirective,
    CustomNumberFormatterDirective,
    ConfirmModalPopupComponent,
    FileUploadFailedPopupComponent,
    ProgressBarPopupComponent,
    DisableIfUnauthorizedDirective,
    HideIfUnauthorizedDirective,
    CustomNumberPipe,
    CustomNumberVaidatorDirective,
    CustomIntegerDirective,
    NumericOnlyDirective,
    AlphaNumericOnlyDirective,
    CustomPercentDirective,
    CustomAnchorClickDirective,
    DragDropDirective,
    FocusOutDirective,
    BackButtonDirective,
    LoopIndexPipe,
    NgTableFilteringDirective,
    InfoBoxPopup,
    DateAgoPipe,
    TruncatePipe,
    FullScreenDirective,
    ChecklistDirective,
    MDWizardComponent,
    MDWizardStepComponent,
    NgxModalDraggableDirective,
    ReadMoreComponent,
    SuccessCardComponent,
    ErrorCardComponent,
    AlphaNumericHyphenSlashDirective,
    DurationFormatPipe,
    AlertCardComponent,
    NgxCheckboxComponent,
    NgxCheckboxDirective,
    InfoCardComponent,
    PaginationComponent,
    ScrollTopComponent,
    SafePipe,
    ViewFilePopupComponent,
    GalleryViewerComponent,
    NgxHighlightWordsComponent,
    DropdownTreeviewSelectComponent,
    AngularImageViewerComponent,    
    NgxTextDiffComponent,    
    FormatLinePipe,
    ContainerDirective,
    LoaderSpinnerComponent,
    CustomBusyComponent,
    HighlightDirective,   
  ],
  providers: [
    CurrencyPipe,
    TitleCasePipe,
    LoaderService,
    NIHStudyDataService,
    CustomNumberPipe,
    DateAgoPipe,
    KTimeAgoPipe,
  ]
  // entryComponents: [
  //   ConfirmModalPopupComponent,
  //   FileUploadFailedPopupComponent,
  //   ProgressBarPopupComponent,
  //   SortableHeaderComponent,
  //   ViewFilePopupComponent,
  // ],
})

// https://angular.io/docs/ts/latest/guide/ngmodule.html#!#shared-module
export class SharedModule {}
