<label
  class="ngx-checkbox"
  ngxCheckbox
  [ngClass]="{'check-active':state=='active','check-intermediate':state=='intermediate' }"
  [checkedClass]="checkedClass"
  [nocheckedClass]="nocheckedClass">
  <input
    type="checkbox"
    id="{{id}}"
    [checked]="(isChecked) ? true : null"
    (click)="onActionClick($event)" />
  <span
    class="ngx-span-checkbox"
    role="checkbox"
    aria-checked="true"
    aria-label="Select"
    [attr.tabindex]="tabIndex ? tabIndex : 0"
    (focus)="setFocusClass()"
    (focusout)="setFocusClass()"
    (keypress)="onKeyPress($event)">
    <span *ngIf="state=='active'">
      <i class="ngx-icon-checkbox fas fa-check"></i>
    </span>
    <span *ngIf="state=='intermediate'">
      <i class="ngx-icon-checkbox fas fa-minus"></i>
    </span>
  </span>
</label>
