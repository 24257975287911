<section class="card notifications">
  <header class="card-header">
    <div class="text-center mb-sm">
      <strong>You have 10 notifications</strong>
    </div>
    <div
      class="btn-group btn-group-sm"
      id="notifications-toggle"
      data-toggle="buttons">
      <label class="btn btn-default active">
        <!-- ajax-load plugin in action. setting data-ajax-load & data-ajax-target is the
             only requirement for async reloading -->
        <input
          type="radio"
          notification-load
          data-ajax-trigger="change"
          data-ajax-load="assets/demo/notifications/notifications.html"
          data-ajax-target="#notifications-list" />
        Notifications
      </label>
      <label class="btn btn-default">
        <input
          type="radio"
          notification-load
          data-ajax-trigger="change"
          data-ajax-load="assets/demo/notifications/messages.html"
          data-ajax-target="#notifications-list" />
        Messages
      </label>
      <label class="btn btn-default">
        <input
          type="radio"
          notification-load
          data-ajax-trigger="change"
          data-ajax-load="assets/demo/notifications/progress.html"
          data-ajax-target="#notifications-list" />
        Progress
      </label>
    </div>
  </header>
  <!-- notification list with .thin-scroll which styles scrollbar for webkit -->
  <div id="notifications-list" class="list-group thin-scroll">
    <div class="list-group-item">
      <span class="thumb-sm float-left mr clearfix">
        <i class="fas fa-user fa-lg"></i>
      </span>
      <p class="m-0 overflow-hidden">
        1 new user just signed up! Check out
        <a href="#">Monica Smith</a>'s account.
        <time class="help-block m-0"> 2 mins ago </time>
      </p>
    </div>
    <a class="list-group-item" href="#">
      <span class="thumb-sm float-left mr">
        <i class="fas fa-upload fa-lg"></i>
      </span>
      <p class="text-ellipsis m-0">2.1.0-pre-alpha just released.</p>
      <time class="help-block m-0"> 5h ago </time>
    </a>
    <a class="list-group-item" href="#">
      <span class="thumb-sm float-left mr">
        <i class="fas fa-bolt fa-lg"></i>
      </span>
      <p class="text-ellipsis m-0">Server load limited.</p>
      <time class="help-block m-0"> 7h ago </time>
    </a>
    <div class="list-group-item">
      <span class="thumb-sm float-left mr clearfix">
        <i class="fas fa-user fa-lg"></i>
      </span>
      <p class="m-0 overflow-hidden">
        User <a href="#">Jeff</a> registered &nbsp;&nbsp;
        <button class="btn btn-xs btn-success">Allow</button>
        <button class="btn btn-xs btn-danger">Deny</button>
        <time class="help-block m-0"> 12:18 AM </time>
      </p>
    </div>
    <div class="list-group-item">
      <span class="thumb-sm float-left mr">
        <i class="fas fa-user-shield fa-lg"></i>
      </span>
      <p class="m-0 overflow-hidden">
        Instructions for changing your Envato Account password. Please check
        your account <a href="#">security page</a>.
        <time class="help-block m-0"> 12:18 AM </time>
      </p>
    </div>
    <a class="list-group-item" href="#">
      <span class="thumb-sm float-left mr">
        <span class="rounded bg-primary rounded-lg">
          <i class="fab fa-facebook text-white"></i>
        </span>
      </span>
      <p class="text-ellipsis m-0">
        New <strong>76</strong> facebook likes received.
      </p>
      <time class="help-block m-0"> 15 Oct 2019 </time>
    </a>
    <a class="list-group-item" href="#">
      <span class="thumb-sm float-left mr">
        <span class="circle circle-lg bg-gray-dark">
          <i class="fa fa-dot-circle text-white"></i>
        </span>
      </span>
      <p class="text-ellipsis m-0">Dark matter detected.</p>
      <time class="help-block m-0"> 15 Oct 2019 </time>
    </a>
  </div>
  <footer class="card-footer text-sm">
    <!-- ajax-load button. loads assets/demo/notifications/notifications.php to #notifications-list
         when clicked -->
    <button
      class="btn btn-xs btn-link float-right btn-notifications-reload"
      id="load-notifications-btn"
      appNotificationsLoad
      data-ajax-load="assets/demo/notifications/notifications.php"
      data-ajax-target="#notifications-list"
      data-loading-text="<i class='fas fa-sync fa-spin mr-xs'></i> Loading...">
      <i class="fas fa-sync"></i>
    </button>
    <span class="fs-mini">Synced at: 21 Oct 2023 18:36</span>
  </footer>
</section>
