import {
  Directive,
  Input,
  EventEmitter,
  ElementRef,
  HostListener,
  Inject,
  Renderer2,
} from '@angular/core';
declare var jQuery: any;
@Directive({
  selector: '[focusOut]',
})
export class FocusOutDirective {
  @HostListener('keypress', ['$event'])
  keyenter(event: KeyboardEvent) {
    if (event.which === 32 || event.which === 13) {
      jQuery(event.target).blur();
    }
  }

  @HostListener('click', ['$event'])
  mouseclick(event: MouseEvent) {
    jQuery(event.target).blur();
  }
}
