import {
  Directive,
  HostListener,
  ElementRef,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { CustomNumberPipe } from './custom-number.pipe';

declare let jQuery: any;

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[customNumberFormatter]',
  providers: [CustomNumberPipe],
})
export class CustomNumberFormatterDirective implements OnInit, OnChanges {
  private el: HTMLInputElement;
  @Input('customNumberFormatter') private controlValue: string;
  @Input('valueChg') valChg: number;

  constructor(
    private elementRef: ElementRef,
    private numberPipe: CustomNumberPipe
  ) {
    this.el = this.elementRef.nativeElement;
  }

  moveCursorToEnd(el) {
    if (typeof el.selectionStart === 'number') {
      el.selectionStart = el.selectionEnd = el.value.length;
    } else if (typeof el.createTextRange !== 'undefined') {
      el.focus();
      let range = el.createTextRange();
      range.collapse(false);
      range.select();
    }
  }

  ngOnInit() {
    if (this.el.value != null && this.el.value.trim() !== '') {
      this.el.value = this.numberPipe.transform(this.el.value);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    for (let propName in changes) {
      if (propName === 'valChg') {
        let chg = changes[propName];
        if (chg.currentValue) {
          let isFocused: boolean = jQuery(this.el).is(':focus');
          if (
            this.el.value != null &&
            this.el.value.trim() !== '' &&
            !isFocused
          ) {
            setTimeout(
              _ => (this.el.value = this.numberPipe.transform(this.el.value, 0))
            );
          }
        }
      }
    }
  }

  @HostListener('focus', ['$event.target.value'])
  onFocus(value) {
    if (this.el.value != null && this.el.value.trim() !== '') {
      this.el.value = this.numberPipe.parse(value, 0);
      // this.moveCursorToEnd(this.el);
      this.el.select();
    }
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value) {
    if (this.el.value != null && this.el.value.trim() !== '') {
      this.el.value = this.numberPipe.transform(value, 0);
    }
  }
}
