/*
@author : Deloitte

this is Component for editing company address.
*/

import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';

declare var jQuery: any;

/**
 *
 *
 * @export
 * @class ConfirmPopup
 */
@Component({
  selector: '[confirm-popup-ph]',
  templateUrl: './confirm-popup.ph.template.html',
})
export class ConfirmPopupPh implements OnInit {
  @Input() model: any;
  @Output() callBack = new EventEmitter<boolean>();
  @Output() hideModal = new EventEmitter<boolean>();
  currentModel: any;
  @Input() msg: string;
  @Input() title: string;
  documentDesc: string;

  ngOnInit() {}

  constructor() {}

  reset() {
    jQuery('#confirm-popup').modal('hide');
    this.hideModal.emit(true);
  }

  delete() {
    this.callBack.emit(this.currentModel);
  }
  /*
    ngOnChanges() used to capture changes on the input properties.
*/
  ngOnChanges(changes: SimpleChanges) {
    for (let propName in changes) {
      if (propName === 'model') {
        let chg = changes[propName];
        if (chg.currentValue) {
          this.currentModel = chg.currentValue;
        }
      }
    }
  }
}
