import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import * as R from 'ramda';

@Pipe({ name: 'datePipe' })
export class DatePipe implements PipeTransform {
  constructor() {}

  transform(value: any): string {
    if (!R.isNil(value)) {
      if (R.is(String, value)) {
        if (value.trim().length === 10) {
          if (value.includes('-')) {
            return this.getFormattedDate(new Date(value.replace(/-/g, '/')));
          }
        } else {
          return this.getFormattedDate(new Date(value));
        }
      }
      if (R.is(Date, value)) {
        return moment(value).format('MMM DD, YYYY');
      }
    }
  }

  //https://stackoverflow.com/questions/7556591/javascript-date-object-always-one-day-off
  private getFormattedDate(date): string {
    return moment(date).format('MMM DD, YYYY');
  }
}
