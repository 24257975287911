import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive(
  // tslint:disable-next-line: directive-selector
  { selector: '[alphanumhypslash]' }
)
export class AlphaNumericHyphenSlashDirective {
  regexStr = '^[a-zA-Z0-9-_/]*$';
  constructor(private el: ElementRef) {}

  @HostListener('keypress', ['$event'])
  keyenter(event: KeyboardEvent) {
    return new RegExp(this.regexStr).test(event.key);
  }

  @HostListener('paste', ['$event'])
  blockPaste(event: KeyboardEvent) {
    this.validateFields(event);
  }

  validateFields(event) {
    setTimeout(() => {
      this.el.nativeElement.value = this.el.nativeElement.value
        .replace(/[^A-Za-z\-\_\/]/g, '')
        .replace(/\s/g, '');
      event.preventDefault();
    }, 100);
  }
}
